import React, { ReactElement } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CrmCard from './helpers/CrmCard';
import FormBuilder from './helpers/forms/FormBuilder';
import PasswordField from './helpers/forms/PasswordField';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { Check, Save } from '@mui/icons-material';
import { useToggle } from 'react-use';

const Me = (): ReactElement => {
    const [passwordChanged, togglePasswordChanged] = useToggle(false);

    return (
        <Box mx="auto" maxWidth="500px">
            {passwordChanged ? (
                <CrmCard title="Mein Passwort ändern">
                    <Typography component="span">
                        <Check color="primary" fontSize="inherit" />
                        <Box component="span" ml={1}>
                            Passwort erfolgreich geändert.
                        </Box>
                    </Typography>
                </CrmCard>
            ) : (
                <FormBuilder
                    responseHandler={togglePasswordChanged}
                    action="/api/crm/me/change-password"
                    initialValues={{
                        old_password: '',
                        new_password: '',
                    }}
                >
                    <CrmCard
                        title="Mein Passwort ändern"
                        titleAction={
                            <IconButton type="submit">
                                <Save />
                            </IconButton>
                        }
                    >
                        <Field
                            component={TextField}
                            name="old_password"
                            label="Aktuelles Passwort"
                            type="password"
                            required
                        />
                        <PasswordField
                            name="new_password"
                            label="Neues Passwort"
                            required
                        />
                    </CrmCard>
                </FormBuilder>
            )}
        </Box>
    );
};

export default Me;
