import React, { ReactElement, useState } from 'react';
import AutocompleteInput from '../../helpers/forms/AutocompleteInput';
import Ajax from '../../../helpers/Ajax';
import FormBuilder from '../../helpers/forms/FormBuilder';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { mapContacts } from '../../../mappers/contactMapper';
import { AutocompleteOption } from '../../../crm';
import { ContactEntity } from '../../../helpers/entities';

interface Props {
    closeDialog: { (): void };
    contactPersonId?: string;
    responseHandler(): void;
}

const ContactLinkDialog = ({
    closeDialog,
    contactPersonId,
    responseHandler,
}: Props): ReactElement => {
    const [selectedContactId, setSelectedContactId] = useState<string | null>(
        null
    );

    const getContacts = async (
        searchString: string
    ): Promise<AutocompleteOption[]> => {
        const response = await Ajax.get<any>('contacts', {
            search: searchString,
        });

        return mapContacts(response).map(contact => ({
            label: contact.name,
            value: contact.id,
            additionalInformation: contact.address?.city,
        }));
    };

    const autocompleteChangeHandler = (newValue: AutocompleteOption | null) => {
        setSelectedContactId(newValue ? newValue.value : null);
    };

    return (
        <FormBuilder
            action={
                ContactEntity.apiBaseUrl +
                '/' +
                selectedContactId +
                '/contact_persons/' +
                contactPersonId
            }
            responseHandler={responseHandler}
            initialValues={{}}
            errorMessage="Fehler beim Verknüpfen des Kontaktes!"
        >
            <DialogTitle>
                <Typography component="h2">Kontakt verknüpfen</Typography>
                <IconButton onClick={closeDialog} size="small" edge="end">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <AutocompleteInput
                    getOptions={getContacts}
                    autoFocus
                    name="contact_id"
                    label="Kontakt"
                    onChangeHandler={autocompleteChangeHandler}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="primary"
                    disabled={!selectedContactId}
                >
                    Kontakt verknüpfen
                </Button>
            </DialogActions>
        </FormBuilder>
    );
};

export default ContactLinkDialog;
