import React, { ReactElement } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import FormBuilder from '../helpers/forms/FormBuilder';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import FileField, { defaultValue } from '../helpers/forms/FileField';
import EditCloseAction from '../helpers/actions/EditCloseAction';
import { File } from '../../mappers/fileMapper';

interface Props {
    action: string;
    closeDialog: { (): void };
    responseHandler(): void;
    file?: File | null;
}

const FileFormDialog = ({
    closeDialog,
    action,
    responseHandler,
    file = null,
}: Props): ReactElement => {
    const title = `Datei ${file?.id ? 'bearbeiten' : 'hinterlegen'}`;
    return (
        <FormBuilder
            action={action}
            contentType="formData"
            initialValues={{
                label: file?.label ?? '',
                file: defaultValue,
            }}
            responseHandler={responseHandler}
            errorMessage="Fehler beim Speichern der Datei!"
        >
            <DialogTitle>
                <Typography component="h2">{title}</Typography>
                <EditCloseAction editClose={closeDialog} edge="end" />
            </DialogTitle>
            <DialogContent dividers>
                <Field
                    component={TextField}
                    name="label"
                    label="Beschreibung"
                    autoFocus
                    fullWidth
                />
                <FileField
                    name="file"
                    label={file?.id ? 'Datei ersetzen' : 'Datei hochladen'}
                />
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    {title}
                </Button>
            </DialogActions>
        </FormBuilder>
    );
};

export default FileFormDialog;
