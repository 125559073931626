import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import FormBuilderCard from '../../helpers/forms/FormBuilderCard';
import { ContactPersonEntity, PositionEntity } from '../../../helpers/entities';
import { Position } from '../../../mappers/positionMapper';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';

interface Props {
    reloadHandler: { (): void };
    contactPerson: ContactPerson;
}

const ContactPersonPosition = ({
    reloadHandler,
    contactPerson,
}: Props): ReactElement => (
    <FormBuilderCard
        title="Position"
        apiUrl={`${ContactPersonEntity.apiBaseUrl}/${contactPerson.id}`}
        form={
            <>
                <EntityAutocomplete<Position>
                    entity={PositionEntity}
                    selectedElement={contactPerson.position}
                    name="position_id"
                    minInputLength={0}
                />
            </>
        }
        view={
            <Typography variant="body2">
                {contactPerson.position_name ?? (
                    <i>Keine Position zugewiesen</i>
                )}
            </Typography>
        }
        reloadHandler={reloadHandler}
        initialValues={{
            position: contactPerson.position,
        }}
    />
);

export default ContactPersonPosition;
