import { Link } from 'react-router-dom';
import { Box, TableCell, TableRow } from '@mui/material';
import { Block } from '@mui/icons-material';
import React, { ReactElement } from 'react';
import { User } from '../../../mappers/userMapper';

interface Props {
    user: User;
}

const UserCells = ({ user }: Props): ReactElement => (
    <TableRow>
        <TableCell>
            <Link to={`/user/${user.id}`}>{user.last_name}</Link>
            {user.isDisabled && (
                <Box ml={1} component="span">
                    <Block fontSize="inherit" color="inherit" />
                </Box>
            )}
        </TableCell>
        <TableCell>{user.first_name}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.roleTranslation}</TableCell>
    </TableRow>
);

export default UserCells;
