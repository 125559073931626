import React, { ReactElement } from 'react';
import { Contract, contractTypeLabel } from '../../../mappers/contractMapper';
import {
    Box,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material';
import { Edit, OpenInNew } from '@mui/icons-material';
import Ajax from '../../../helpers/Ajax';
import useDialog from '../../../hooks/useDialog';
import CrmDialog from '../../helpers/CrmDialog';
import ContractFormDialog from './ContractFormDialog';

interface Props {
    contract: Contract;
    reloadHandler: { (): void };
}

const ContractView = ({ contract, reloadHandler }: Props): ReactElement => {
    const { openDialog, closeDialog, dialogOpen } = useDialog();

    const onClickView = async () => {
        if (!contract.uri) {
            return;
        }

        await Ajax.file(contract.uri);
    };

    const contractResponseHandler = () => {
        closeDialog();
        reloadHandler();
    };

    return (
        <>
            <ListItem disableGutters>
                <ListItemText
                    primary={contractTypeLabel(contract.type)}
                    secondary={
                        <>
                            <Box component="div" display="flex" flexWrap="wrap">
                                <Box component="span" mr={1}>
                                    Vertragsbeginn:{' '}
                                    {contract.start_at.format('DD.MM.YYYY')}
                                </Box>
                                {contract.end_at && (
                                    <Box mr={1} component="span">
                                        Gekündigt zum:{' '}
                                        {contract.end_at.format('DD.MM.YYYY')}
                                    </Box>
                                )}
                                {contract.earliest_termination_at && (
                                    <Box component="span">
                                        Frühester Kündigungstermin:{' '}
                                        {contract.earliest_termination_at.format(
                                            'DD.MM.YYYY'
                                        )}
                                    </Box>
                                )}
                            </Box>
                            <Box component="span" display="block">
                                {contract.comment}
                            </Box>
                        </>
                    }
                />
                <ListItemSecondaryAction>
                    <>
                        {contract.uri && (
                            <IconButton edge="start" onClick={onClickView}>
                                <OpenInNew />
                            </IconButton>
                        )}
                        <IconButton edge="end" onClick={openDialog}>
                            <Edit />
                        </IconButton>
                    </>
                </ListItemSecondaryAction>
            </ListItem>
            <CrmDialog
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
                maxWidth="sm"
                confirmClose
            >
                <ContractFormDialog
                    action={`contracts/${contract.id}`}
                    closeDialog={closeDialog}
                    responseHandler={contractResponseHandler}
                    contract={contract}
                />
            </CrmDialog>
        </>
    );
};

export default ContractView;
