import React, { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import 'typeface-roboto';
import theme from '../theme';
import AuthStore from '../stores/AuthStore';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlobalStore from '../stores/GlobalStore';
import Main from './Main';
import { SnackbarProvider } from 'notistack';
import ConfigStore from '../stores/ConfigStore';
import { initializeSentry } from '../../../../../../resources/assets/react/funcs/error-handling';
import ReportDoneNotification from './modules/ReportDoneNotification';
import Unauthenticated from './Unauthenticated';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';

import 'dayjs/locale/de';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

initializeSentry(
    window.Voigt.sentryDsn,
    window.Voigt.environment,
    window.Voigt.version,
    window.Voigt.sentrySampleRate,
    window.Voigt.sentryTracesSampleRate,
    'crm'
);

Chart.register(
    LineElement,
    PointElement,
    LineController,
    LinearScale,
    CategoryScale,
    BarElement,
    ArcElement,
    Legend,
    Tooltip
);

dayjs.locale('de');
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

dayjs.tz.setDefault('Europe/Berlin');

const App: FC = () => (
    <BrowserRouter
        basename={window.Voigt.environment === 'local' ? '/crm' : '/'}
    >
        <ThemeProvider theme={theme}>
            <Routes>
                <Route
                    path="/report_done"
                    element={<ReportDoneNotification />}
                />
                <Route path="/401" element={<Unauthenticated />} />
                <Route
                    path="*"
                    element={
                        <SnackbarProvider maxSnack={3}>
                            <ConfigStore>
                                <AuthStore>
                                    <GlobalStore>
                                        <CssBaseline />
                                        <Main />
                                    </GlobalStore>
                                </AuthStore>
                            </ConfigStore>
                        </SnackbarProvider>
                    }
                ></Route>
            </Routes>
        </ThemeProvider>
    </BrowserRouter>
);

export default App;
