import React, { ReactElement, useContext } from 'react';
import { Box, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { EntityContext } from '../../../helpers/entities';
import { Contact } from '../../../mappers/contactMapper';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import Short from '../ContactPerson/Short';

const ContactPersonRows = (): ReactElement => {
    const { entities: contactPersons } = useContext(EntityContext);

    return (
        <>
            {contactPersons?.map((contactPerson: ContactPerson) => (
                <TableRow key={contactPerson.id}>
                    <TableCell>
                        <Short contactPerson={contactPerson} useSortableName />
                    </TableCell>
                    <TableCell>
                        {contactPerson.contacts
                            ?.slice(0, 10)
                            .map((contact: Contact) => (
                                <Box key={contact.id}>
                                    <Link to={`/contact/${contact.id}`}>
                                        {contact.name}
                                    </Link>
                                </Box>
                            ))}
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
};

export default ContactPersonRows;
