import React, { ReactElement } from 'react';
import {
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import CrmDialog from '../helpers/CrmDialog';
import { Close } from '@mui/icons-material';
import OfficeUsersTable from './Offices/OfficeUsersTable';

interface Props {
    officeUnique: string | null;
    officeName: string | null;
    dialogOpen: boolean;
    closeDialog(): void;
}

const OfficeUsersDialog = ({
    officeUnique,
    officeName,
    dialogOpen,
    closeDialog,
}: Props): ReactElement => (
    <CrmDialog dialogOpen={dialogOpen} closeDialog={closeDialog}>
        <DialogTitle>
            <Typography variant="h1" component="h1">
                Zugewiesene Benutzer für Standort {officeName}
            </Typography>
            <IconButton onClick={closeDialog} size="small" edge="end">
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <OfficeUsersTable officeUnique={officeUnique} />
        </DialogContent>
    </CrmDialog>
);

export default OfficeUsersDialog;
