import React, { FC, ReactNode, useContext } from 'react';
import {
    IconButton,
    TableCell,
    TableRow,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { EntityContext } from '../../../helpers/entities';
import { Report, ReportType, reportTypes } from '../../../mappers/reportMapper';
import { CallMade } from '@mui/icons-material';
import Short from '../ContactPerson/Short';
import WarningIcon from '../../helpers/WarningIcon';
import {
    getTextExcerptWithSearchText,
    TextParts,
} from '../../../helpers/getTextExcerptWithSearchText';

interface Props {
    openDialog(): void;
    setDialogReportId(reportId: string): void;
    searchText?: string | null;
}

const ReportRows: FC<Props> = ({
    openDialog,
    setDialogReportId,
    searchText,
}) => {
    const theme: Theme = useTheme();
    const { entities: reports } = useContext(EntityContext);

    const handleDialogOpen: (reportId: string) => void = reportId => {
        setDialogReportId(reportId);
        openDialog();
    };

    if (!reports) {
        return <></>;
    }

    return (
        <>
            {reports.map((report: Report): ReactNode => {
                const textExcerptWithSearchText: TextParts | null = searchText
                    ? getTextExcerptWithSearchText(
                          report.report,
                          searchText,
                          170
                      )
                    : null;

                return (
                    <React.Fragment key={report.id}>
                        <TableRow>
                            <TableCell>{report.date_formatted}</TableCell>
                            <TableCell>
                                {report.contact?.name}
                                <Typography
                                    color="textSecondary"
                                    component="div"
                                    variant="body2"
                                >
                                    {report.contact?.address?.city}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                {reportTypes[report.type as ReportType]}
                                {report.contact_persons.length > 0 && (
                                    <>
                                        <Typography
                                            color="textSecondary"
                                            component="span"
                                            variant="body2"
                                        >
                                            {' '}
                                            mit
                                        </Typography>
                                        <br />
                                        {report.contact_persons.map(
                                            contactPerson => (
                                                <Short
                                                    key={contactPerson.id}
                                                    contactPerson={
                                                        contactPerson
                                                    }
                                                />
                                            )
                                        )}
                                    </>
                                )}
                            </TableCell>
                            <TableCell
                                sx={{
                                    width: '50%',
                                }}
                            >
                                {textExcerptWithSearchText ? (
                                    <>
                                        {
                                            textExcerptWithSearchText.firstTextPart
                                        }
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            sx={{
                                                backgroundColor: `${theme.palette.yellow.main}80`,
                                                color: theme.palette.yellow
                                                    .contrastText,
                                                borderRadius:
                                                    theme.shape.borderRadius,
                                                padding: '4px 1px',
                                            }}
                                        >
                                            {
                                                textExcerptWithSearchText.searchText
                                            }
                                        </Typography>
                                        {
                                            textExcerptWithSearchText.secondTextPart
                                        }
                                    </>
                                ) : (
                                    report.report_excerpt
                                )}
                            </TableCell>
                            <TableCell>{report.user?.name}</TableCell>

                            <TableCell>
                                {report.notification_date ? (
                                    <>
                                        {report.overdue && <WarningIcon />}
                                        {report.notification_date_formatted}
                                    </>
                                ) : (
                                    '-'
                                )}
                            </TableCell>

                            <TableCell>
                                <IconButton
                                    edge="end"
                                    color="secondary"
                                    onClick={() => handleDialogOpen(report.id)}
                                >
                                    <CallMade />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default ReportRows;
