import React, { FC, useEffect, useMemo, useState } from 'react';
import CrmToolbar from './helpers/CrmToolbar';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
} from '@mui/material';
import { ContactEntity } from '../helpers/entities';
import { Contact } from '../mappers/contactMapper';
import useEntities from '../hooks/useEntities';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ContactsMapMarker from './modules/ContactsMapMarker';
import { calculateAverageOfCoordinates } from '../helpers/calculateAverageOfCoordinates';
import { Location, useLocation, useParams } from 'react-router-dom';
import { LatLngExpression } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

const ContactsMap: FC = () => {
    const { contactId } = useParams<{ contactId?: string }>();
    const [zoom, setZoom] = useState<number>(6);
    const [selected, setSelected] = useState<string | undefined>('');
    const { entities: contacts } = useEntities<Contact>(ContactEntity, {
        withRelations: ['empfehler', 'address'],
    });

    const location: Location = useLocation();
    const contactCenter: LatLngExpression = location.state as LatLngExpression;

    const center = useMemo(
        () =>
            !contactCenter
                ? calculateAverageOfCoordinates(contacts)
                : contactCenter,
        [contacts]
    );

    useEffect(() => {
        if (contactCenter) {
            setSelected(contactId);
            setZoom(17);
        }
    }, [contacts]);

    return (
        <>
            <Paper>
                <CrmToolbar title="Karte mit Kontakten" />
            </Paper>
            <Paper>
                <Box my={3} p={3} height="80vh">
                    {contacts && (
                        <MapContainer
                            center={center}
                            zoom={zoom}
                            scrollWheelZoom
                            style={{ height: '100%' }}
                            attributionControl={false}
                        >
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <MarkerClusterGroup showCoverageOnHover={false}>
                                {contacts.map(contact => (
                                    <ContactsMapMarker
                                        key={contact.id}
                                        contact={contact}
                                        selectedId={selected}
                                    />
                                ))}
                            </MarkerClusterGroup>
                            <div className="leaflet-bottom leaflet-right">
                                <Paper className="leaflet-control">
                                    <Box
                                        display="flex"
                                        flexDirection={{
                                            xs: 'row',
                                            sm: 'column',
                                        }}
                                    >
                                        <List
                                            dense
                                            sx={{
                                                display: 'flex',
                                                flexDirection: {
                                                    xs: 'row',
                                                    sm: 'column',
                                                },
                                            }}
                                        >
                                            {selected && (
                                                <ListItem>
                                                    <ListItemIcon
                                                        style={{ minWidth: 23 }}
                                                    >
                                                        <img
                                                            src="/images/crm/marker-icon-green.svg"
                                                            alt="green marker"
                                                            width={15}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Gesucht" />
                                                </ListItem>
                                            )}
                                            <ListItem>
                                                <ListItemIcon
                                                    style={{ minWidth: 23 }}
                                                >
                                                    <img
                                                        src="/images/crm/marker-icon-red.svg"
                                                        alt="red marker"
                                                        width={15}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary="Empfehler" />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon
                                                    style={{ minWidth: 23 }}
                                                >
                                                    <img
                                                        src="/images/crm/marker-icon-blue.svg"
                                                        alt="blue marker"
                                                        width={15}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary="Kontakt" />
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Paper>
                            </div>
                        </MapContainer>
                    )}
                </Box>
            </Paper>
        </>
    );
};

export default ContactsMap;
