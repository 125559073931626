import React, { ReactElement } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    useTheme,
} from '@mui/material';
import { CrmTheme } from '../../theme';
import { LockOutlined } from '@mui/icons-material';

interface Props {
    title: string;
    titleAction?: any;
    children: any;
    footer?: any;
    disablePaddingTop?: boolean;
    adminBox?: boolean;
    marginTop?: boolean;
    titleColor?: 'textPrimary' | 'error';
    noContent?: boolean;
}

const CrmCard = ({
    title,
    titleAction,
    footer,
    children,
    disablePaddingTop,
    adminBox,
    marginTop,
    titleColor = 'textPrimary',
    noContent,
}: Props): ReactElement => {
    const theme: CrmTheme = useTheme();

    return (
        <Card sx={{ marginTop: marginTop ? theme.spacing(2) : undefined }}>
            <CardHeader
                sx={{ paddingBottom: theme.spacing(1), minHeight: 46 }}
                title={<Box mt={-1}>{title}</Box>}
                action={
                    <>
                        {titleAction}
                        {adminBox && (
                            <Tooltip
                                title="Diese Box ist nur für Administratoren sichtbar."
                                placement="top"
                                arrow
                            >
                                <span>
                                    <IconButton size="small" disabled>
                                        <LockOutlined />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                    </>
                }
                slotProps={{ title: { variant: 'h3', color: titleColor } }}
            />
            {noContent ? (
                children
            ) : (
                <CardContent sx={{ paddingTop: 0 }}>
                    <Divider
                        sx={{
                            marginBottom: disablePaddingTop
                                ? ''
                                : theme.spacing(2),
                        }}
                    />
                    {children}
                    {footer && (
                        <>
                            <Divider sx={{ marginBottom: theme.spacing(2) }} />
                            <Grid container justifyContent="flex-end">
                                {footer}
                            </Grid>
                        </>
                    )}
                </CardContent>
            )}
        </Card>
    );
};

export default CrmCard;
