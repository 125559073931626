import React, { Fragment, ReactElement, useContext } from 'react';
import {
    Box,
    Button,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Typography,
} from '@mui/material';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { Report, reportTypes } from '../../mappers/reportMapper';
import CrmLabel from '../helpers/CrmLabel';
import useEntity from '../../hooks/useEntity';
import { ReportEntity } from '../../helpers/entities';
import CrmDialog from '../helpers/CrmDialog';
import { Link } from 'react-router-dom';
import Ajax from '../../helpers/Ajax';
import { useSnackbar } from 'notistack';
import useEditable from '../../hooks/useEditable';
import ReportDialogForm from './Report/ReportDialogForm';
import FormBuilder from '../helpers/forms/FormBuilder';
import * as yup from 'yup';
import dayjs from 'dayjs';
import logError from '../../errors/logError';
import DeleteAction from '../helpers/actions/DeleteAction';
import { AuthContext } from '../../stores/AuthStore';
import EditCloseAction from '../helpers/actions/EditCloseAction';
import Nl2br from '../helpers/Nl2br';
import WarningIcon from '../helpers/WarningIcon';
import { User } from '../../mappers/userMapper';
import { matchOffices } from '../../helpers/matchOffices';

interface Props {
    reportId: string | null;
    dialogOpen: boolean;
    closeDialog(): void;
    responseHandler?(): void;
}

const ReportDialog = ({
    reportId,
    dialogOpen,
    closeDialog,
    responseHandler,
}: Props): ReactElement | null => {
    const { entity: report, reloadEntity: reloadReport } = useEntity<Report>(
        ReportEntity,
        reportId
    );

    const { enqueueSnackbar } = useSnackbar();
    const { editOpen, editClose, isEdited } = useEditable();
    const { userCan, user } = useContext<AuthContext>(AuthContext);

    const closeDialogAndAbortEditing: () => void = () => {
        editClose();
        closeDialog();
    };

    const handleClickDone: () => Promise<void> = async () => {
        try {
            await Ajax.post<any>('reports/' + reportId, {
                done: dayjs().format('YYYY-MM-DD'),
            });

            reloadReport();
            responseHandler && responseHandler();
        } catch (e) {
            enqueueSnackbar('Fehler beim Aktualisieren des Berichtes!', {
                variant: 'error',
            });

            logError(e);
        }
    };

    const editResponseHandler: (response: any) => void = response => {
        reloadReport();
        responseHandler && responseHandler();
        editClose();
    };

    const deleteResponseHandler: () => void = () => {
        closeDialog();
        responseHandler && responseHandler();
    };

    if (!report || !user) {
        return null;
    }

    if (isEdited) {
        return (
            <CrmDialog
                dialogOpen={dialogOpen}
                closeDialog={closeDialogAndAbortEditing}
                maxWidth="md"
                confirmClose
            >
                {!report ? (
                    <LinearProgress />
                ) : (
                    <FormBuilder
                        action={'reports/' + reportId}
                        initialValues={{
                            date: report.date.format('DD.MM.YYYY'),
                            subject: report.subject,
                            type: report.type,
                            report: report.report,
                            notification_date: report.notification_date
                                ? report.notification_date.format('DD.MM.YYYY')
                                : null,
                            internal: [],
                            external: [],
                            contact_persons:
                                report.contact_persons.map(
                                    contactPerson => contactPerson.id
                                ) ?? [],
                            internal_participants:
                                report.internal_participants.map(
                                    user => user.id
                                ) ?? [],
                            competitors:
                                report.competitors.map(competitor =>
                                    competitor.id.toString()
                                ) ?? [],
                            done: report.done
                                ? report.done.format('DD.MM.YYYY')
                                : null,
                        }}
                        validationSchema={validationSchema}
                        responseHandler={editResponseHandler}
                        errorMessage="Fehler beim Speichern des Berichtes!"
                    >
                        <DialogTitle>
                            <Typography component="h2">
                                Bericht bearbeiten
                            </Typography>
                            <EditCloseAction editClose={editClose} edge="end" />
                        </DialogTitle>
                        <ReportDialogForm contact={report.contact!} />
                    </FormBuilder>
                )}
            </CrmDialog>
        );
    }

    return (
        <CrmDialog
            dialogOpen={dialogOpen}
            closeDialog={closeDialog}
            maxWidth="sm"
        >
            <DialogTitle>
                <Typography component="h2">Bericht</Typography>
                {userCanEditReport(user, report) && (
                    <IconButton
                        size="small"
                        onClick={editOpen}
                        sx={{
                            marginLeft: 'auto',
                        }}
                    >
                        <Edit />
                    </IconButton>
                )}
                {userCan('delete') && (
                    <DeleteAction
                        label="Bericht löschen"
                        action={'reports/' + reportId}
                        onDeleteHandler={deleteResponseHandler}
                        icon={Delete}
                        size="small"
                    >
                        <Typography>
                            Sind Sie sicher, dass Sie diesen Bericht
                            unwiderruflich löschen wollen?
                        </Typography>
                    </DeleteAction>
                )}
                <IconButton onClick={closeDialog} size="small" edge="end">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {!report ? (
                    <LinearProgress />
                ) : (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <CrmLabel>Verfasser</CrmLabel>
                                <Typography variant="body2">
                                    {report.user?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <CrmLabel>Kontakt</CrmLabel>
                                <Typography variant="body2">
                                    <Link to={'/contact/' + report.contact_id}>
                                        {report.contact?.name}
                                    </Link>
                                    {report.contact?.address?.city
                                        ? ` (${report.contact.address.city})`
                                        : ''}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <CrmLabel>Ansprechpartner</CrmLabel>
                                {report.contact_persons.length === 0 && '-'}
                                {report.contact_persons.map(contactPerson => (
                                    <Box key={contactPerson.id}>
                                        <Link
                                            to={`/contact_person/${contactPerson.id}`}
                                        >
                                            {contactPerson.name}
                                        </Link>
                                    </Box>
                                ))}
                            </Grid>
                            <Grid item xs={6}>
                                <CrmLabel>Interne Teilnehmer</CrmLabel>
                                {report.internal_participants.length === 0 &&
                                    '-'}
                                {report.internal_participants.map(user => (
                                    <Box key={user.id}>{user.name}</Box>
                                ))}
                            </Grid>
                            <Grid item xs={6}>
                                <CrmLabel>Datum</CrmLabel>
                                <Typography variant="body2">
                                    {report.date_formatted}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <CrmLabel>Kontaktart</CrmLabel>
                                <Typography variant="body2">
                                    {reportTypes[report.type]}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <CrmLabel>Thema / Inhalt</CrmLabel>
                                <Typography variant="body2">
                                    {report.subject}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <CrmLabel>Wettbewerber</CrmLabel>
                                {report.competitors.length === 0
                                    ? '-'
                                    : report.competitors.map(
                                          ({ competitor, id }, index) => (
                                              <Fragment
                                                  key={`competitor-${id}`}
                                              >
                                                  {index !== 0 && ', '}
                                                  {competitor}
                                              </Fragment>
                                          )
                                      )}
                            </Grid>
                            <Grid item xs={12}>
                                <CrmLabel>Bericht</CrmLabel>
                                <Typography variant="body2">
                                    <Nl2br>{report.report}</Nl2br>
                                </Typography>
                            </Grid>
                            {report.notification_date && (
                                <>
                                    <Grid item xs={6}>
                                        <CrmLabel>Wiedervorlage</CrmLabel>
                                        <Typography variant="body2">
                                            {report.overdue && <WarningIcon />}
                                            {report.notification_date_formatted}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CrmLabel>Erledigt</CrmLabel>
                                        {report.done ? (
                                            <>
                                                <Typography variant="body2">
                                                    {report.done_formatted}
                                                </Typography>
                                            </>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                startIcon={<Check />}
                                                onClick={handleClickDone}
                                            >
                                                erledigt
                                            </Button>
                                        )}
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                {report.copies.length > 0 && (
                                    <CrmLabel>Kopien</CrmLabel>
                                )}
                                {report.copies.map(copy => (
                                    <Grid container key={copy.id} spacing={2}>
                                        <Grid item xs={2}>
                                            {copy.type === 'internal'
                                                ? 'Intern'
                                                : 'Extern'}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {copy.send_at
                                                ? dayjs(copy.send_at).format(
                                                      'DD.MM.YYYY'
                                                  )
                                                : 'Wird versendet'}
                                        </Grid>
                                        <Grid item>{copy.email}</Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </>
                )}
            </DialogContent>
        </CrmDialog>
    );
};

export default ReportDialog;

const validationSchema = yup.object().shape({
    external: yup
        .array()
        .of(
            yup
                .string()
                .email(
                    params =>
                        `"${params.originalValue}" ist keine gültige E-Mail Adresse.`
                )
        ),
    external_textfield_input: yup
        .string()
        .test(
            'is-full',
            'Bestätigen Sie die E-Mail-Adresse mit [ENTER].',
            (value, context) => !value
        ),
});

const userCanEditReport = (user: User, report: Report): boolean =>
    user.isAdmin || matchOffices(report.contact?.offices, user.offices);
