import React, { ReactElement, useContext } from 'react';
import {
    Box,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    Pagination,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import useEntities from '../../../hooks/useEntities';
import { Report } from '../../../mappers/reportMapper';
import { ReportEntity, UserEntity } from '../../../helpers/entities';
import usePagination from '../../../hooks/usePagination';
import CrmCard from '../../helpers/CrmCard';
import ReportList from '../ReportList';
import { AuthContext } from '../../../stores/AuthStore';
import MenuItem from '@mui/material/MenuItem';
import Ajax from '../../../helpers/Ajax';
import logError from '../../../errors/logError';
import { FilterList, Sort } from '@mui/icons-material';
import Select from '@mui/material/Select';
import { useToggle } from 'react-use';

const Notifications = (): ReactElement => {
    const { user, reloadUser } = useContext<AuthContext>(AuthContext);

    const [showScopeFilters, toggleShowScopeFilters] = useToggle(false);
    const [showSortFilters, toggleShowSortFilters] = useToggle(false);

    const {
        entities: reports,
        totalCount,
        reloadEntities,
    } = useEntities<Report>(ReportEntity, {
        scopes: [
            'notified',
            ...(user?.isAdmin
                ? []
                : [
                      user && user.report_scope_selection
                          ? user.report_scope_selection
                          : 'ownReports',
                  ]),
        ],
        orderBy: user?.report_notification_order
            ? user.report_notification_order +
              (user.report_notification_order === 'date' ? ':DESC' : ':ASC')
            : 'notification_date:ASC',
    });

    const {
        page,
        totalPages,
        handlePageChange,
        startEntryIndex,
        endEntryIndex,
    } = usePagination(totalCount, 2);

    const onChangeSorting = async (event: SelectChangeEvent): Promise<void> => {
        try {
            await Ajax.post(UserEntity.apiBaseUrl + '/' + user?.id, {
                report_notification_order: event.target.value,
            });
            reloadUser();
            reloadEntities();
        } catch (e) {
            logError(e);
        }
    };

    const onChangeScope = async (event: SelectChangeEvent): Promise<void> => {
        try {
            await Ajax.post(UserEntity.apiBaseUrl + '/' + user?.id, {
                report_scope_selection: event.target.value,
            });
            reloadUser();
            reloadEntities();
        } catch (e) {
            logError(e);
        }
    };

    const onClickFilters = () => {
        toggleShowSortFilters();
        toggleShowScopeFilters(false);
    };

    const onClickSorting = () => {
        toggleShowScopeFilters();
        toggleShowSortFilters(false);
    };

    return (
        <CrmCard
            title="Wiedervorlagen"
            titleAction={
                <>
                    {!user?.isAdmin && (
                        <IconButton size="small" onClick={onClickFilters}>
                            <FilterList />
                        </IconButton>
                    )}
                    <IconButton size="small" onClick={onClickSorting}>
                        <Sort />
                    </IconButton>
                </>
            }
            footer={
                totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        size="small"
                    />
                )
            }
        >
            <Box mb={2} display={showScopeFilters ? 'block' : 'none'}>
                <FormControl size="small" margin="normal">
                    <InputLabel>Auswahl</InputLabel>
                    <Select
                        label="Auswahl"
                        onChange={onChangeScope}
                        value={user?.report_scope_selection ?? 'ownReports'}
                    >
                        {loadOptions.map(loadOption => (
                            <MenuItem
                                key={loadOption.value}
                                value={loadOption.value}
                            >
                                {loadOption.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box mb={2} display={showSortFilters ? 'block' : 'none'}>
                <FormControl size="small" margin="normal">
                    <InputLabel>Sortierung</InputLabel>
                    <Select
                        label="Sortierung"
                        onChange={onChangeSorting}
                        value={
                            user?.report_notification_order ??
                            'notification_date'
                        }
                    >
                        {sortOptions.map(sortOption => (
                            <MenuItem
                                key={sortOption.value}
                                value={sortOption.value}
                            >
                                {sortOption.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {reports !== null ? (
                reports.length ? (
                    <ReportList
                        reports={reports}
                        startEntryIndex={startEntryIndex}
                        endEntryIndex={endEntryIndex}
                        reportChangeHandler={reloadEntities}
                    />
                ) : (
                    <Typography variant="body2">
                        Keine offenen Wiedervorlagen.
                    </Typography>
                )
            ) : (
                <CircularProgress size={20} />
            )}
        </CrmCard>
    );
};

export default Notifications;

const sortOptions = [
    { value: 'date', name: 'Nach Datum' },
    { value: 'notification_date', name: 'Nach Wiedervorlage' },
];

const loadOptions = [
    { value: 'ownReports', name: 'Nur von mir verfasste Berichte' },
    { value: 'mainOfficeReports', name: 'Alle Berichte aus meinen Standorten' },
];
