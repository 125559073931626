import React, { ReactElement, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import CrmCard from '../../helpers/CrmCard';
import { CrmTheme } from '../../../theme';
import { lighten, useTheme } from '@mui/material';
import Ajax from '../../../helpers/Ajax';
import dayjs from 'dayjs';
import { AutocompleteOption } from '../../../crm';
import { useDebounce } from 'react-use';

interface Props {
    selectedUser: AutocompleteOption;
    startDate: Date;
    endDate: Date;
}

interface OfficeShares {
    labels: string[];
    shares: number[];
}

const UserOfficesDoughnut = ({
    selectedUser,
    startDate,
    endDate,
}: Props): ReactElement => {
    const theme: CrmTheme = useTheme();
    const apiUrl = 'statistics/office_reports/user/' + selectedUser.value;

    const [officeShares, setOfficeShares] = useState<OfficeShares | null>(null);

    const getEntityReports = async (): Promise<void> => {
        const reports = await Ajax.get<Obj>(apiUrl, {
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
        });

        const officeSharesTmp: OfficeShares = {
            labels: [],
            shares: [],
        };

        let totalReports = 0;

        Object.keys(reports).map(reportLabel => {
            totalReports += reports[reportLabel].EK + reports[reportLabel].BK;
        });

        Object.keys(reports).map(reportLabel => {
            const share =
                Math.round(
                    ((reports[reportLabel].EK + reports[reportLabel].BK) /
                        totalReports) *
                        100 *
                        10
                ) / 10;

            officeSharesTmp.labels.push(`${reportLabel} (${share}%)`);
            officeSharesTmp.shares.push(share);
        });

        setOfficeShares(officeSharesTmp);
    };

    useDebounce(getEntityReports, 500, [startDate, endDate, apiUrl]);

    const data = {
        labels: officeShares?.labels,
        datasets: [
            {
                label: 'Anteil der Termine pro Niederlassung',
                data: officeShares?.shares,
                backgroundColor: [
                    theme.palette.primary.main,
                    lighten(theme.palette.primary.main, 0.7),
                    theme.palette.secondary.main,
                    lighten(theme.palette.secondary.main, 0.7),
                ],
                hoverOffset: 4,
            },
        ],
    };

    return (
        <CrmCard title="Termine pro Niederlassung (prozentual)">
            {officeShares && <Doughnut data={data} />}
        </CrmCard>
    );
};

export default UserOfficesDoughnut;
