import React, { ReactElement } from 'react';
import useEditable from '../../../hooks/useEditable';
import FormBuilder from './FormBuilder';
import CrmCard from '../CrmCard';
import { IconButton } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
import EditCloseAction from '../actions/EditCloseAction';

interface Props {
    title: string;
    apiUrl: string;
    form: ReactElement;
    view: ReactElement;
    reloadHandler: { (): void };
    initialValues: Obj;
    preSubmitFunction?: ((values: any) => Promise<any>) | null;
    additionalViewActions?: ReactElement | null;
}

const FormBuilderCard = ({
    title,
    form,
    view,
    reloadHandler,
    apiUrl,
    initialValues,
    preSubmitFunction = null,
    additionalViewActions = null,
}: Props): ReactElement => {
    const { isEdited, editOpen, editClose } = useEditable();

    const editResponseHandler = () => {
        editClose();
        reloadHandler();
    };

    if (isEdited) {
        return (
            <FormBuilder
                action={apiUrl}
                initialValues={initialValues}
                responseHandler={editResponseHandler}
                errorMessage="Fehler beim Speichern"
                preSubmitFunction={preSubmitFunction}
            >
                <CrmCard
                    title={title}
                    titleAction={
                        <>
                            <IconButton size="small" type="submit">
                                <Save />
                            </IconButton>
                            <EditCloseAction editClose={editClose} />
                        </>
                    }
                >
                    {form}
                </CrmCard>
            </FormBuilder>
        );
    }

    return (
        <CrmCard
            title={title}
            titleAction={
                <>
                    {additionalViewActions}
                    <IconButton size="small" onClick={editOpen}>
                        <Edit />
                    </IconButton>
                </>
            }
        >
            {view}
        </CrmCard>
    );
};

export default FormBuilderCard;
