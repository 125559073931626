import {
    createTheme,
    DialogTitleProps,
    Theme,
    ThemeOptions,
} from '@mui/material';
import { purple, teal } from '@mui/material/colors';
import webportalTheme from '../../../../../resources/assets/react/theme';

declare module '@mui/material' {
    interface Palette {
        yellow: Palette['primary'];
    }
    interface PaletteOptions {
        yellow: PaletteOptions['primary'];
    }
}

const defaultTheme: Theme = createTheme();

// A custom theme for this app
const theme: ThemeOptions = createTheme({
    palette: {
        primary: {
            main: teal['500'],
            contrastText: '#FFF',
        },
        secondary: {
            main: purple['900'],
        },
        background: {
            default: '#f5f5f5',
        },
        yellow: webportalTheme.palette.secondary,
    },

    typography: {
        h1: {
            fontSize: '1.5rem',
            fontWeight: 400,
            paddingTop: '8px',
            paddingBottom: '8px',
        },
        h2: {
            fontSize: '1.2rem',
        },
        h3: {
            fontSize: '1rem',
        },
        h4: {
            fontSize: '0.9rem',
        },
        h5: {
            fontSize: '0.8rem',
        },
        h6: {
            fontSize: '0.75rem',
        },
    },
    components: {
        MuiDialogTitle: {
            defaultProps: {
                disableTypography: true,
            } as Partial<DialogTitleProps>,
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    [defaultTheme.breakpoints.up('sm')]: {
                        paddingLeft: '16px',
                        paddingRight: '16px',
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                margin: 'normal',
                fullWidth: true,
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'outlined',
                fullWidth: true,
            },
        },
        MuiLink: {
            defaultProps: {
                color: 'secondary',
                style: {
                    cursor: 'pointer',
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                color: 'secondary',
            },
        },
        MuiBackdrop: {
            defaultProps: {
                style: {
                    color: '#FFF',
                },
            },
        },
    },
});

export type CrmTheme = Theme;

export default theme;
