import React, { FC } from 'react';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import CrmCard from '../../helpers/CrmCard';
import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { Close, Edit, Link as LinkIcon, LinkOff } from '@mui/icons-material';
import useEditable from '../../../hooks/useEditable';
import useDialog from '../../../hooks/useDialog';
import Ajax from '../../../helpers/Ajax';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import CrmDialog from '../../helpers/CrmDialog';
import ContactLinkDialog from './ContactLinkDialog';
import logError from '../../../errors/logError';
import { ContactEntity } from '../../../helpers/entities';

interface Props {
    contactPerson: ContactPerson;
    reloadContactPerson(): void;
}

const Contacts: FC<Props> = ({ contactPerson, reloadContactPerson }) => {
    const { isEdited, editOpen, editClose } = useEditable();
    const {
        openDialog: openContactLinkDialog,
        closeDialog: closeContactLinkDialog,
        dialogOpen: contactLinkDialogOpen,
    } = useDialog();
    const { enqueueSnackbar } = useSnackbar();

    const contactLinkResponseHandler: () => void = () => {
        reloadContactPerson();
        closeContactLinkDialog();
    };

    const handleContactUnlink: (
        contactId: string
    ) => Promise<void> = async contactId => {
        try {
            await Ajax.delete<any>(
                ContactEntity.apiBaseUrl +
                    '/' +
                    contactId +
                    '/contact_persons/' +
                    contactPerson.id
            );

            reloadContactPerson();
        } catch (e) {
            enqueueSnackbar('Fehler beim Lösen des Kontaktes!', {
                variant: 'error',
            });
            logError(e);
        }
    };

    return (
        <CrmCard
            title="Kontakte"
            titleAction={
                isEdited ? (
                    <IconButton size="small" onClick={editClose}>
                        <Close />
                    </IconButton>
                ) : (
                    <>
                        <IconButton
                            size="small"
                            onClick={openContactLinkDialog}
                        >
                            <LinkIcon />
                        </IconButton>
                        <IconButton size="small" onClick={editOpen}>
                            <Edit />
                        </IconButton>
                    </>
                )
            }
        >
            {contactPerson.contacts?.length ? (
                <List
                    dense
                    disablePadding
                    sx={{
                        marginTop: '-10px',
                    }}
                >
                    {contactPerson.contacts.map(contact => (
                        <ListItem
                            disableGutters
                            key={contact.id}
                            secondaryAction={
                                isEdited && (
                                    <Tooltip
                                        title={
                                            (contactPerson.contacts?.length ??
                                                1) === 1
                                                ? 'Muss mindestens einem Kontakt zugeordnet sein.'
                                                : ''
                                        }
                                        placement="top"
                                        arrow
                                    >
                                        <span>
                                            <IconButton
                                                edge="end"
                                                disabled={
                                                    (contactPerson?.contacts
                                                        ?.length ?? 1) === 1
                                                }
                                            >
                                                <LinkOff
                                                    fontSize="small"
                                                    onClick={() =>
                                                        handleContactUnlink(
                                                            contact.id
                                                        )
                                                    }
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                )
                            }
                        >
                            <ListItemText
                                primary={
                                    <Link to={'/contact/' + contact.id}>
                                        {contact.name}
                                    </Link>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography variant="body2">
                    Bisher wurden keine Kontakte zugewiesen.
                </Typography>
            )}
            <CrmDialog
                dialogOpen={contactLinkDialogOpen}
                closeDialog={closeContactLinkDialog}
                maxWidth="sm"
            >
                <ContactLinkDialog
                    closeDialog={closeContactLinkDialog}
                    responseHandler={contactLinkResponseHandler}
                    contactPersonId={contactPerson.id}
                />
            </CrmDialog>
        </CrmCard>
    );
};

export default Contacts;
