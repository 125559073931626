import React, { ReactElement } from 'react';
import { Call } from '@mui/icons-material';
import FormattingBase from './FormattingBase';

interface Props {
    phone: string;
}

const Phone = ({ phone }: Props): ReactElement => (
    <FormattingBase
        label={phone}
        href={`tel:${phone.replace(/\D+/g, '')}`}
        title={`${phone} anrufen`}
        icon={Call}
    />
);

export default Phone;
