import React, { ReactElement, useMemo, useState } from 'react';
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import CrmToolbar from './helpers/CrmToolbar';
import Ajax from '../helpers/Ajax';
import { useAsync, useToggle } from 'react-use';
import AboptimalUserRow from './modules/AboptimalUsers/AboptimalUserRow';
import { useSnackbar } from 'notistack';
import logError from '../errors/logError';
import {
    filterAboptimalUsers,
    filterValues,
} from '../helpers/filterAboptimalUsers';
import { AboptimalUser } from '../mappers/aboptimalUserMapper';
import { FilterList } from '@mui/icons-material';

const filterOptions: { value: filterValues; name: string }[] = [
    { value: filterValues.noContact, name: 'Nicht zugewiesene Benutzer' },
    { value: filterValues.withContact, name: 'Zugewiesene Benutzer' },
    { value: filterValues.all, name: 'Alle' },
];

const AboptimalUsers = (): ReactElement => {
    const [aboptimalUsers, setAboptimalUsers] = useState<
        AboptimalUser[] | null
    >(null);

    const [showFilters, toggleShowFilters] = useToggle(false);
    const [filter, setFilter] = useState<filterValues>(filterValues.noContact);

    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(0);

    const { enqueueSnackbar } = useSnackbar();

    const fetchAboptimalUsers = async (): Promise<void> => {
        try {
            const aboptimalUsers = await Ajax.get<AboptimalUser[]>(
                'rentalCarReports/aboptimalUsers'
            );
            setAboptimalUsers(aboptimalUsers);
        } catch (e) {
            enqueueSnackbar('Fehler beim Laden der Aboptimal-Benutzer!', {
                variant: 'error',
            });
            logError(e);
            setAboptimalUsers([]);
        }
    };

    const onSuccessHandler = async () => {
        enqueueSnackbar('Aboptimal-Benutzer erfolgreich zugeordnet.', {
            variant: 'success',
        });
        await fetchAboptimalUsers();
    };

    useAsync(fetchAboptimalUsers, []);

    const onChangeFilter = (event: SelectChangeEvent): void => {
        setFilter(event.target.value as filterValues);
        setPage(0);
    };

    const filteredUsers = useMemo<AboptimalUser[]>(
        () =>
            aboptimalUsers?.filter((aboptimalUser: AboptimalUser) =>
                filterAboptimalUsers(filter, aboptimalUser)
            ) ?? [],
        [aboptimalUsers, filter]
    );

    const usersOfCurrentPage = useMemo<AboptimalUser[]>(
        () =>
            filteredUsers.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [page, rowsPerPage, filteredUsers]
    );

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paper = (
        <>
            <Paper>
                <CrmToolbar
                    title="Aboptimal-Benutzer"
                    buttons={
                        <IconButton onClick={toggleShowFilters}>
                            <FilterList />
                        </IconButton>
                    }
                />
                {showFilters && (
                    <Box mb={2} mx={2}>
                        <FormControl size="small" margin="normal">
                            <InputLabel>Filter</InputLabel>
                            <Select
                                label="Filter"
                                value={filter}
                                onChange={onChangeFilter}
                            >
                                {filterOptions.map(filterOption => (
                                    <MenuItem
                                        key={filterOption.value}
                                        value={filterOption.value}
                                    >
                                        {filterOption.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                <TableContainer>
                    <Table>
                        <colgroup>
                            <Box width="30%">
                                <col span={2} />
                            </Box>
                            <Box width="40%">
                                <col span={1} />
                            </Box>
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name, ID</TableCell>
                                <TableCell>Adresse</TableCell>
                                <TableCell>Kontakt zuordnen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers.length > 0 ? (
                                usersOfCurrentPage.map(user => (
                                    <AboptimalUserRow
                                        key={user.id}
                                        aboptimalUser={user}
                                        responseHandler={onSuccessHandler}
                                    />
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        Keine Aboptimal-Benutzer gefunden.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        labelRowsPerPage="Einträge pro Seite:"
                        rowsPerPageOptions={[10, 15, 25, 100]}
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    {aboptimalUsers === null && <LinearProgress />}
                </TableContainer>
            </Paper>
        </>
    );
    return paper;
};

export default AboptimalUsers;
