import React, { ReactElement, useContext } from 'react';
import useDialog from '../../hooks/useDialog';
import Ajax from '../../helpers/Ajax';
import { File } from '../../mappers/fileMapper';
import {
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@mui/material';
import { Delete, Edit, GetApp } from '@mui/icons-material';
import CrmDialog from '../helpers/CrmDialog';
import FileFormDialog from './FileFormDialog';
import DeleteAction from '../helpers/actions/DeleteAction';
import { useSnackbar } from 'notistack';
import { FileEntity } from '../../helpers/entities';
import { AuthContext } from '../../stores/AuthStore';

interface Props {
    file: File;
    reloadHandler: { (): void };
}

const FileView = ({ file, reloadHandler }: Props): ReactElement => {
    const { openDialog, closeDialog, dialogOpen } = useDialog();
    const { enqueueSnackbar } = useSnackbar();
    const { userCan } = useContext<AuthContext>(AuthContext);

    const onClickDownload = async () => {
        if (!file.uri) {
            return;
        }

        await Ajax.file(file.uri);
    };

    const onDeleteFile = async () => {
        enqueueSnackbar(`${file.label} gelöscht`, {
            variant: 'success',
        });
        reloadHandler();
    };

    const fileResponseHandler = () => {
        closeDialog();
        reloadHandler();
    };

    return (
        <>
            <ListItem disableGutters>
                <ListItemText
                    primary={file.label}
                    secondary={file.originalFileName}
                />
                <ListItemSecondaryAction>
                    <>
                        {file.uri && (
                            <IconButton edge="start" onClick={onClickDownload}>
                                <GetApp />
                            </IconButton>
                        )}
                        <IconButton edge="end" onClick={openDialog}>
                            <Edit />
                        </IconButton>
                        {userCan('delete') && (
                            <DeleteAction
                                label="Datei löschen"
                                action={FileEntity.apiBaseUrl + '/' + file.id}
                                onDeleteHandler={onDeleteFile}
                                icon={Delete}
                            >
                                <Typography gutterBottom>
                                    Sind Sie sicher, dass Sie die Datei{' '}
                                    <strong>{file.label}</strong> unwiderruflich
                                    löschen wollen?
                                </Typography>
                            </DeleteAction>
                        )}
                    </>
                </ListItemSecondaryAction>
            </ListItem>
            <CrmDialog
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
                maxWidth="sm"
                confirmClose
            >
                <FileFormDialog
                    action={`files/${file.id}`}
                    closeDialog={closeDialog}
                    responseHandler={fileResponseHandler}
                    file={file}
                />
            </CrmDialog>
        </>
    );
};

export default FileView;
