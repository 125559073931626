import React, { AnchorHTMLAttributes, ReactElement } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { Box, Link, SvgIcon } from '@mui/material';

interface Props {
    label: string;
    href?: string;
    title?: string;
    icon: SvgIconComponent;
    linkProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
}

const Url = ({
    label,
    href,
    title,
    icon,
    linkProps = {},
}: Props): ReactElement => (
    <span>
        <Box component="span" mr={1} color="text.secondary">
            <SvgIcon
                component={icon}
                fontSize="inherit"
                style={{ verticalAlign: 'middle' }}
            />
        </Box>
        {href ? (
            <Link href={href} title={title} {...linkProps} color="primary">
                {label}
            </Link>
        ) : (
            label
        )}
    </span>
);

export default Url;
