import React, { useContext } from 'react';
import { AuthContext } from '../../stores/AuthStore';
import FormBuilder from '../helpers/forms/FormBuilder';
import { TextField } from 'formik-mui';
import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import { Field } from 'formik';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const LoginForm = () => {
    const { login } = useContext<AuthContext>(AuthContext);

    const responseHandler = (response: any) => {
        login(response.token);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Grid
                container
                direction="column"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <Paper>
                    <Box m={4}>
                        <Grid container justifyContent="center">
                            <Avatar>
                                <LockOutlinedIcon />
                            </Avatar>
                        </Grid>
                        <Box mt={1} mb={2}>
                            <Typography
                                align="center"
                                component="h1"
                                variant="h1"
                            >
                                Anmeldung
                            </Typography>
                        </Box>
                        <FormBuilder
                            action="login"
                            initialValues={{ email: '', password: '' }}
                            responseHandler={responseHandler}
                        >
                            <Field
                                component={TextField}
                                name="email"
                                label="E-Mail"
                                data-at="email"
                                required
                                autoFocus
                            />
                            <Field
                                component={TextField}
                                name="password"
                                label="Passwort"
                                type="password"
                                data-at="password"
                                required
                            />

                            <Box mt={2}>
                                <Button
                                    id="login_button"
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    Anmelden
                                </Button>
                            </Box>
                        </FormBuilder>
                        <Box mt={2}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                align="center"
                            >
                                © Kanzlei Voigt Rechtsanwalts GmbH{' '}
                                {new Date().getFullYear()}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Container>
    );
};

export default LoginForm;
