import React, { ReactElement } from 'react';
import { IconButton, Typography } from '@mui/material';
import { Close, Edit, Save } from '@mui/icons-material';
import { TextField } from 'formik-mui';
import { Field } from 'formik';
import { User } from '../../../mappers/userMapper';
import useEditable from '../../../hooks/useEditable';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { UserEntity } from '../../../helpers/entities';
import CrmCard from '../../helpers/CrmCard';
import CrmLabel from '../../helpers/CrmLabel';

interface Props {
    user: User;
    reloadHandler(): void;
}

const ContactDetails = ({ user, reloadHandler }: Props): ReactElement => {
    const { isEdited, editOpen, editClose } = useEditable();

    const editResponseHandler = () => {
        editClose();
        reloadHandler();
    };

    if (isEdited) {
        return (
            <FormBuilder
                action={UserEntity.apiBaseUrl + '/' + user.id}
                initialValues={{
                    email: user.email,
                }}
                responseHandler={editResponseHandler}
                errorMessage="Fehler beim Speichern der Kontaktdaten!"
            >
                <CrmCard
                    title="Kontaktdaten"
                    titleAction={
                        <>
                            <IconButton size="small" type="submit">
                                <Save />
                            </IconButton>
                            <IconButton size="small" onClick={editClose}>
                                <Close />
                            </IconButton>
                        </>
                    }
                >
                    <Field
                        component={TextField}
                        size="small"
                        name="email"
                        label="E-Mail"
                        type="email"
                        autoFocus
                    />
                </CrmCard>
            </FormBuilder>
        );
    }

    return (
        <CrmCard
            title="Kontaktdaten"
            titleAction={
                <IconButton size="small" onClick={editOpen}>
                    <Edit />
                </IconButton>
            }
        >
            <CrmLabel>E-Mail</CrmLabel>
            <Typography variant="body2">{user.email}</Typography>
        </CrmCard>
    );
};

export default ContactDetails;
