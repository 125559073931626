import React, { ReactElement } from 'react';
import FormattingBase from './FormattingBase';
import { Mail } from '@mui/icons-material';

interface Props {
    email: string;
}

const Email = ({ email }: Props): ReactElement => (
    <FormattingBase
        label={email}
        href={`mailto:${email}`}
        title={`E-Mail schreiben an ${email}`}
        icon={Mail}
    />
);

export default Email;
