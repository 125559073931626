import React, { FC, useContext } from 'react';
import { Contact } from '../../../mappers/contactMapper';
import FormBuilder from '../../helpers/forms/FormBuilder';
import CrmToolbar from '../../helpers/CrmToolbar';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { Grid, IconButton, Typography } from '@mui/material';
import { Delete, Edit, Save } from '@mui/icons-material';
import useEditable from '../../../hooks/useEditable';
import { ContactEntity, GroupEntity } from '../../../helpers/entities';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';
import { AuthContext } from '../../../stores/AuthStore';
import DeleteAction from '../../helpers/actions/DeleteAction';
import { useSnackbar } from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import EditCloseAction from '../../helpers/actions/EditCloseAction';
import ContactingForbiddenAction from '../../helpers/actions/ContactingForbiddenAction';

interface Props {
    contact: Contact;
    reloadContact(): void;
}

const Name: FC<Props> = ({ contact, reloadContact }) => {
    const { isEdited, editOpen, editClose } = useEditable();
    const { userCan } = useContext<AuthContext>(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const navigate: NavigateFunction = useNavigate();

    const editResponseHandler: () => void = () => {
        editClose();
        reloadContact();
    };

    const onDeleteContact: () => void = () => {
        enqueueSnackbar(`${contact.name} gelöscht`, {
            variant: 'success',
        });
        navigate('/', { replace: true });
    };

    if (!contact) {
        return null;
    }

    if (isEdited) {
        return (
            <FormBuilder
                action={ContactEntity.apiBaseUrl + '/' + contact.id}
                initialValues={{
                    name: contact.name,
                    group_id: contact.group?.group || '',
                    contacting_forbidden: contact.contacting_forbidden ?? false,
                }}
                responseHandler={editResponseHandler}
                errorMessage="Fehler beim Speichern des Namens!"
            >
                <CrmToolbar
                    title={
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    size="small"
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    required
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <EntityAutocomplete
                                    entity={GroupEntity}
                                    size="small"
                                    name="group_id"
                                    minInputLength={0}
                                    selectedElement={contact.group}
                                    preloadAllEntries
                                />
                            </Grid>
                        </Grid>
                    }
                    disableTypography
                    buttons={
                        <>
                            <IconButton type="submit">
                                <Save />
                            </IconButton>
                            <EditCloseAction
                                size="medium"
                                editClose={editClose}
                            />
                        </>
                    }
                />
            </FormBuilder>
        );
    }

    const singleAssignedContactPersons: ContactPerson[] =
        contact.contact_persons?.filter(
            (person: ContactPerson) => person.contacts?.length === 1
        ) ?? [];

    return (
        <CrmToolbar
            title={contact.name}
            subtitle={contact.group?.group}
            subtitleLink={
                contact.group ? `/contacts?groups=${contact.group.id}` : null
            }
            buttons={
                <>
                    {userCan('delete') && (
                        <DeleteAction
                            label="Kontakt löschen"
                            action={ContactEntity.apiBaseUrl + '/' + contact.id}
                            onDeleteHandler={onDeleteContact}
                            icon={Delete}
                        >
                            <>
                                <Typography gutterBottom>
                                    Sind Sie sicher, dass Sie den Kontakt{' '}
                                    <strong>{contact.name}</strong>{' '}
                                    unwiderruflich löschen wollen?
                                </Typography>
                                <Typography>
                                    Davon betroffen sind auch:
                                    <ul>
                                        <li>Alle Berichte</li>
                                        <li>Alle Verträge</li>
                                        <li>Alle Dateien</li>
                                    </ul>
                                </Typography>
                                <Typography>
                                    Außerdem werden alle Ansprechpartner
                                    gelöscht, die nur diesem Kontakt zugewiesen
                                    sind:
                                    {singleAssignedContactPersons.length ===
                                    1 ? (
                                        <ul>
                                            {singleAssignedContactPersons.map(
                                                (person: ContactPerson) => (
                                                    <li key={person.id}>
                                                        {person.name}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    ) : (
                                        <i>Keine Ansprechpartner betroffen</i>
                                    )}
                                </Typography>
                            </>
                        </DeleteAction>
                    )}

                    <ContactingForbiddenAction
                        action={ContactEntity.apiBaseUrl + '/' + contact.id}
                        isContactingForbidden={!contact.contacting_forbidden}
                        onUpdateHandler={reloadContact}
                    />
                    <IconButton edge="end" onClick={editOpen}>
                        <Edit />
                    </IconButton>
                </>
            }
        />
    );
};

export default Name;
