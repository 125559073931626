import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import CrmToolbar from './helpers/CrmToolbar';
import CrmCard from './helpers/CrmCard';
import dayjs from 'dayjs';
import EntityReports from './modules/Statistics/EntityReports';
import EntityAutocomplete from './helpers/forms/EntityAutocomplete';
import { UserEntity } from '../helpers/entities';
import { AutocompleteOption } from '../crm';
import { AuthContext } from '../stores/AuthStore';
import UserOfficesDoughnut from './modules/Statistics/UserOfficesDoughnut';
import Ajax from '../helpers/Ajax';
import logError from '../errors/logError';
import { useSnackbar } from 'notistack';
import { User } from '../mappers/userMapper';
import DailyRecords from './modules/Statistics/DailyRecords';
import AlertBox from './helpers/AlertBox';

const Statistics: FC = () => {
    const inputDateFormat: string = 'YYYY-MM-DD';
    const { enqueueSnackbar } = useSnackbar();
    const { userCan } = useContext<AuthContext>(AuthContext);
    const [selectableUsers, setSelectableUsers] = useState<User[]>([]);

    const [inputStartDate, setInputStartDate] = useState<string>(
        dayjs().subtract(1, 'month').startOf('month').format(inputDateFormat)
    );
    const [inputEndDate, setInputEndDate] = useState<string>(
        dayjs().format(inputDateFormat)
    );

    const [selectedUser, setSelectedUser] = useState<AutocompleteOption | null>(
        null
    );
    const { user } = useContext<AuthContext>(AuthContext);

    const onUserChange: (user: AutocompleteOption | null) => void = user => {
        setSelectedUser(user);
    };

    const onUserSelectChange: (event: SelectChangeEvent) => void = event => {
        const val: string = event.target.value as string;

        setSelectedUser({
            label: selectableUsers.find(user => user.id === val)?.name || '',
            value: val,
        });
    };

    const onStartDateChange: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => void = event => {
        setInputStartDate(event.target.value);
    };
    const onEndDateChange: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => void = event => {
        setInputEndDate(event.target.value);
    };

    useEffect(() => {
        void fetchSelectableUsers();
    }, []);

    const fetchSelectableUsers: () => Promise<void> = async () => {
        if (!userCan('office_management')) {
            if (!user) {
                return;
            }
            setSelectedUser({ label: user.name, value: user.id });
        }

        try {
            const relatedUsers: any = await Ajax.get('me/related-users');

            setSelectableUsers(UserEntity.arrayMapper(relatedUsers));
        } catch (e) {
            enqueueSnackbar(`Fehler beim Laden der Mitarbeiter!`, {
                variant: 'error',
            });
            logError(e);
        }
    };

    const setDateThisMonth: () => void = () => {
        setInputStartDate(dayjs().startOf('month').format(inputDateFormat));
        setInputEndDate(dayjs().format(inputDateFormat));
    };

    const setDateLastMonth: () => void = () => {
        setInputStartDate(
            dayjs()
                .subtract(1, 'month')
                .startOf('month')
                .format(inputDateFormat)
        );
        setInputEndDate(
            dayjs().subtract(1, 'month').endOf('month').format(inputDateFormat)
        );
    };

    const setDateThisYear: () => void = () => {
        setInputStartDate(dayjs().startOf('year').format(inputDateFormat));
        setInputEndDate(dayjs().format(inputDateFormat));
    };

    const setDateLastYear: () => void = () => {
        setInputStartDate(
            dayjs().subtract(1, 'year').startOf('year').format(inputDateFormat)
        );
        setInputEndDate(
            dayjs().subtract(1, 'year').endOf('year').format(inputDateFormat)
        );
    };

    const startDate: Date | null = useMemo(
        () => (inputStartDate ? dayjs(inputStartDate).toDate() : null),
        [inputStartDate]
    );

    const endDate: Date | null = useMemo(
        () => (inputEndDate ? dayjs(inputEndDate).toDate() : null),
        [inputEndDate]
    );

    return (
        <>
            <Paper>
                <CrmToolbar title="Statistiken" />
            </Paper>
            <Box my={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CrmCard title="Zeitraum">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ButtonGroup size="small">
                                        <Button onClick={setDateThisMonth}>
                                            aktueller Monat
                                        </Button>
                                        <Button onClick={setDateLastMonth}>
                                            letzter Monat
                                        </Button>
                                        <Button onClick={setDateThisYear}>
                                            aktuelles Jahr
                                        </Button>
                                        <Button onClick={setDateLastYear}>
                                            letztes Jahr
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        type="date"
                                        label="Von"
                                        value={inputStartDate}
                                        onChange={onStartDateChange}
                                        slotProps={{
                                            inputLabel: { shrink: true },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        type="date"
                                        label="Bis"
                                        value={inputEndDate}
                                        onChange={onEndDateChange}
                                        slotProps={{
                                            inputLabel: { shrink: true },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CrmCard>
                    </Grid>
                    {startDate && endDate && startDate < endDate ? (
                        <>
                            <Grid item xs={12} md={6}>
                                <EntityReports
                                    apiUrl="statistics/user_reports"
                                    title="Termine pro Vertriebsmitarbeiter"
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <EntityReports
                                    apiUrl="statistics/office_reports"
                                    title="Termine pro Niederlassung"
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DailyRecords
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {userCan('office_management') && (
                                    <CrmCard title="Mitarbeiter">
                                        {user?.isAdmin ? (
                                            <EntityAutocomplete
                                                entity={UserEntity}
                                                name="user"
                                                preloadAllEntries
                                                withoutFormik
                                                minInputLength={0}
                                                onChangeHandler={onUserChange}
                                            />
                                        ) : (
                                            <FormControl>
                                                <InputLabel id="select-label">
                                                    {UserEntity.namePlural}
                                                </InputLabel>
                                                <Select
                                                    onChange={
                                                        onUserSelectChange
                                                    }
                                                    labelId="select-label"
                                                    label={
                                                        UserEntity.namePlural
                                                    }
                                                    value={
                                                        selectedUser
                                                            ? selectedUser.value
                                                            : ''
                                                    }
                                                >
                                                    {[
                                                        <MenuItem
                                                            key="none"
                                                            value=""
                                                        >
                                                            -
                                                        </MenuItem>,
                                                        selectableUsers.map(
                                                            user => (
                                                                <MenuItem
                                                                    key={
                                                                        user.id
                                                                    }
                                                                    value={
                                                                        user.id
                                                                    }
                                                                >
                                                                    {user.name}
                                                                </MenuItem>
                                                            )
                                                        ),
                                                    ]}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </CrmCard>
                                )}
                            </Grid>
                            {selectedUser?.value && (
                                <>
                                    <Grid item xs={12} md={6}>
                                        <EntityReports
                                            apiUrl={
                                                'statistics/office_reports/user/' +
                                                selectedUser.value
                                            }
                                            title={
                                                'Termine pro Niederlassung (' +
                                                selectedUser.label +
                                                ')'
                                            }
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <UserOfficesDoughnut
                                            selectedUser={selectedUser}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    </Grid>
                                </>
                            )}
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <AlertBox>
                                Bitte wählen Sie gültige Start- und Enddaten.
                            </AlertBox>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
};

export default Statistics;
