import React, { ReactElement } from 'react';
import {
    CircularProgress,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useEntities from '../../../hooks/useEntities';
import { Contact } from '../../../mappers/contactMapper';
import { ContactEntity } from '../../../helpers/entities';
import { Add, AddAPhoto, List as ListIcon, Map } from '@mui/icons-material';
import CrmCard from '../../helpers/CrmCard';
import useDialog from '../../../hooks/useDialog';
import ContactAddDialog from '../ContactAddDialog';
import CrmDialog from '../../helpers/CrmDialog';

import BusinessCardScanDialog from '../../BusinessCardScanDialog';

const NewContacts = (): ReactElement => {
    const {
        dialogOpen: contactAddDialogOpen,
        closeDialog: closeContactAddDialog,
        openDialog: openContactAddDialog,
    } = useDialog();
    const {
        dialogOpen: businessCardScanDialogOpen,
        closeDialog: closeBusinessCardScanDialog,
        openDialog: openBusinessCardScanDialog,
    } = useDialog();
    const { entities: contacts } = useEntities<Contact>(ContactEntity, {
        limit: 3,
        orderBy: 'created_at',
        withRelations: ['address'],
    });

    return (
        <CrmCard
            title="Neue Kontakte"
            titleAction={
                <>
                    <IconButton
                        size="small"
                        onClick={openBusinessCardScanDialog}
                    >
                        <AddAPhoto />
                    </IconButton>
                    <IconButton size="small" onClick={openContactAddDialog}>
                        <Add />
                    </IconButton>
                    <IconButton
                        size="small"
                        component={RouterLink}
                        to="/contacts/"
                    >
                        <ListIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        component={RouterLink}
                        to="/contactsMap/"
                    >
                        <Map />
                    </IconButton>
                </>
            }
        >
            <List dense disablePadding>
                {contacts !== null ? (
                    contacts.map(contact => (
                        <ListItem disableGutters key={contact.id}>
                            <ListItemText
                                primary={
                                    <Link
                                        component={RouterLink}
                                        to={'/contact/' + contact.id}
                                    >
                                        {contact.name}
                                    </Link>
                                }
                                secondary={[
                                    contact.address?.address,
                                    contact.address?.postcode,
                                    contact.address?.city,
                                ]
                                    .filter(Boolean)
                                    .join(', ')}
                            />
                        </ListItem>
                    ))
                ) : (
                    <CircularProgress size={20} />
                )}
            </List>
            <CrmDialog
                dialogOpen={contactAddDialogOpen}
                closeDialog={closeContactAddDialog}
                confirmClose
            >
                <ContactAddDialog closeDialog={closeContactAddDialog} />
            </CrmDialog>
            <CrmDialog
                dialogOpen={businessCardScanDialogOpen}
                closeDialog={closeBusinessCardScanDialog}
                confirmClose
            >
                <BusinessCardScanDialog
                    closeDialog={closeBusinessCardScanDialog}
                />
            </CrmDialog>
        </CrmCard>
    );
};

export default NewContacts;
