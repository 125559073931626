import React, { FC } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid2,
    Typography,
} from '@mui/material';
import FormBuilder from '../helpers/forms/FormBuilder';
import { Field } from 'formik';

import { NavigateFunction, useNavigate } from 'react-router-dom';
import { OfficeEntity, RivalEntity } from '../../helpers/entities';
import useEntitiesAutocomplete from '../../hooks/useEntitiesAutocomplete';
import EditCloseAction from '../helpers/actions/EditCloseAction';
import { TextField } from 'formik-mui';

interface Props {
    closeDialog(): void;
}

const RivalAddDialog: FC<Props> = ({ closeDialog }) => {
    const navigate: NavigateFunction = useNavigate();
    const { getAutocompleteEntities: getOffices } =
        useEntitiesAutocomplete(OfficeEntity);

    const responseHandler: (response: any) => void = response =>
        response?.id && navigate('/rival/' + response.id);

    return (
        <FormBuilder
            action={RivalEntity.apiBaseUrl}
            initialValues={{
                name: '',
                address: '',
                postcode: '',
                city: '',
                phone: '',
                email: '',
                web: '',
            }}
            responseHandler={responseHandler}
            errorMessage="Fehler beim Speichern des Wettbewerbers!"
        >
            <DialogTitle>
                <Typography component="h2">Wettbewerber erstellen</Typography>
                <EditCloseAction editClose={closeDialog} edge="end" />
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="h3" gutterBottom>
                    Stammdaten
                </Typography>
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Field
                            component={TextField}
                            name="name"
                            label="Name"
                            required
                            autoFocus
                        />
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Field
                            component={TextField}
                            name="address"
                            label="Adresse"
                        />

                        <Grid2 container spacing={2}>
                            <Grid2 size={{ xs: 4 }}>
                                <Field
                                    component={TextField}
                                    name="postcode"
                                    label="PLZ"
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 8 }}>
                                <Field
                                    component={TextField}
                                    name="city"
                                    label="Ort"
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        <Divider />
                    </Grid2>
                    <Grid2 size={{ xs: 12 }}>
                        <Typography variant="h3">Kontaktdaten</Typography>
                        <Field
                            component={TextField}
                            name="phone"
                            label="Telefon"
                        />
                        <Field
                            component={TextField}
                            name="email"
                            label="Email"
                            type="email"
                        />
                        <Field
                            component={TextField}
                            name="web"
                            label="Webseite"
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary">
                    Wettbewerber erstellen
                </Button>
            </DialogActions>
        </FormBuilder>
    );
};

export default RivalAddDialog;
