import React, {
    ChangeEvent,
    FC,
    MouseEvent,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Backdrop,
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    TextField,
    Theme,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    Business,
    BusinessCenter,
    Close,
    Person,
    Search,
} from '@mui/icons-material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDebounce } from 'react-use';
import Ajax from '../../helpers/Ajax';
import logError from '../../errors/logError';
import { mapContacts } from '../../mappers/contactMapper';
import { mapContactPersons } from '../../mappers/contactPersonMapper';
import HttpAuthenticationError from '../../errors/HttpAuthenticationError';

interface SearchResult {
    type: 'contact' | 'contact_person' | 'rival';
    label: string;
    additionalInformation?: string;
    id: string;
}

const minSearchLength: number = 3;

const SearchBox: FC = () => {
    const theme: Theme = useTheme();
    const smallScreen: boolean = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate: NavigateFunction = useNavigate();
    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState<SearchResult[] | null>(null);
    const textInputElement: RefObject<HTMLInputElement | null> =
        useRef<HTMLInputElement>(null);

    useEffect(() => {
        setShowSearchResults(!!searchQuery);
    }, [searchQuery]);

    const hasSearchQueryRequiredLength: boolean =
        searchQuery.length >= minSearchLength;

    const fetchResults: () => Promise<void> = async () => {
        try {
            const contactResponse: Obj[] = await Ajax.get<Obj[]>(
                'contacts/all',
                {
                    search: searchQuery,
                    withRelations: ['address'],
                }
            );
            const contactPersonResponse: Obj[] = await Ajax.get<Obj[]>(
                'contact_persons/all',
                {
                    search: searchQuery,
                    withRelations: ['address'],
                }
            );
            const rivalResponse: Obj[] = await Ajax.get<Obj[]>('rivals', {
                search: searchQuery,
                withRelations: ['address'],
            });

            setResults([
                ...mapContactToSearchResult(contactResponse),
                ...mapContactPersonsToSearchResult(contactPersonResponse),
                ...mapRivalToSearchResult(rivalResponse),
            ]);
        } catch (error) {
            if (error instanceof HttpAuthenticationError) return;
            logError(error);
        }
    };

    useDebounce(
        async () => {
            if (!hasSearchQueryRequiredLength) {
                return;
            }
            await fetchResults();
        },
        300,
        [searchQuery]
    );

    const onClickResult: (event: MouseEvent<HTMLElement>) => void = event => {
        event.preventDefault();
        resetSearchResults();

        navigate(
            `/${event.currentTarget.dataset.type}/${event.currentTarget.dataset.id}`
        );
        return true;
    };

    const resetSearchResults: () => void = () => {
        setSearchQuery('');
        setResults([]);
        setShowSearchResults(false);
        setShowMobileSearch(false);
    };

    const onChangeSearchQuery: (
        event: ChangeEvent<HTMLInputElement>
    ) => void = event => {
        setResults(null);
        setSearchQuery(event.target.value);
    };

    const openMobileSearch: () => void = () => {
        setShowMobileSearch(true);
        textInputElement.current?.focus();
    };

    return (
        <div style={{ paddingLeft: 16 }}>
            <div
                style={
                    !smallScreen
                        ? {
                              display: 'none',
                              textAlign: 'right',
                          }
                        : {
                              textAlign: 'right',
                              display: 'block',
                          }
                }
            >
                <IconButton
                    onClick={openMobileSearch}
                    sx={{ color: 'primary.contrastText' }}
                >
                    <Search />
                </IconButton>
            </div>
            <Box
                sx={
                    !smallScreen
                        ? {
                              maxWidth: 500,
                              width: '100%',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              position: 'relative',
                              left: 0,
                              right: 0,
                              transition: theme.transitions.create('all'),
                              background: theme.palette.primary.main,
                          }
                        : {
                              width: '100%',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              left: 0,
                              right: 0,
                              transition: theme.transitions.create('all'),
                              background: theme.palette.primary.main,
                              zIndex: theme.zIndex.appBar + 2,
                              padding: 1,
                              position: 'fixed',
                              top: '-100%',
                              maxWidth: 'none',
                              '&.active': {
                                  top: 0,
                              },
                          }
                }
                className={showMobileSearch ? 'active' : ''}
            >
                <TextField
                    placeholder="Suche nach Kontakt, Ansprechpartner oder Wettbewerber..."
                    name="search"
                    type="text"
                    size="small"
                    margin="none"
                    value={searchQuery}
                    onChange={onChangeSearchQuery}
                    inputRef={textInputElement}
                    autoComplete="off"
                    slotProps={{
                        input: {
                            sx: {
                                background: theme.palette.background.paper,
                                zIndex: theme.zIndex.appBar + 2,
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search color="disabled" />
                                </InputAdornment>
                            ),
                            endAdornment: (showMobileSearch || searchQuery) && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={resetSearchResults}
                                        edge="end"
                                    >
                                        <Close />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    }}
                />
                {showSearchResults && (
                    <>
                        <Paper
                            sx={
                                !smallScreen
                                    ? {
                                          position: 'absolute',
                                          top: theme.spacing(8),
                                          left: 0,
                                          right: 0,
                                          zIndex: theme.zIndex.appBar + 2,
                                          maxHeight: '75vh',
                                          overflowX: 'scroll',
                                      }
                                    : {
                                          position: 'absolute',
                                          left: 0,
                                          right: 0,
                                          zIndex: theme.zIndex.appBar + 2,
                                          maxHeight: '75vh',
                                          overflowX: 'scroll',
                                          margin: theme.spacing(1),
                                          top: theme.spacing(7),
                                      }
                            }
                        >
                            <List dense sx={{ padding: 1 }}>
                                {results === null &&
                                    hasSearchQueryRequiredLength && (
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <CircularProgress
                                                        size={20}
                                                        color="secondary"
                                                    />
                                                }
                                            />
                                        </ListItem>
                                    )}
                                {!hasSearchQueryRequiredLength && (
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Mindestens{' '}
                                                    <strong>
                                                        {minSearchLength}
                                                    </strong>{' '}
                                                    Zeichen eingeben...
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                {results?.length === 0 && (
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <>
                                                    Kein Treffer für{' '}
                                                    <strong>
                                                        {searchQuery}
                                                    </strong>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                )}
                                {results?.map(result => (
                                    <ListItem
                                        component="button"
                                        key={result.id}
                                        onClick={onClickResult}
                                        data-id={result.id}
                                        data-type={result.type}
                                        sx={{
                                            cursor: 'pointer',
                                            marginBottom: 1,
                                        }}
                                    >
                                        <ListItemIcon>
                                            {result.type === 'contact' && (
                                                <Business />
                                            )}
                                            {result.type ===
                                                'contact_person' && <Person />}
                                            {result.type === 'rival' && (
                                                <BusinessCenter />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={result.label}
                                            secondary={
                                                result.additionalInformation
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                        <Backdrop
                            open
                            onClick={resetSearchResults}
                            sx={{
                                zIndex: theme.zIndex.appBar + 1,
                            }}
                        />
                    </>
                )}
            </Box>
        </div>
    );
};

export default SearchBox;

const mapContactToSearchResult: (
    response: Obj[]
) => SearchResult[] = response =>
    mapContacts(response).map(contact => ({
        type: 'contact',
        label: contact.name,
        id: contact.id,
        additionalInformation: contact.address?.city,
    }));

const mapContactPersonsToSearchResult: (
    response: Obj[]
) => SearchResult[] = response =>
    mapContactPersons(response).map(contactPerson => ({
        type: 'contact_person',
        label: contactPerson.nameSortable,
        id: contactPerson.id,
        additionalInformation: contactPerson.main_contact?.name,
    }));

const mapRivalToSearchResult: (response: Obj[]) => SearchResult[] = response =>
    mapContacts(response).map(rival => ({
        type: 'rival',
        label: rival.name,
        id: rival.id,
        additionalInformation: rival.address?.city,
    }));
