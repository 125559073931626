import React, { ReactElement } from 'react';
import { Grid, IconButton } from '@mui/material';
import EntityAutocompleteMultiple from '../../helpers/forms/EntityAutocompleteMultiple';
import { OfficeEntity } from '../../../helpers/entities';
import { Check } from '@mui/icons-material';

const DecliningCaseNumbersFilters = (): ReactElement => (
    <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={3}>
            <EntityAutocompleteMultiple
                entity={OfficeEntity}
                name="filter_offices"
                minInputLength={0}
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <IconButton type="submit" color="secondary">
                <Check />
            </IconButton>
        </Grid>
    </Grid>
);

export default DecliningCaseNumbersFilters;
