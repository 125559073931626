import React from 'react';
import {
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    Close,
    Edit,
    Link as LinkIcon,
    LinkOff,
    Star,
    StarBorder,
} from '@mui/icons-material';
import useEditable from '../../../hooks/useEditable';
import CrmCard from '../../helpers/CrmCard';
import useDialog from '../../../hooks/useDialog';
import CrmDialog from '../../helpers/CrmDialog';
import OfficeLinkDialog from './OfficeLinkDialog';
import { Contact } from '../../../mappers/contactMapper';
import Ajax from '../../../helpers/Ajax';
import { useSnackbar } from 'notistack';
import logError from '../../../errors/logError';

interface Props {
    contact: Contact;
    reloadHandler(): void;
}

const Offices = ({ contact, reloadHandler }: Props) => {
    const { isEdited, editOpen, editClose } = useEditable();
    const { enqueueSnackbar } = useSnackbar();
    const {
        openDialog: openOfficeLinkDialog,
        closeDialog: closeOfficeLinkDialog,
        dialogOpen: officeLinkDialogOpen,
    } = useDialog();

    const officeLinkResponseHandler = () => {
        closeOfficeLinkDialog();
        reloadHandler();
    };

    const handleOfficeUnlink = async (officeUnique: string) => {
        try {
            await Ajax.delete<any>(
                'contacts/' + contact.id + '/offices/' + officeUnique
            );

            reloadHandler();
        } catch (e) {
            enqueueSnackbar('Fehler beim Lösen der Niederlassung!', {
                variant: 'error',
            });
            logError(e);
        }
    };

    const handleMainOfficeChange = async (officeUnique: string) => {
        try {
            await Ajax.post<any>(
                'contacts/' + contact.id + '/main_office/' + officeUnique,
                {}
            );

            reloadHandler();
        } catch (e) {
            enqueueSnackbar('Fehler beim Ändern der Hauptniederlassung!', {
                variant: 'error',
            });
            logError(e);
        }
    };

    return (
        <CrmCard
            title="Niederlassungen"
            disablePaddingTop={!!contact.offices?.length}
            titleAction={
                isEdited ? (
                    <IconButton size="small" onClick={editClose}>
                        <Close />
                    </IconButton>
                ) : (
                    <>
                        <IconButton size="small" onClick={openOfficeLinkDialog}>
                            <LinkIcon />
                        </IconButton>
                        {contact.offices && contact.offices.length > 1 && (
                            <IconButton size="small" onClick={editOpen}>
                                <Edit />
                            </IconButton>
                        )}
                    </>
                )
            }
        >
            {contact.offices?.length ? (
                <List dense>
                    {contact.offices.map(office => (
                        <ListItem disableGutters key={office.unique}>
                            <ListItemText primary={office.name} />
                            <ListItemSecondaryAction>
                                {office.unique ===
                                contact.main_office?.unique ? (
                                    <Tooltip
                                        title="Hauptstandort"
                                        placement="top"
                                        arrow
                                    >
                                        <Star
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Tooltip>
                                ) : (
                                    isEdited && (
                                        <>
                                            <IconButton
                                                onClick={() =>
                                                    handleOfficeUnlink(
                                                        office.unique
                                                    )
                                                }
                                            >
                                                <LinkOff fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                onClick={() =>
                                                    handleMainOfficeChange(
                                                        office.unique
                                                    )
                                                }
                                            >
                                                <StarBorder fontSize="small" />
                                            </IconButton>
                                        </>
                                    )
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography variant="body2">
                    Bisher wurden keine Niederlassungen zugewiesen.
                </Typography>
            )}
            <CrmDialog
                dialogOpen={officeLinkDialogOpen}
                closeDialog={closeOfficeLinkDialog}
                maxWidth="sm"
            >
                <OfficeLinkDialog
                    closeDialog={closeOfficeLinkDialog}
                    contactId={contact.id}
                    responseHandler={officeLinkResponseHandler}
                />
            </CrmDialog>
        </CrmCard>
    );
};

export default Offices;
