import React, { ReactElement } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material';
import useDialog from './useDialog';
import CrmDialog from '../components/helpers/CrmDialog';
import { Close } from '@mui/icons-material';
import { CrmTheme } from '../theme';

interface Props {
    children?: string | ReactElement;
    confirmationButtonText?: string;
    confirmationButtonColor?: 'error' | 'default';
    onConfirm(): void;
}

const useConfirmationDialog: ({
    onConfirm,
    confirmationButtonText,
    confirmationButtonColor,
    children,
}: Props) => {
    confirmationDialog: React.ReactElement;
    openConfirmation: { (): void };
} = ({
    onConfirm,
    confirmationButtonText = 'Ok',
    confirmationButtonColor = 'default',
    children = 'Möchten Sie die Bearbeitung wirklich Abbrechen ohne zu Speichern?',
}: Props) => {
    const theme: CrmTheme = useTheme();
    const { closeDialog, dialogOpen, openDialog } = useDialog();

    const confirmationContinue: () => void = () => {
        closeDialog();
        onConfirm();
    };

    const confirmationDialog: ReactElement = (
        <CrmDialog
            maxWidth="xs"
            closeDialog={closeDialog}
            dialogOpen={dialogOpen}
        >
            <DialogTitle>
                <Typography variant="h2">Bestätigung erforderlich</Typography>
                <IconButton onClick={closeDialog} size="small" edge="end">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Button type="button" autoFocus onClick={closeDialog}>
                            Abbrechen
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="button"
                            color="secondary"
                            onClick={confirmationContinue}
                            sx={{
                                color:
                                    confirmationButtonColor !== 'default'
                                        ? theme.palette.error.main
                                        : undefined,
                            }}
                        >
                            {confirmationButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </CrmDialog>
    );
    return { confirmationDialog, openConfirmation: openDialog };
};

export default useConfirmationDialog;
