import React, { ChangeEvent, ReactElement, useState } from 'react';
import { useFormikContext } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { useDebounce } from 'react-use';
import { AutocompleteOption } from '../../../crm';
import logError from '../../../errors/logError';

interface Props {
    getOptions(
        searchString: string
    ): Promise<AutocompleteOption[]> | AutocompleteOption[];
    name: string;
    label: string;
    size?: 'medium' | 'small';
    autoFocus?: boolean;
    selectedOptions?: AutocompleteOption[] | null;
    onChangeHandler?(newValues: AutocompleteOption[] | null): void;
    inputProps?: Obj;
    minInputLength?: number;
}

const AutocompleteInputMultiple = ({
    getOptions,
    label,
    name,
    size,
    selectedOptions,
    onChangeHandler,
    autoFocus,
    inputProps = {},
    minInputLength = 3,
}: Props): ReactElement => {
    const [searchString, setSearchString] = useState('');
    const [options, setOptions] = useState<AutocompleteOption[]>([]);
    const { setFieldValue } = useFormikContext();

    const updateOptions = async () => {
        if (searchString.trim().length < minInputLength) {
            return setOptions([]);
        }

        try {
            const options = await getOptions(searchString);
            setOptions(options);
        } catch (e) {
            setOptions([]);
            logError(e);
        }
    };

    const [isReady] = useDebounce(updateOptions, 500, [searchString]);

    const onAutoCompleteChange = (
        event: any,
        newValues: AutocompleteOption[] | null
    ) => {
        setFieldValue(
            name,
            newValues?.map((value: AutocompleteOption) => value.value) ?? null
        );
        onChangeHandler && onChangeHandler(newValues);
    };

    const onInputChange = async (
        event: ChangeEvent<unknown>,
        value: string
    ) => {
        setSearchString(value);
    };

    return (
        <Autocomplete
            multiple
            ChipProps={{
                color: 'secondary',
            }}
            defaultValue={selectedOptions || []}
            autoHighlight
            groupBy={option => option.label.charAt(0)}
            options={options}
            noOptionsText={
                searchString.trim().length >= minInputLength
                    ? isReady()
                        ? 'Keine passenden Einträge gefunden...'
                        : 'Lädt...'
                    : `Mindestens ${minInputLength} Zeichen eingeben...`
            }
            isOptionEqualToValue={(option, value) =>
                option.value === value.value
            }
            onChange={onAutoCompleteChange}
            onInputChange={onInputChange}
            getOptionLabel={option => option.label}
            renderInput={params => (
                <TextField
                    {...params}
                    autoFocus={autoFocus}
                    label={label}
                    size={size || 'medium'}
                    {...inputProps}
                />
            )}
        />
    );
};

export default AutocompleteInputMultiple;
