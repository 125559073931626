import React, { FC, useContext, useRef } from 'react';
import {
    AppBar,
    Box,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import { AuthContext } from '../../stores/AuthStore';
import { ExitToApp, List } from '@mui/icons-material';
import SearchBox from './SearchBox';
import { baseUrl as baseDataUrl } from '../DataSetOverView';
import useMenu from '../../hooks/useMenu';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const CrmAppBar: FC = () => {
    const { logout, user } = useContext<AuthContext>(AuthContext);
    const theme: Theme = useTheme();
    const navigate: NavigateFunction = useNavigate();
    const smallScreen: boolean = useMediaQuery(theme.breakpoints.down('sm'));
    const settingsMenuRef = useRef(null);
    const listMenuRef = useRef(null);
    const {
        isMenuOpen: isSettingsMenuOpen,
        handleMenuOpen: handleSettingsMenuOpen,
        handleMenuClose: handleSettingsMenuClose,
    } = useMenu();
    const {
        isMenuOpen: isListMenuOpen,
        handleMenuOpen: handleListMenuOpen,
        handleMenuClose: handleListMenuClose,
    } = useMenu();

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
        const link = event.currentTarget.dataset.link;

        link && navigate(link);

        handleSettingsMenuClose();
        handleListMenuClose();
    };

    return (
        <AppBar position="static">
            <Container disableGutters>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        data-link="/"
                        onClick={handleMenuItemClick}
                    >
                        <HomeIcon />
                    </IconButton>
                    <Typography component="h2" data-at="crm-bar-title">
                        Voigt CRM
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <SearchBox />
                    </Box>
                    {!smallScreen && (
                        <Box component="span" px={2}>
                            {user?.name}
                        </Box>
                    )}
                    <IconButton
                        color="inherit"
                        ref={listMenuRef}
                        onClick={handleListMenuOpen}
                    >
                        <List />
                    </IconButton>
                    <Menu
                        anchorEl={listMenuRef.current}
                        open={isListMenuOpen}
                        onClose={handleListMenuClose}
                    >
                        {[
                            <MenuItem
                                key={1}
                                data-link="/contact_persons/"
                                onClick={handleMenuItemClick}
                            >
                                Ansprechpartner
                            </MenuItem>,
                            <MenuItem
                                key={2}
                                data-link="/reports/"
                                onClick={handleMenuItemClick}
                            >
                                Berichte
                            </MenuItem>,
                            <MenuItem
                                key={3}
                                data-link="/contacts/"
                                onClick={handleMenuItemClick}
                            >
                                Kontakte
                            </MenuItem>,
                            <MenuItem
                                key={4}
                                data-link="/rivals/"
                                onClick={handleMenuItemClick}
                            >
                                Wettbewerber
                            </MenuItem>,
                            <MenuItem
                                key={5}
                                data-link="/declining_cases/declining_case_numbers"
                                onClick={handleMenuItemClick}
                            >
                                Rückgängige Fallzahlen
                            </MenuItem>,
                            <MenuItem
                                key={6}
                                data-link="/rentalCarUsage/"
                                onClick={handleMenuItemClick}
                            >
                                Mietwagen-Tool-Nutzung
                            </MenuItem>,
                        ]}
                    </Menu>
                    <IconButton
                        color="inherit"
                        ref={settingsMenuRef}
                        onClick={handleSettingsMenuOpen}
                    >
                        <SettingsIcon />
                    </IconButton>
                    <Menu
                        anchorEl={settingsMenuRef.current}
                        open={isSettingsMenuOpen}
                        onClose={handleSettingsMenuClose}
                    >
                        {[
                            <MenuItem
                                key={1}
                                data-link="/me"
                                onClick={handleMenuItemClick}
                            >
                                Mein Passwort ändern
                            </MenuItem>,
                            user?.isAdmin && [
                                <MenuItem
                                    key={2}
                                    data-link="/users/"
                                    onClick={handleMenuItemClick}
                                >
                                    Benutzerverwaltung
                                </MenuItem>,
                            ],
                            user?.isAdmin && [
                                <MenuItem
                                    key={3}
                                    data-link={baseDataUrl}
                                    onClick={handleMenuItemClick}
                                >
                                    Stammdatenverwaltung
                                </MenuItem>,
                                <MenuItem
                                    key={4}
                                    data-link="/settings/aboptimalUsers/"
                                    onClick={handleMenuItemClick}
                                >
                                    Aboptimal-Verknüpfung
                                </MenuItem>,
                            ],
                            user?.isAdmin && [
                                <MenuItem
                                    key={4}
                                    data-link="/offices/"
                                    onClick={handleMenuItemClick}
                                >
                                    Standortverwaltung
                                </MenuItem>,
                            ],
                        ]}
                    </Menu>
                    <IconButton edge="end" color="inherit" onClick={logout}>
                        <ExitToApp />
                    </IconButton>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default CrmAppBar;
