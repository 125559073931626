import React, { ReactElement } from 'react';
import { Contact } from '../../mappers/contactMapper';
import { Rival } from '../../mappers/rivalMapper';
import useDialog from '../../hooks/useDialog';
import CrmCard from '../helpers/CrmCard';
import { IconButton, List, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import CrmDialog from '../helpers/CrmDialog';
import FileFormDialog from './FileFormDialog';
import FileView from './FileView';

interface Props {
    fileable: Contact | Rival;
    apiUrl: string;
    reloadHandler: { (): void };
}

const Files = ({ fileable, apiUrl, reloadHandler }: Props): ReactElement => {
    const { openDialog, closeDialog, dialogOpen } = useDialog();

    const fileResponseHandler = () => {
        closeDialog();
        reloadHandler();
    };

    return (
        <>
            <CrmCard
                title="Dateien"
                titleAction={
                    <IconButton size="small" onClick={openDialog}>
                        <Add />
                    </IconButton>
                }
            >
                {fileable.files?.length ? (
                    <List dense disablePadding>
                        {fileable.files.map(file => (
                            <FileView
                                file={file}
                                key={file.id}
                                reloadHandler={reloadHandler}
                            />
                        ))}
                    </List>
                ) : (
                    <Typography variant="body2">
                        Bisher wurden keine Dateien hochgeladen.
                    </Typography>
                )}
            </CrmCard>
            <CrmDialog
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
                maxWidth="sm"
                confirmClose
            >
                <FileFormDialog
                    action={apiUrl}
                    closeDialog={closeDialog}
                    responseHandler={fileResponseHandler}
                />
            </CrmDialog>
        </>
    );
};

export default Files;
