import React, { FC, ReactElement, useEffect, useState } from 'react';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { reportTypes } from '../../../mappers/reportMapper';
import AutoCompleteInputFree from '../../helpers/forms/AutoCompleteInputFree';
import { useToggle } from 'react-use';
import { Mail } from '@mui/icons-material';
import ReportAddDialogAutocompleteAjax from './ReportAddDialogAutocompleteAjax';
import AutocompleteInputMultiple from '../../helpers/forms/AutocompleteInputMultiple';
import { Contact } from '../../../mappers/contactMapper';
import { AutocompleteOption } from '../../../crm';
import DateField from '../DateField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReportAddDialogCompetitors from './ReportAddDialogCompetitors';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
    contact: Contact;
    newReport?: boolean;
    storeValues?: (values: any) => void;
}
const ReportDialogForm: FC<Props> = ({
    contact,
    newReport,
    storeValues,
}: Props): ReactElement => {
    const theme: Theme = useTheme();
    const smallScreen: boolean = useMediaQuery(theme.breakpoints.down('sm'));
    const [showConfirmationWindow, toggleConfirmationWindow] = useToggle(false);

    const { values, isSubmitting, setFieldValue } = useFormikContext<any>();

    const [reportLines, setReportLines] = useState(6);

    storeValues && useEffect(() => storeValues(values), [values]);

    const onClickSubmitButton: (event: any) => boolean | undefined = event => {
        if (!event.target.closest('form').reportValidity()) {
            return false;
        }

        if (!values.internal.length && !values.external.length) {
            return true;
        }

        toggleConfirmationWindow();
    };

    const onContinueEditing: (event: any) => boolean = event => {
        event.preventDefault();
        toggleConfirmationWindow();
        return false;
    };

    const changeDateValue: (date: Dayjs | null, fieldName: string) => void = (
        date,
        fieldName
    ) => {
        void setFieldValue(
            fieldName,
            date !== null ? date.format('DD.MM.YYYY') : null
        );

        if (date === null) {
            void setFieldValue('done', date);
        }
    };

    const handleIncreaseReportSize: () => void = () => {
        setReportLines(prevLines => prevLines + 6);
    };

    const handleDecreaseReportSize: () => void = () => {
        setReportLines(6);
    };

    return (
        <>
            {showConfirmationWindow ? (
                <>
                    <DialogContent>
                        <Box mb={2}>
                            <Typography>
                                Diese E-Mail-Adressen erhalten eine Kopie des
                                Berichts:
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="body2" color="textSecondary">
                                Intern
                            </Typography>
                            <List>
                                {values.internal.map((email: string) => (
                                    <ListItem key={email} disableGutters>
                                        <ListItemIcon>
                                            <Mail />
                                        </ListItemIcon>
                                        <ListItemText primary={email} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="body2" color="textSecondary">
                                Extern
                            </Typography>
                            <List>
                                {values.external.map((email: string) => (
                                    <ListItem key={email} disableGutters>
                                        <ListItemIcon>
                                            <Mail />
                                        </ListItemIcon>
                                        <ListItemText primary={email} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <Typography>
                            Sind Sie sicher, dass Sie Bericht speichern wollen?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="button"
                            color="primary"
                            onClick={onContinueEditing}
                        >
                            Weiter bearbeiten
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                        >
                            Bericht speichern
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent dividers>
                        <Field
                            component={TextField}
                            name="type"
                            label="Kontaktart"
                            required
                            autoFocus
                            select
                        >
                            {Object.entries(reportTypes).map(
                                ([name, label]) => (
                                    <MenuItem value={name} key={name}>
                                        {label}
                                    </MenuItem>
                                )
                            )}
                        </Field>
                        <Grid container spacing={2} style={{ paddingTop: 14 }}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={DateField}
                                    date={values.date}
                                    label="Datum"
                                    id="date"
                                    clearable
                                    maxDate={dayjs()}
                                    changeDateValue={changeDateValue}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={DateField}
                                    date={
                                        values.notification_date
                                            ? values.notification_date
                                            : null
                                    }
                                    label="Wiedervorlage am"
                                    id="notification_date"
                                    changeDateValue={changeDateValue}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={smallScreen ? 0 : 2}>
                            <Grid item xs={12} md={6}>
                                <AutocompleteInputMultiple
                                    name="contact_persons"
                                    label="Ansprechpartner"
                                    minInputLength={0}
                                    getOptions={() =>
                                        contact.contact_persons?.map(
                                            (
                                                contactPerson
                                            ): AutocompleteOption => ({
                                                value: contactPerson.id,
                                                label: contactPerson.nameSortable,
                                            })
                                        ) ?? []
                                    }
                                    selectedOptions={contact.contact_persons
                                        ?.filter(contactPerson =>
                                            values.contact_persons.includes(
                                                contactPerson.id
                                            )
                                        )
                                        .map(
                                            (
                                                contactPerson
                                            ): AutocompleteOption => ({
                                                value: contactPerson.id,
                                                label: contactPerson.nameSortable,
                                            })
                                        )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ReportAddDialogAutocompleteAjax
                                    name="internal_participants"
                                    label="Interne Teilnehmer"
                                    userKey="id"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={smallScreen ? 0 : 2}>
                            <Grid item xs={12} md={6}>
                                <Field
                                    component={TextField}
                                    name="subject"
                                    label="Thema / Inhalt"
                                    required
                                    autoFocus
                                    select
                                >
                                    {[
                                        'Bestandsbesuch',
                                        'Erstkontakt',
                                        'Reaktivierung',
                                        'Review Gespräch',
                                        'Schulung',
                                        'Sonstiges',
                                        'Telefonat',
                                        'Vermerk',
                                    ].map(value => (
                                        <MenuItem value={value} key={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ReportAddDialogCompetitors />
                            </Grid>
                        </Grid>
                        <Field
                            component={TextField}
                            name="report"
                            label="Bericht"
                            rows={reportLines}
                            multiline
                            required
                        />
                        <div>
                            {reportLines <= 18 ? (
                                <Button
                                    color="primary"
                                    startIcon={<KeyboardArrowDownIcon />}
                                    size="small"
                                    onClick={handleIncreaseReportSize}
                                >
                                    Berichtsfeld vergrößern
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    startIcon={<KeyboardArrowUpIcon />}
                                    size="small"
                                    onClick={handleDecreaseReportSize}
                                >
                                    Berichtsfeld verkleinern
                                </Button>
                            )}
                        </div>
                        {!newReport &&
                            (values.notification_date || values.done) && (
                                <DateField
                                    date={values.done ? values.done : null}
                                    label="Erledigt am"
                                    id="done"
                                    changeDateValue={changeDateValue}
                                />
                            )}
                        <ReportAddDialogAutocompleteAjax
                            name="internal"
                            label="Kopie (intern)"
                        />
                        <AutoCompleteInputFree
                            name="external"
                            label="Kopie (extern)"
                            type="email"
                            placeholder="name@example.com"
                            helperText="Umlaute in Domains werden in ACE Strings umgewandelt (bspw. beispiel@bär.de ➝ beispiel@xn--br-via.de)"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            onClick={onClickSubmitButton}
                            disabled={isSubmitting}
                        >
                            Bericht speichern
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
};

export default ReportDialogForm;
