import React, { ReactElement, useContext } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';

import { EntityContext } from '../../../helpers/entities';
import { Office } from '../../../../../../../../resources/assets/react/entities/Office';
import { CallMade } from '@mui/icons-material';
import OfficeCheckbox from '../Office/OfficeCheckbox';
import OfficeUserAssignment from '../Office/OfficeUserAssignment';

interface Props {
    openDialog(): void;
    setDialogOfficeUnique(officeUnique: string): void;
    setDialogOfficeName(officeName: string): void;
}

const OfficeRows = ({
    openDialog,
    setDialogOfficeUnique,
    setDialogOfficeName,
}: Props): ReactElement => {
    const { entities: offices } = useContext(EntityContext);

    const handleDialogOpen = (officeUnique: string, officeName: string) => {
        setDialogOfficeUnique(officeUnique);
        setDialogOfficeName(officeName);
        openDialog();
    };

    return (
        <>
            {offices &&
                offices.map((office: Office) => (
                    <TableRow key={office.id}>
                        <TableCell>{office.name}</TableCell>
                        <TableCell>
                            <OfficeCheckbox
                                officeUnique={office.unique}
                                restrictedStatus={office.crm_restricted}
                            />
                        </TableCell>
                        <TableCell>{office.crm_members?.length}</TableCell>
                        <TableCell>
                            <OfficeUserAssignment
                                officeUnique={office.unique}
                                assignedCrmUser={
                                    office.assigned_crm_user ?? null
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <IconButton
                                edge="end"
                                color="secondary"
                                onClick={() =>
                                    handleDialogOpen(office.unique, office.name)
                                }
                            >
                                <CallMade />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
        </>
    );
};

export default OfficeRows;
