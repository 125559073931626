import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { User } from '../../../mappers/userMapper';
import CrmCard from '../../helpers/CrmCard';
import logError from '../../../errors/logError';
import Ajax from '../../../helpers/Ajax';
import { Block, LockOpen } from '@mui/icons-material';

interface Props {
    user: User;
    reloadHandler: { (): void };
}

const Disable = ({ user, reloadHandler }: Props) => {
    const label = user.isDisabled ? 'Aktivieren' : 'Deaktivieren';

    const onClick = async () => {
        try {
            await Ajax.post(
                `users/${user.id}/${user.isDisabled ? 'enable' : 'disable'}`,
                {}
            );
            reloadHandler();
        } catch (e) {
            logError(e);
        }
    };

    return (
        <CrmCard title={label}>
            <Typography>
                {user.isDisabled
                    ? 'Dieser Nutzer ist deaktiviert und kann sich nicht mehr anmelden.'
                    : 'Deaktivierte Nutzer können sich nicht mehr anmelden.'}
            </Typography>
            <Box mt={1}>
                <Button
                    color={user.isDisabled ? 'secondary' : 'primary'}
                    onClick={onClick}
                    startIcon={user.isDisabled ? <LockOpen /> : <Block />}
                >
                    {label}
                </Button>
            </Box>
        </CrmCard>
    );
};

export default Disable;
