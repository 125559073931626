import React, { ReactElement } from 'react';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import CrmDialog from './helpers/CrmDialog';
import useDialog from '../hooks/useDialog';
import PaginationPage from './helpers/PaginationPage';
import { RivalEntity } from '../helpers/entities';
import RivalRows from './modules/Rivals/RivalRows';
import RivalAddDialog from './modules/RivalAddDialog';

const Rivals = (): ReactElement => {
    const { dialogOpen, closeDialog, openDialog } = useDialog();

    return (
        <PaginationPage
            entity={RivalEntity}
            buttons={
                <IconButton onClick={openDialog}>
                    <Add />
                </IconButton>
            }
            withRelations={['address']}
        >
            <RivalRows />
            <CrmDialog dialogOpen={dialogOpen} closeDialog={closeDialog}>
                <RivalAddDialog closeDialog={closeDialog} />
            </CrmDialog>
        </PaginationPage>
    );
};

export default Rivals;
