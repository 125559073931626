import React, { FC, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { TextField } from 'formik-mui';
import { Field } from 'formik';
import { Address } from '../../mappers/addressMapper';
import CrmLabel from '../helpers/CrmLabel';
import FormBuilderCard from '../helpers/forms/FormBuilderCard';
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { icon } from 'leaflet';
import { fetchCoordinates } from '../../helpers/fetchCoordinates';
import { Directions, Map as MapIcon } from '@mui/icons-material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Ajax from '../../helpers/Ajax';
import { Contact } from '../../mappers/contactMapper';
import logError from '../../errors/logError';
import { useAsync } from 'react-use';
import ContactsMapMarker from './ContactsMapMarker';
import MarkerClusterGroup from 'react-leaflet-cluster';
import HttpValidationError from '../../errors/HttpValidationError';

interface Props {
    address?: Address;
    apiUrl: string;
    contactId?: string;

    reloadHandler(): void;
}

const AddressView: FC<Props> = ({
    address,
    apiUrl,
    reloadHandler,
    contactId,
}) => {
    const navigate: NavigateFunction = useNavigate();
    const [nearContacts, setNearContacts] = useState<Contact[]>([]);

    const SetMapViewOnChange = ({
        coords,
    }: {
        coords: [number, number];
    }): null => {
        const map = useMap();
        map.setView(coords, map.getZoom());

        return null;
    };

    const handleOnClick: () => void = () => {
        if (address)
            navigate('/contactsMap/' + contactId, {
                state: {
                    lat: address.latitude,
                    lng: address.longitude,
                },
            });
    };

    const fetchContactsWithinRadius: () => Promise<void> = async () => {
        if (
            !address ||
            address.latitude === null ||
            address.longitude === null
        ) {
            return;
        }
        try {
            const contactsWithinRadius: Contact[] = await Ajax.get<Contact[]>(
                'contacts/withinRadius',
                {
                    latitude: address.latitude,
                    longitude: address.longitude,
                    radius: 25000,
                    withRelations: ['empfehler', 'address'],
                }
            );
            setNearContacts(contactsWithinRadius);
        } catch (error) {
            if (error instanceof HttpValidationError) return;

            setNearContacts([]);
            logError(error);
        }
    };

    useAsync(fetchContactsWithinRadius, [address]);

    return (
        <FormBuilderCard
            title="Anschrift"
            apiUrl={apiUrl}
            form={
                <>
                    <Field
                        component={TextField}
                        size="small"
                        name="address"
                        label="Adresse"
                        fullWidth
                        autoFocus
                    />
                    <Field
                        component={TextField}
                        size="small"
                        name="postcode"
                        label="PLZ"
                        fullWidth
                    />
                    <Field
                        component={TextField}
                        size="small"
                        name="city"
                        label="Ort"
                        fullWidth
                    />
                </>
            }
            view={
                <>
                    {address?.address || address?.postcode || address?.city ? (
                        <>
                            <CrmLabel>Adresse</CrmLabel>
                            <Typography variant="body2">
                                {address.address}
                                <br />
                                {address.postcode} {address.city}
                            </Typography>
                            {address.latitude !== null &&
                                address.longitude !== null && (
                                    <Box height={{ xs: 200, md: 130 }} mt={2}>
                                        <MapContainer
                                            center={[
                                                address.latitude,
                                                address.longitude,
                                            ]}
                                            zoom={15}
                                            scrollWheelZoom
                                            style={{ height: '100%' }}
                                            attributionControl={false}
                                        >
                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                            <Marker
                                                position={[
                                                    address.latitude,
                                                    address.longitude,
                                                ]}
                                                icon={icon({
                                                    iconUrl:
                                                        '/images/crm/marker-icon-green.svg',
                                                    shadowUrl:
                                                        '/images/crm/marker-shadow.svg',
                                                    iconSize: [25, 41],
                                                    iconAnchor: [12, 41],
                                                    popupAnchor: [1, -34],
                                                    tooltipAnchor: [16, -28],
                                                    shadowSize: [41, 41],
                                                })}
                                            />
                                            <MarkerClusterGroup
                                                showCoverageOnHover={false}
                                            >
                                                {nearContacts.map(contact => (
                                                    <ContactsMapMarker
                                                        key={contact.id}
                                                        contact={contact}
                                                        selectedId={contactId}
                                                    />
                                                ))}
                                            </MarkerClusterGroup>
                                            <SetMapViewOnChange
                                                coords={[
                                                    address.latitude,
                                                    address.longitude,
                                                ]}
                                            />
                                        </MapContainer>
                                    </Box>
                                )}
                        </>
                    ) : (
                        'Bisher wurde keine Adresse angelegt.'
                    )}
                </>
            }
            additionalViewActions={
                address &&
                address?.latitude !== null &&
                address?.longitude !== null ? (
                    <>
                        <IconButton size="small" onClick={handleOnClick}>
                            <MapIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            href={`https://www.google.com/maps/dir/?api=1&destination=${address.latitude},${address.longitude}`}
                            target="_blank"
                        >
                            <Directions />
                        </IconButton>
                    </>
                ) : null
            }
            reloadHandler={reloadHandler}
            initialValues={{
                address: address?.address,
                postcode: address?.postcode,
                city: address?.city,
                longitude: address?.longitude,
                latitude: address?.latitude,
            }}
            preSubmitFunction={fetchCoordinates}
        />
    );
};

export default AddressView;
