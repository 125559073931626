import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { default as MuiTextField } from '@mui/material/TextField/TextField';
import MenuItem from '@mui/material/MenuItem';
import CrmLabel from '../CrmLabel';
import {
    dayOptions,
    monthOptions,
    yearOptions,
} from '../../../helpers/dateOptions';
import { Button, Grid } from '@mui/material';
import dayjs from 'dayjs';

interface Props {
    birthday: Date | null;
}

const BirthdayPickerField = ({ birthday }: Props): ReactElement => {
    const [day, setDay] = useState<number | null>(birthday?.getDate() ?? null);
    const [month, setMonth] = useState<number | null>(
        birthday?.getMonth() ?? null
    );
    const [year, setYear] = useState<number | null>(
        birthday?.getFullYear() ?? null
    );
    const { setFieldValue, submitForm } = useFormikContext();
    const handleChangeDay = (event: ChangeEvent<HTMLInputElement>) => {
        setDay(parseInt(event.target.value));
    };
    const handleChangeMonth = (event: ChangeEvent<HTMLInputElement>) => {
        setMonth(parseInt(event.target.value));
    };
    const handleChangeYear = (event: ChangeEvent<HTMLInputElement>) => {
        setYear(parseInt(event.target.value));
    };
    const handleChangeReset = () => {
        setFieldValue('birthday', null);
        submitForm();
    };

    useEffect(() => {
        setFieldValue(
            'birthday',
            dayjs(
                (year ?? 1800) +
                    '-' +
                    (month !== null ? month + 1 : '') +
                    '-' +
                    day
            )
        );
    }, [day, month, year]);
    return (
        <>
            <CrmLabel>Geburtstag</CrmLabel>
            <Grid container spacing={2}>
                <Grid item xs={4} sm={3}>
                    <MuiTextField
                        label="Tag"
                        required
                        select
                        value={day}
                        onChange={handleChangeDay}
                    >
                        {dayOptions.map(dayOption => (
                            <MenuItem
                                key={dayOption.value}
                                value={dayOption.value}
                            >
                                {dayOption.label}
                            </MenuItem>
                        ))}
                    </MuiTextField>
                </Grid>
                <Grid item xs={8} sm={5}>
                    <MuiTextField
                        label="Monat"
                        required
                        select
                        value={month}
                        onChange={handleChangeMonth}
                    >
                        {monthOptions.map(monthOption => (
                            <MenuItem
                                key={monthOption.value}
                                value={monthOption.value}
                            >
                                {monthOption.label}
                            </MenuItem>
                        ))}
                    </MuiTextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <MuiTextField
                        label="Jahr"
                        select
                        value={year}
                        onChange={handleChangeYear}
                    >
                        <MenuItem value="1800">Unbekannt</MenuItem>
                        {yearOptions.map(yearOption => (
                            <MenuItem
                                key={yearOption.value}
                                value={yearOption.value}
                            >
                                {yearOption.label}
                            </MenuItem>
                        ))}
                    </MuiTextField>
                </Grid>
            </Grid>
            <Grid container direction="column" alignItems="flex-end">
                <Grid item>
                    <Button
                        disabled={!birthday}
                        onClick={handleChangeReset}
                        color="secondary"
                    >
                        Zurücksetzen
                    </Button>
                </Grid>
            </Grid>

            <Field type="hidden" name="birthday" />
            <ErrorMessage component="span" name="birthday" />
        </>
    );
};

export default BirthdayPickerField;
