import React, { ReactElement } from 'react';
import { Print } from '@mui/icons-material';
import FormattingBase from './FormattingBase';

interface Props {
    fax: string;
}

const Fax = ({ fax }: Props): ReactElement => (
    <FormattingBase label={fax} icon={Print} />
);

export default Fax;
