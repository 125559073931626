import React, { ChangeEvent, ReactElement, useContext, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
} from '@mui/material';
import { Add, Check, Map } from '@mui/icons-material';
import CrmDialog from './helpers/CrmDialog';
import useDialog from '../hooks/useDialog';
import ContactAddDialog from './modules/ContactAddDialog';
import PaginationPage from './helpers/PaginationPage';
import {
    AccidentConsultantEntity,
    BrandEntity,
    CampaignEntity,
    CompetitorEntity,
    ContactEntity,
    ContactTypeEntity,
    CooperationEntity,
    GroupEntity,
    OfficeEntity,
    WorkshopSystemEntity,
} from '../helpers/entities';
import ContactRows from './modules/Contacts/ContactRows';
import EntityAutocompleteMultiple from './helpers/forms/EntityAutocompleteMultiple';
import { Link as RouterLink } from 'react-router-dom';
import useQuery from '../hooks/useQuery';
import { ConfigContext } from '../stores/ConfigStore';
import { Field } from 'formik';
import { Select } from 'formik-mui';

const Contacts = (): ReactElement => {
    const { dialogOpen, closeDialog, openDialog } = useDialog();
    const [withContactingForbiddenChecked, setWithContactingForbiddenCheck] =
        useState<boolean>(false);
    const [downloadOptions, setDownloadOptions] = useState<Obj>({});
    const { segmentationOptions } = useContext<ConfigContext>(ConfigContext);

    const query = useQuery();
    const filterParams = {
        ...(query.has('groups') && {
            filter_groups: query.get('groups')?.split(','),
        }),
        ...(query.has('segmentations') && {
            filter_segmentations: query.get('segmentations')?.split(','),
        }),
        ...(query.has('brands') && {
            filter_brands: query.get('brands')?.split(','),
        }),
        ...(query.has('contactTypes') && {
            filter_contact_types: query.get('contactTypes')?.split(','),
        }),
        ...(query.has('accidentConsultants') && {
            filter_accident_consultants: query
                .get('accidentConsultants')
                ?.split(','),
        }),
        ...(query.has('workshopSystems') && {
            filter_workshop_systems: query.get('workshopSystems')?.split(','),
        }),
        ...(query.has('cooperations') && {
            filter_cooperations: query.get('cooperations')?.split(','),
        }),
        ...(query.has('competitors') && {
            filter_competitors: query.get('competitors')?.split(','),
        }),
        ...(query.has('campaigns') && {
            filter_campaigns: query.get('campaigns')?.split(','),
        }),
    };

    const withContactingForbiddenChange = (
        event: ChangeEvent<HTMLInputElement>,
        value: boolean
    ) => {
        setWithContactingForbiddenCheck(value);
        setDownloadOptions(prevState => {
            prevState.with_contacting_forbidden = value ? '1' : '0';
            return prevState;
        });
    };

    const filters = (
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid
                item
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
            >
                <Grid item xs={12} md>
                    <EntityAutocompleteMultiple
                        entity={OfficeEntity}
                        name="filter_offices"
                        minInputLength={0}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <EntityAutocompleteMultiple
                        entity={BrandEntity}
                        name="filter_brands"
                        minInputLength={0}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <EntityAutocompleteMultiple
                        entity={CooperationEntity}
                        name="filter_cooperations"
                        minInputLength={0}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <EntityAutocompleteMultiple
                        entity={GroupEntity}
                        name="filter_groups"
                        minInputLength={0}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <FormControl size="medium" margin="normal">
                        <InputLabel id="segmentations-label">
                            Prioritäten
                        </InputLabel>
                        <Field
                            component={Select}
                            id="filter_segmentations"
                            name="filter_segmentations"
                            label="Prioritäten"
                            defaultValue={[]}
                            disabled={false}
                            multiple
                        >
                            {segmentationOptions.map(value => (
                                <MenuItem value={value} key={value}>
                                    {value ?? '-'}
                                </MenuItem>
                            ))}
                        </Field>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid
                item
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
            >
                <Grid item xs={12} md>
                    <EntityAutocompleteMultiple
                        entity={ContactTypeEntity}
                        name="filter_contact_types"
                        minInputLength={0}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <EntityAutocompleteMultiple
                        entity={AccidentConsultantEntity}
                        name="filter_accident_consultants"
                        minInputLength={0}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <EntityAutocompleteMultiple
                        entity={WorkshopSystemEntity}
                        name="filter_workshop_systems"
                        minInputLength={0}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <EntityAutocompleteMultiple
                        entity={CompetitorEntity}
                        name="filter_competitors"
                        minInputLength={0}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <EntityAutocompleteMultiple
                        entity={CampaignEntity}
                        name="filter_campaigns"
                        minInputLength={0}
                    />
                </Grid>
                <Grid item md={1}>
                    <IconButton type="submit" color="secondary">
                        <Check />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <PaginationPage
            entity={ContactEntity}
            withRelations={['group', 'address']}
            buttons={
                <>
                    <IconButton onClick={openDialog}>
                        <Add />
                    </IconButton>
                    <IconButton component={RouterLink} to="/contactsMap/">
                        <Map />
                    </IconButton>
                </>
            }
            filters={filters}
            preselectedFilters={filterParams}
            downloadable
            downloadOptions={downloadOptions}
            downloadDialogContent={
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={withContactingForbiddenChecked}
                                    onChange={withContactingForbiddenChange}
                                    name="with_contacting_forbidden"
                                />
                            }
                            label="Auch Kontakte exportieren, die keinen Kontakt wünschen"
                        />
                    </FormGroup>
                </FormControl>
            }
        >
            <ContactRows />
            <CrmDialog dialogOpen={dialogOpen} closeDialog={closeDialog}>
                <ContactAddDialog closeDialog={closeDialog} />
            </CrmDialog>
        </PaginationPage>
    );
};

export default Contacts;
