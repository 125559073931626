import React, { ReactElement } from 'react';
import {
    ContactDetailsHierarchical,
    ContactDetailType,
} from '../../../mappers/contactDetailMapper';
import { Grid, IconButton } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
import CrmCard from '../../helpers/CrmCard';
import useEditable from '../../../hooks/useEditable';
import FormBuilder from '../../helpers/forms/FormBuilder';
import ContactDetailItem from './ContactDetailItem';
import EditCloseAction from '../../helpers/actions/EditCloseAction';

interface Props {
    contactDetailFields: ContactDetailType[];
    contactDetails?: ContactDetailsHierarchical | null;
    contactDetailUpdateUrl: string;
    reloadHandler(): void;
}

const ContactDetails = ({
    contactDetailFields,
    contactDetails,
    reloadHandler,
    contactDetailUpdateUrl,
}: Props): ReactElement => {
    const { isEdited, editOpen, editClose } = useEditable();

    const onSaveForm = (response: Obj) => {
        editClose();
        reloadHandler();
    };

    const initialValues = contactDetailFields.reduce(
        (initialValues: Obj, key) => {
            initialValues[key] = contactDetails?.[key as ContactDetailType]
                ?.length
                ? contactDetails?.[key as ContactDetailType][0].value
                : '';
            return initialValues;
        },
        {}
    );

    return (
        <FormBuilder
            action={contactDetailUpdateUrl}
            initialValues={initialValues}
            responseHandler={onSaveForm}
        >
            <CrmCard
                title="Kontaktdaten"
                titleAction={
                    isEdited ? (
                        <>
                            <IconButton
                                key="saveButton"
                                size="small"
                                type="submit"
                            >
                                <Save />
                            </IconButton>
                            <EditCloseAction editClose={editClose} />
                        </>
                    ) : (
                        <>
                            <IconButton
                                key="editButton"
                                size="small"
                                onClick={editOpen}
                            >
                                <Edit />
                            </IconButton>
                        </>
                    )
                }
            >
                <Grid container spacing={1}>
                    {contactDetailFields.map(key => (
                        <ContactDetailItem
                            key={key}
                            type={key as ContactDetailType}
                            isEdited={isEdited}
                            value={initialValues[key]}
                        />
                    ))}
                </Grid>
            </CrmCard>
        </FormBuilder>
    );
};

export default ContactDetails;
