import React, { FC, useContext, useState } from 'react';
import {
    Box,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    lighten,
    MenuItem,
    Select,
    SelectChangeEvent,
    useTheme,
} from '@mui/material';
import CrmCard from '../../helpers/CrmCard';
import { CrmTheme } from '../../../theme';
import { Line } from 'react-chartjs-2';
import Ajax from '../../../helpers/Ajax';
import dayjs from 'dayjs';
import { Link as RouterLink } from 'react-router-dom';
import { FilterList, List as ListIcon } from '@mui/icons-material';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';
import { OfficeEntity } from '../../../helpers/entities';
import { AutocompleteOption } from '../../../crm';
import logError from '../../../errors/logError';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../../../stores/AuthStore';
import { useAsync } from 'react-use';
import { MonthlyRecords } from '../../../helpers/statistics';
import {
    createMonthlyRecordsByYears,
    createMonthlyRentalCarReportsByYears,
    DateRecords,
    MonthlyRecordsByYears,
    MonthlyRentalCarReportsByYears,
} from '../../../helpers/dashboardStatistics';
import { ChartData, ChartDataset } from 'chart.js';

const Statistics: FC = () => {
    const title: string = 'Statistik';
    const theme: CrmTheme = useTheme();
    const { user } = useContext<AuthContext>(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const [monthlyRecords, setMonthlyRecords] =
        useState<MonthlyRecordsByYears | null>(null);
    const [rentalCarReports, setRentalCarReports] =
        useState<MonthlyRentalCarReportsByYears | null>(null);
    const [showFilters, setShowFilters] = useState<boolean | null>(null);
    const [selectedOffice, setSelectedOffice] = useState<string | null>(null);

    const currentYear: string = dayjs().format('YYYY');
    const lastYear: string = dayjs().subtract(1, 'year').format('YYYY');
    const recordsStartDate: string = dayjs()
        .subtract(2, 'year')
        .format('YYYY-01-01');
    const rentalCarStartDate: string = dayjs()
        .subtract(1, 'year')
        .format('YYYY-01-01');
    const endDate: string = dayjs().format('YYYY-MM-DD');

    useAsync(async () => {
        await getMonthlyRecords();
        await getRentalCarReports();
    }, []);

    const changeOffice: (
        event: SelectChangeEvent
    ) => Promise<void> = async event => {
        const val: string | null =
            typeof event.target.value === 'string' && event.target.value !== ''
                ? event.target.value
                : null;
        setSelectedOffice(val);
        await getMonthlyRecords(val);
        await getRentalCarReports(val);
    };

    const changeOfficeAutocomplete: (
        newValue: AutocompleteOption | null
    ) => Promise<void> = async newValue => {
        await getMonthlyRecords(newValue?.value ?? null);
        await getRentalCarReports(newValue?.value ?? null);
    };

    const onClickFilter: () => void = () => setShowFilters(!showFilters);

    const getMonthlyRecords = async (
        office: string | null = null
    ): Promise<void> => {
        try {
            const officeSearchParam = office ? { office_unique: office } : {};
            const records = await Ajax.get<MonthlyRecords>('records/monthly', {
                startDate: recordsStartDate,
                endDate,
                ...officeSearchParam,
            });

            const monthlyRecordsTmp = createMonthlyRecordsByYears(records);

            setMonthlyRecords(monthlyRecordsTmp);
        } catch (e) {
            enqueueSnackbar('Fehler beim Laden der Fallzahlen!', {
                variant: 'error',
            });
            logError(e);
        }
    };

    const getRentalCarReports = async (
        office: string | null = null
    ): Promise<void> => {
        try {
            const officeSearchParam = office ? { office_unique: office } : {};
            const reports = await Ajax.get<DateRecords>(
                'rentalCarReports/monthly',
                {
                    startDate: rentalCarStartDate,
                    endDate,
                    ...officeSearchParam,
                }
            );

            const monthlyReports = createMonthlyRentalCarReportsByYears(
                reports,
                currentYear
            );

            setRentalCarReports(monthlyReports);
        } catch (e) {
            enqueueSnackbar('Fehler beim Laden der Mietwagen-Tool-Reports!', {
                variant: 'error',
            });
            logError(e);
            setRentalCarReports({
                lastYear: {},
                currentYear: {},
            });
        }
    };

    if (monthlyRecords === null || rentalCarReports === null) {
        return (
            <CrmCard title={title}>
                <CircularProgress size={20} />
            </CrmCard>
        );
    }

    const coefficient: number = 0.7;

    const statisticsData: ChartData<'line'> = {
        labels: Object.keys(monthlyRecords[lastYear]['haftpflicht']).map(date =>
            dayjs(date).format('MMM')
        ),
        datasets: [
            ...Object.keys(monthlyRecords)
                .sort()
                .reduce((datasets: Obj[], year, index, yearsArray) => {
                    const colorKasko =
                        index === yearsArray.length - 1
                            ? theme.palette.primary.main
                            : lighten(
                                  theme.palette.primary.main,
                                  coefficient / (index + 1)
                              );
                    const colorHaftpflicht =
                        index === yearsArray.length - 1
                            ? theme.palette.secondary.light
                            : lighten(
                                  theme.palette.secondary.light,
                                  coefficient / (index + 1)
                              );
                    return [
                        ...datasets,
                        {
                            label: 'Kasko ' + year,
                            borderColor: colorKasko,
                            backgroundColor: 'transparent',
                            pointBackgroundColor: colorKasko,
                            data: Object.values(monthlyRecords[year]['kasko']),
                            hidden: index === 0,
                        },
                        {
                            label: 'Haftpflicht ' + year,
                            borderColor: colorHaftpflicht,
                            backgroundColor: 'transparent',
                            pointBackgroundColor: colorHaftpflicht,
                            data: Object.values(
                                monthlyRecords[year]['haftpflicht']
                            ),
                            hidden: index === 0,
                        },
                    ];
                }, []),
            {
                label: 'Mietwagen-Tool ' + lastYear,
                borderColor: lighten(theme.palette.yellow.main, coefficient),
                backgroundColor: 'transparent',
                pointBackgroundColor: lighten(
                    theme.palette.yellow.main,
                    coefficient
                ),
                data: Object.values(rentalCarReports.lastYear),
            },
            {
                label: 'Mietwagen-Tool ' + currentYear,
                borderColor: theme.palette.yellow.main,
                backgroundColor: 'transparent',
                pointBackgroundColor: theme.palette.yellow.main,
                data: Object.values(rentalCarReports.currentYear),
            },
        ] as ChartDataset<'line'>[],
    };

    const maxRecords = Math.max(
        ...Object.values(monthlyRecords).reduce(
            (values: number[], data) => [
                ...values,
                ...Object.values(data['kasko']),
                ...Object.values(data['haftpflicht']),
            ],
            []
        ),
        ...Object.values(rentalCarReports.currentYear),
        ...Object.values(rentalCarReports.lastYear),
        10
    );

    const stepSize: number = (() => {
        if (maxRecords >= 1000) return 500;
        if (maxRecords >= 200) return 100;
        if (maxRecords >= 20) return 10;
        return 2;
    })();

    return (
        <CrmCard
            title={title}
            titleAction={
                <>
                    <IconButton size="small" onClick={onClickFilter}>
                        <FilterList />
                    </IconButton>
                    <IconButton
                        size="small"
                        component={RouterLink}
                        to="/statistics/"
                    >
                        <ListIcon />
                    </IconButton>
                </>
            }
        >
            {showFilters !== null && (
                <Box mb={2} display={showFilters ? 'block' : 'none'}>
                    {user?.isAdmin ? (
                        <EntityAutocomplete
                            entity={OfficeEntity}
                            name="office"
                            minInputLength={0}
                            preloadAllEntries
                            onChangeHandler={changeOfficeAutocomplete}
                            size="small"
                            withoutFormik
                        />
                    ) : (
                        <FormControl size="small" margin="normal">
                            <InputLabel id="select-label">
                                {OfficeEntity.namePlural}
                            </InputLabel>
                            <Select
                                onChange={changeOffice}
                                labelId="select-label"
                                label={OfficeEntity.namePlural}
                                value={
                                    selectedOffice ? selectedOffice : undefined
                                }
                            >
                                <MenuItem key="all" value="">
                                    -
                                </MenuItem>
                                {user?.offices.map(office => (
                                    <MenuItem
                                        key={office.unique}
                                        value={office.unique}
                                    >
                                        {office.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>
            )}
            <Line
                data={statisticsData}
                options={{
                    animation: {
                        duration: showFilters !== null ? 0 : 1000,
                    },
                    scales: {
                        y: {
                            ticks: {
                                stepSize: stepSize,
                                maxTicksLimit:
                                    Math.ceil(maxRecords / stepSize) * stepSize,
                            },
                        },
                        x: {
                            ticks: {
                                minRotation: 45,
                            },
                        },
                    },
                }}
            />
        </CrmCard>
    );
};

export default Statistics;
