import React, { ReactElement, useContext } from 'react';
import { IconButton, Pagination, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import CrmCard from '../../helpers/CrmCard';
import usePagination from '../../../hooks/usePagination';
import { Report } from '../../../mappers/reportMapper';
import useDialog from '../../../hooks/useDialog';
import ReportAddDialog from '../Report/ReportAddDialog';
import ReportList from '../ReportList';
import { Contact } from '../../../mappers/contactMapper';
import { User } from '../../../mappers/userMapper';
import { AuthContext } from '../../../stores/AuthStore';
import { matchOffices } from '../../../helpers/matchOffices';

interface Props {
    reloadHandler(): void;
    reports?: Report[];
    contact: Contact;
}

const Reports = ({
    reports,
    reloadHandler,
    contact,
}: Props): ReactElement | null => {
    const {
        page,
        totalPages,
        handlePageChange,
        startEntryIndex,
        endEntryIndex,
    } = usePagination(reports?.length || 0);
    const { openDialog, closeDialog, dialogOpen } = useDialog();
    const { user } = useContext<AuthContext>(AuthContext);

    const reportAddResponseHandler = () => {
        reloadHandler();
        closeDialog();
    };

    if (!user) {
        return null;
    }

    return (
        <CrmCard
            title="Berichte"
            titleAction={
                userCanAddReport(user, contact) && (
                    <IconButton size="small" onClick={openDialog}>
                        <Add />
                    </IconButton>
                )
            }
            footer={
                totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        size="small"
                    />
                )
            }
        >
            {reports?.length ? (
                <ReportList
                    reports={reports}
                    startEntryIndex={startEntryIndex}
                    endEntryIndex={endEntryIndex}
                    hideContactName={true}
                    reportChangeHandler={reloadHandler}
                />
            ) : (
                <Typography variant="body2">
                    Bisher wurden keine Berichte verfasst.
                </Typography>
            )}

            <ReportAddDialog
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
                contact={contact}
                responseHandler={reportAddResponseHandler}
            />
        </CrmCard>
    );
};

export default Reports;

const userCanAddReport = (user: User, contact: Contact): boolean =>
    user.isAdmin || matchOffices(contact.offices, user.offices);
