import React, { ChangeEvent, ReactElement, useMemo, useState } from 'react';
import CrmToolbar from './helpers/CrmToolbar';
import {
    Box,
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
} from '@mui/material';
import { ContactEntity } from '../helpers/entities';
import { Contact } from '../mappers/contactMapper';
import useEntities from '../hooks/useEntities';
import { Link } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import Ajax from '../helpers/Ajax';
import { MonthlyRecords } from '../helpers/statistics';
import { useAsync } from 'react-use';
import useUniqueTableHeadCells from '../hooks/useUniqueTableHeadCells';
import { Order } from '../helpers/getStableSortMap';
import UniqueTableHeadCells, { HeadCell } from './modules/UniqueTableHeadCells';
import logError from '../errors/logError';
import { useSnackbar } from 'notistack';

interface TableData {
    contactID: string;
    contactName: string;
    rentalCarUsage: number;
    cases: number;
    ratio: number;
}

const headCells: HeadCell<TableData>[] = [
    { id: 'contactName', label: 'Name' },
    {
        id: 'rentalCarUsage',
        alignment: 'right',
        label: 'Mietwagen-Tool-Nutzung',
    },
    { id: 'cases', alignment: 'right', label: 'Fälle' },
    { id: 'ratio', alignment: 'right', label: 'Verhältnis (Nutzung / Fälle)' },
];

const RentalCarUsage = (): ReactElement => {
    dayjs.extend(isBetween);

    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(0);

    const { entities: contacts, totalCount } = useEntities<Contact>(
        ContactEntity,
        {
            withRelations: ['rentalCarReports'],
        }
    );

    const { order, orderBy, handleRequestSort, getStableSortMap } =
        useUniqueTableHeadCells<TableData>('rentalCarUsage', Order.DESC);
    const { enqueueSnackbar } = useSnackbar();

    const inputDateFormat = 'YYYY-MM';
    const initStartDate = dayjs().startOf('month').subtract(3, 'month');
    const initEndDate = dayjs().endOf('month');

    const [startDate, setStartDate] = useState<Dayjs>(initStartDate);
    const [endDate, setEndDate] = useState<Dayjs>(initEndDate);
    const [contactRecords, setContactRecords] = useState<MonthlyRecords | null>(
        null
    );

    const getRecordCountByContacts = async (): Promise<void> => {
        try {
            const records = await Ajax.get<MonthlyRecords>('records/contacts', {
                startDate: dayjs(startDate).format('YYYY-MM-DD'),
                endDate: dayjs(endDate).format('YYYY-MM-DD'),
            });
            setContactRecords(records);
        } catch (error) {
            enqueueSnackbar('Fehler beim Laden der Fallzahlen!', {
                variant: 'error',
            });
            logError(error);
            setContactRecords(null);
        }
    };

    useAsync(getRecordCountByContacts, [startDate, endDate]);

    const tableData = useMemo<TableData[]>(
        () =>
            contacts?.map(contact => {
                const rentalCarUsage =
                    contact.rental_car_reports
                        ?.filter(report =>
                            dayjs(report.reported_at).isBetween(
                                startDate,
                                endDate,
                                'day',
                                '[]'
                            )
                        )
                        .reduce(
                            (carry, report) => carry + report.calculations,
                            0
                        ) ?? 0;

                const cases =
                    contactRecords && contactRecords[contact.id]
                        ? contactRecords[contact.id].kasko +
                          contactRecords[contact.id].haftpflicht
                        : 0;

                return {
                    contactID: contact.id,
                    contactName: contact.name,
                    rentalCarUsage,
                    cases,
                    ratio: cases > 0 ? (100 * rentalCarUsage) / cases : 0,
                };
            }) ?? [],
        [contacts, startDate, endDate, contactRecords]
    );

    const sortedData = useMemo<TableData[]>(
        () =>
            getStableSortMap(tableData, order, orderBy).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            ),
        [tableData, order, orderBy, page, rowsPerPage]
    );

    const onStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputStartDate = event.target.value;
        setStartDate(
            inputStartDate
                ? dayjs(inputStartDate).startOf('month')
                : initStartDate
        );
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputEndDate = event.target.value;
        setEndDate(
            inputEndDate ? dayjs(inputEndDate).endOf('month') : initEndDate
        );
    };

    return (
        <Paper>
            <CrmToolbar title="Nutzung des Mietwagen-Tools" />
            <Box mx={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="month"
                            label="Von"
                            value={startDate.format(inputDateFormat)}
                            onChange={onStartDateChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="month"
                            label="Bis"
                            value={endDate.format(inputDateFormat)}
                            onChange={onEndDateChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <TableContainer>
                <Table>
                    <UniqueTableHeadCells
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                        order={order}
                        orderBy={orderBy}
                    />
                    <TableBody>
                        {startDate && endDate && startDate <= endDate ? (
                            tableData.length > 0 ? (
                                sortedData.map(
                                    ({
                                        contactID,
                                        contactName,
                                        rentalCarUsage,
                                        cases,
                                        ratio,
                                    }) => (
                                        <TableRow key={contactID}>
                                            <TableCell>
                                                <Link
                                                    to={`/contact/${contactID}`}
                                                >
                                                    {contactName}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="right">
                                                {rentalCarUsage}
                                            </TableCell>
                                            <TableCell align="right">
                                                {cases}
                                            </TableCell>
                                            <TableCell align="right">
                                                {ratio.toFixed(2)}%
                                            </TableCell>
                                        </TableRow>
                                    )
                                )
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={headCells.length}>
                                        Keine Kontakte vorhanden...
                                    </TableCell>
                                </TableRow>
                            )
                        ) : (
                            <TableRow>
                                <TableCell colSpan={headCells.length}>
                                    Bitte wählen Sie gültige Start- und
                                    Enddaten.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    labelRowsPerPage="Einträge pro Seite:"
                    rowsPerPageOptions={[10, 15, 25, 100]}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {tableData === null && <LinearProgress />}
            </TableContainer>
        </Paper>
    );
};

export default RentalCarUsage;
