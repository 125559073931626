import React, { ReactElement } from 'react';
import {
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useEntities from '../../../hooks/useEntities';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import { ContactPersonEntity } from '../../../helpers/entities';
import { List as ListIcon } from '@mui/icons-material';
import CrmCard from '../../helpers/CrmCard';
import Short from '../ContactPerson/Short';

const NewContactPersons = (): ReactElement => {
    const { entities: contactPersons } = useEntities<ContactPerson>(
        ContactPersonEntity,
        {
            limit: 3,
            orderBy: 'created_at',
            withRelations: ['address'],
        }
    );

    return (
        <CrmCard
            title="Neue Ansprechpartner"
            titleAction={
                <IconButton
                    size="small"
                    component={RouterLink}
                    to="/contact_persons/"
                >
                    <ListIcon />
                </IconButton>
            }
        >
            <List dense disablePadding>
                {contactPersons !== null ? (
                    contactPersons.map(contactPerson => (
                        <ListItem disableGutters key={contactPerson.id}>
                            <ListItemText
                                primary={
                                    <Short contactPerson={contactPerson} />
                                }
                                secondary={contactPerson.main_contact?.name}
                            />
                        </ListItem>
                    ))
                ) : (
                    <CircularProgress size={20} />
                )}
            </List>
        </CrmCard>
    );
};

export default NewContactPersons;
