import React, { ChangeEvent, CSSProperties, ReactElement, useRef } from 'react';
import { useFormikContext } from 'formik';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Add, AttachFile, Delete } from '@mui/icons-material';
import { FormBuilderFieldProps } from './FormBuilder';

type Props = FormBuilderFieldProps;

export const defaultValue = '';

const FileField = ({ name, label, required = false }: Props): ReactElement => {
    const { values, setFieldValue, errors } = useFormikContext<{
        [name: string]: File | typeof defaultValue;
    }>();
    const inputElement = useRef<HTMLInputElement>(null);
    const file: typeof defaultValue | File = values[name];

    const onClickUploadButton = () => {
        inputElement.current?.click();
    };

    const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
        const file: File | null | undefined = event.target?.files?.item(0);
        if (!file) {
            setFieldValue(name, defaultValue);
            return;
        }

        setFieldValue(name, file);
    };

    const onDeleteFile = () => {
        setFieldValue(name, defaultValue);
    };

    return (
        <>
            <TextField
                label={label}
                value={file instanceof File ? file.name : ''}
                required={required}
                error={!!errors[name]}
                helperText={errors[name]}
                placeholder="Datei auswählen"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <AttachFile />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                type="button"
                                onClick={
                                    file ? onDeleteFile : onClickUploadButton
                                }
                                color={file ? 'secondary' : 'primary'}
                            >
                                {file ? <Delete /> : <Add />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    style: {
                        caretColor: 'transparent',
                    } as CSSProperties,
                }}
            />
            <input
                type="file"
                ref={inputElement}
                style={{ display: 'none' } as CSSProperties}
                onChange={onChangeFile}
            />
        </>
    );
};

export default FileField;
