import React, { ReactElement } from 'react';
import { icon } from 'leaflet';
import { Box, Link, Typography } from '@mui/material';
import { Marker, Popup } from 'react-leaflet';
import { Link as RouterLink } from 'react-router-dom';
import { Contact } from '../../mappers/contactMapper';

interface Props {
    contact: Contact;
    selectedId?: string;
}

const ContactsMapMarker = ({
    contact,
    selectedId,
}: Props): ReactElement | null => {
    const setIconUrl = (selectedId?: string): string => {
        if (selectedId == contact.id) {
            return '/images/crm/marker-icon-green.svg';
        }
        if (contact.empfehler) {
            return '/images/crm/marker-icon-red.svg';
        }
        return '/images/crm/marker-icon-blue.svg';
    };

    return contact.address?.latitude && contact.address?.longitude ? (
        <Marker
            icon={icon({
                iconUrl: setIconUrl(selectedId),
                shadowUrl: '/images/crm/marker-shadow.svg',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize: [41, 41],
            })}
            position={[contact.address.latitude, contact.address.longitude]}
        >
            <Box maxWidth="70vw">
                <Popup>
                    <Typography variant="body2" component="div">
                        <Link
                            component={RouterLink}
                            to={'/contact/' + contact.id}
                        >
                            {contact.name}
                        </Link>
                    </Typography>
                    <Typography
                        variant="body2"
                        component="div"
                        color="textSecondary"
                    >
                        {[
                            contact.address.address,
                            contact.address.postcode,
                            contact.address.city,
                        ]
                            .filter(Boolean)
                            .join(', ')}
                    </Typography>
                </Popup>
            </Box>
        </Marker>
    ) : null;
};

export default ContactsMapMarker;
