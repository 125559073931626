import React, { FC, useContext, useState } from 'react';
import CrmCard from '../../helpers/CrmCard';
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    useTheme,
} from '@mui/material';
import { FilterList } from '@mui/icons-material';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';
import { OfficeEntity } from '../../../helpers/entities';
import { Line } from 'react-chartjs-2';
import { AuthContext } from '../../../stores/AuthStore';
import { AutocompleteOption } from '../../../crm';
import Ajax from '../../../helpers/Ajax';
import dayjs from 'dayjs';
import logError from '../../../errors/logError';
import { useSnackbar } from 'notistack';
import { CrmTheme } from '../../../theme';
import { useDebounce } from 'react-use';
import AlertBox from '../../helpers/AlertBox';

interface DailyRecords {
    kasko: Obj;
    haftpflicht: Obj;
}

interface Props {
    startDate: Date;
    endDate: Date;
}

const DailyRecords: FC<Props> = ({ startDate, endDate }) => {
    const theme: CrmTheme = useTheme();
    const [dailyRecords, setDailyRecords] = useState<DailyRecords | null>(null);
    const [showFilters, setShowFilters] = useState<boolean | null>(null);
    const { user } = useContext<AuthContext>(AuthContext);
    const [selectedOffice, setSelectedOffice] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();

    const onClickFilter: () => void = () => setShowFilters(!showFilters);

    const changeOfficeAutocomplete: (
        newValue: AutocompleteOption | null
    ) => void = newValue => {
        setSelectedOffice(newValue?.value ?? '');
    };

    const changeOffice: (event: SelectChangeEvent) => void = event => {
        const val: string = event.target.value as string;
        setSelectedOffice(val);
    };

    const dateRangeValid: boolean =
        Math.abs(dayjs(startDate).diff(dayjs(endDate), 'month')) < 2;

    const getDailyRecords: () => Promise<void> = async () => {
        if (!dateRangeValid) {
            setDailyRecords(null);
            return;
        }

        try {
            const records: Obj = await Ajax.get<Obj>('records/daily', {
                startDate: dayjs(startDate).format('YYYY-MM-DD'),
                endDate: dayjs(endDate).format('YYYY-MM-DD'),
                ...(!!selectedOffice && {
                    office_unique: selectedOffice,
                }),
            });

            const dailyRecordsTmp: DailyRecords = {
                haftpflicht: {},
                kasko: {},
            };

            Object.keys(records).map(date => {
                dailyRecordsTmp.haftpflicht[date] = records[date].haftpflicht;
                dailyRecordsTmp.kasko[date] = records[date].kasko;
            });

            setDailyRecords(dailyRecordsTmp);
        } catch (e) {
            enqueueSnackbar('Fehler beim Laden der täglichen Fallzahlen!', {
                variant: 'error',
            });
            logError(e);
        }
    };

    useDebounce(getDailyRecords, 500, [startDate, endDate, selectedOffice]);

    const statisticsData: {
        labels: string[];
        datasets: {
            label: string;
            borderColor: string;
            backgroundColor: string;
            pointBackgroundColor: string;
            data: any[];
        }[];
    } = {
        labels: Object.keys(dailyRecords?.haftpflicht || []).map(date =>
            dayjs(date).format('DD.MM.YY')
        ),
        datasets: [
            {
                label: 'Kasko',
                borderColor: theme.palette.primary.main,
                backgroundColor: 'transparent',
                pointBackgroundColor: theme.palette.primary.main,
                data: Object.values(dailyRecords?.kasko || []),
            },
            {
                label: 'Haftpflicht',
                borderColor: theme.palette.secondary.light,
                backgroundColor: 'transparent',
                pointBackgroundColor: theme.palette.secondary.light,
                data: Object.values(dailyRecords?.haftpflicht || []),
            },
        ],
    };

    const maxRecords: number = Math.max(
        ...Object.values(dailyRecords?.kasko || []),
        ...Object.values(dailyRecords?.haftpflicht || []),
        10
    );

    const stepSize: number = (() => {
        if (maxRecords >= 1000) return 500;
        if (maxRecords >= 200) return 100;
        if (maxRecords >= 20) return 10;
        return 2;
    })();

    return (
        <CrmCard
            title="Fälle pro Tag"
            titleAction={
                <IconButton size="small" onClick={onClickFilter}>
                    <FilterList />
                </IconButton>
            }
        >
            {dailyRecords ? (
                <>
                    {showFilters !== null && (
                        <Box mb={2} display={showFilters ? 'block' : 'none'}>
                            {user?.isAdmin ? (
                                <EntityAutocomplete
                                    entity={OfficeEntity}
                                    name="office"
                                    minInputLength={0}
                                    preloadAllEntries
                                    onChangeHandler={changeOfficeAutocomplete}
                                    size="small"
                                    withoutFormik
                                />
                            ) : (
                                <FormControl size="small" margin="normal">
                                    <InputLabel id="select-label">
                                        {OfficeEntity.namePlural}
                                    </InputLabel>
                                    <Select
                                        onChange={changeOffice}
                                        labelId="select-label"
                                        label={OfficeEntity.namePlural}
                                        value={selectedOffice}
                                    >
                                        <MenuItem key="all" value="">
                                            -
                                        </MenuItem>
                                        {user?.offices.map(office => (
                                            <MenuItem
                                                key={office.unique}
                                                value={office.unique}
                                            >
                                                {office.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Box>
                    )}
                    <Line
                        data={statisticsData}
                        options={{
                            animation: {
                                duration: showFilters !== null ? 0 : 1000,
                            },
                            scales: {
                                y: {
                                    ticks: {
                                        stepSize: stepSize,
                                        maxTicksLimit:
                                            Math.ceil(maxRecords / stepSize) *
                                            stepSize,
                                    },
                                },
                                x: {
                                    ticks: {
                                        minRotation: 45,
                                    },
                                },
                            },
                        }}
                    />
                </>
            ) : dateRangeValid ? (
                <LinearProgress />
            ) : (
                <AlertBox>
                    Bitte wählen Sie für die Tagesstatistiken einen Zeitraum von
                    maximal zwei Monaten.
                </AlertBox>
            )}
        </CrmCard>
    );
};

export default DailyRecords;
