import CrmDialog from '../helpers/CrmDialog';
import {
    Box,
    CircularProgress,
    DialogContent,
    Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';

interface Props {
    dialogOpen: boolean;
    closeDialog(): void;
}

const DownloadDialog = ({ dialogOpen, closeDialog }: Props): ReactElement => (
    <CrmDialog
        dialogOpen={dialogOpen}
        closeDialog={closeDialog}
        maxWidth="sm"
        confirmClose={false}
    >
        <DialogContent>
            <Box
                my={3}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Box mb={1}>
                    <Typography mb={1} gutterBottom component="h1" variant="h1">
                        Export wird generiert
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography mb={2} gutterBottom color="textSecondary">
                        Dieser Vorgang kann je nach Umfang mehrere Minuten
                        dauern.
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        </DialogContent>
    </CrmDialog>
);
export default DownloadDialog;
