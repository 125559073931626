import React, { ReactElement, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import Name from './modules/ContactPerson/Name';
import Contacts from './modules/ContactPerson/Contacts';
import ContactDetails from './modules/ContactDetails/ContactDetails';
import useEntity from '../hooks/useEntity';
import { ContactPerson } from '../mappers/contactPersonMapper';
import { ContactPersonEntity } from '../helpers/entities';
import ContactPersonPosition from './modules/ContactPerson/ContactPersonPosition';
import Note from './modules/ContactPerson/Note';
import ContactPersonBirthday from './modules/ContactPerson/ContactPersonBirthday';
import { ContactPersonContactDetails } from '../mappers/contactDetailMapper';
import AlertBox from './helpers/AlertBox';
import { GlobalContext } from '../stores/GlobalStore';
import AlertPermissionDenied from './helpers/AlertPermissionDenied';
import AddressView from './modules/AddressView';

const ContactPersonView = (): ReactElement => {
    const { contactPersonId } = useParams<{ contactPersonId?: string }>();
    const { setBackdropOpen } = useContext(GlobalContext);
    const {
        entity: contactPerson,
        reloadEntity: reloadContactPerson,
        requestStatus,
    } = useEntity<ContactPerson>(ContactPersonEntity, contactPersonId);

    useEffect(() => {
        setBackdropOpen(contactPerson !== null && requestStatus === 'loading');
    }, [requestStatus, contactPerson]);

    if (requestStatus === 'error-forbidden') {
        return <AlertPermissionDenied />;
    }

    if (!contactPerson) {
        return <LinearProgress />;
    }

    return (
        <>
            <Paper>
                <Name
                    contactPerson={contactPerson}
                    reloadContactPerson={reloadContactPerson}
                />
            </Paper>

            {contactPerson.is_archived && (
                <AlertBox>{contactPerson.name} ist archiviert.</AlertBox>
            )}

            {contactPerson.contacting_forbidden && (
                <AlertBox>
                    {contactPerson.name} wünscht keine Kontaktaufnahme.
                </AlertBox>
            )}

            <Box my={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <AddressView
                            address={contactPerson.address}
                            apiUrl={
                                ContactPersonEntity.apiBaseUrl +
                                '/' +
                                contactPerson.id +
                                '/address'
                            }
                            reloadHandler={reloadContactPerson}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <ContactDetails
                            contactDetailFields={ContactPersonContactDetails}
                            contactDetails={contactPerson.contact_details}
                            reloadHandler={reloadContactPerson}
                            contactDetailUpdateUrl={
                                ContactPersonEntity.apiBaseUrl +
                                '/' +
                                contactPerson.id +
                                '/contactdetails'
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Contacts
                            contactPerson={contactPerson}
                            reloadContactPerson={reloadContactPerson}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ContactPersonPosition
                            reloadHandler={reloadContactPerson}
                            contactPerson={contactPerson}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Note
                            note={contactPerson.note}
                            apiUrl={
                                ContactPersonEntity.apiBaseUrl +
                                '/' +
                                contactPerson.id
                            }
                            reloadHandler={reloadContactPerson}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ContactPersonBirthday
                            reloadHandler={reloadContactPerson}
                            contactPerson={contactPerson}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ContactPersonView;
