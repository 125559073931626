import React, { ReactElement } from 'react';
import { Contact } from '../../../mappers/contactMapper';
import CrmCard from '../../helpers/CrmCard';
import { IconButton, List, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import CrmDialog from '../../helpers/CrmDialog';
import useDialog from '../../../hooks/useDialog';
import ContractFormDialog from './ContractFormDialog';
import ContractView from './ContractView';

interface Props {
    contact: Contact;
    reloadHandler: { (): void };
}

const Contracts = ({ contact, reloadHandler }: Props): ReactElement => {
    const { openDialog, closeDialog, dialogOpen } = useDialog();

    const contractResponseHandler = () => {
        closeDialog();
        reloadHandler();
    };

    return (
        <>
            <CrmCard
                title="Verträge"
                titleAction={
                    <IconButton size="small" onClick={openDialog}>
                        <Add />
                    </IconButton>
                }
            >
                {contact.contracts?.length ? (
                    <List dense disablePadding>
                        {contact.contracts.map(contract => (
                            <ContractView
                                contract={contract}
                                key={contract.id}
                                reloadHandler={reloadHandler}
                            />
                        ))}
                    </List>
                ) : (
                    <Typography variant="body2">
                        Bisher wurden keine Verträge hochgeladen.
                    </Typography>
                )}
            </CrmCard>
            <CrmDialog
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
                maxWidth="sm"
                confirmClose
            >
                <ContractFormDialog
                    action={`contacts/${contact.id}/contracts`}
                    closeDialog={closeDialog}
                    responseHandler={contractResponseHandler}
                />
            </CrmDialog>
        </>
    );
};

export default Contracts;
