import React, { FC } from 'react';
import { Box, Icon } from '@mui/material';
import { Warning } from '@mui/icons-material';

interface Props {
    mr?: number;
}

const WarningIcon: FC<Props> = ({ mr = 0.5 }) => (
    <Box mr={mr}>
        <Icon
            sx={{
                marginRight: mr,
                iconAlignment: {
                    verticalAlign: 'middle',
                    marginBottom: '2px',
                },
            }}
            component={Warning}
            fontSize="inherit"
            color="error"
        />
    </Box>
);

export default React.memo(WarningIcon);
