import React, { ReactElement, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { Entity, getEntities } from '../../../helpers/entities';
import { AutocompleteOption } from '../../../crm';
import AutocompleteInput from './AutocompleteInput';

interface Props<T> {
    selectedElement?: T;
    entity: Entity<T>;
    name: string;
    minInputLength?: number;
    onChangeHandler?(newValue: AutocompleteOption | null): void;
    autoFocus?: boolean;
    preloadAllEntries?: boolean;
    size?: 'medium' | 'small';
    withoutFormik?: boolean;
}

export default function EntityAutocomplete<T>({
    selectedElement,
    entity,
    name,
    minInputLength,
    onChangeHandler,
    autoFocus,
    preloadAllEntries = true,
    size = 'medium',
    withoutFormik = false,
}: Props<T>): ReactElement {
    const [preloadedEntities, setPreloadedEntities] = useState<T[] | null>(
        null
    );
    const [selected, setSelected] = useState<AutocompleteOption | null>(null);

    useEffect(() => {
        preloadAllEntries && preloadAllElements();
    }, []);

    useEffect(() => {
        selectedElement &&
            setSelected(entity.autocompleteOptionMapper(selectedElement));
    }, [selectedElement]);

    const preloadAllElements = async () => {
        const entities = await getEntities(entity);
        setPreloadedEntities(entities);
    };

    const getElements = async (
        search: string
    ): Promise<AutocompleteOption[]> => {
        const result = preloadAllEntries
            ? (preloadedEntities ?? [])
            : await getEntities(entity, {
                  search,
              });

        return entity.autocompleteOptionsMapper(result);
    };

    if (
        (selectedElement && !selected) ||
        (preloadAllEntries && preloadedEntities === null)
    ) {
        return <TextField size={size} disabled value="Lädt..." />;
    }

    return (
        <AutocompleteInput
            getOptions={getElements}
            name={name}
            size={size}
            label={entity.name}
            selectedOption={selected}
            minInputLength={minInputLength}
            onChangeHandler={onChangeHandler}
            autoFocus={autoFocus}
            withoutFormik={withoutFormik}
        />
    );
}
