import React, { useState } from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { Close } from '@mui/icons-material';
import { AutocompleteOption } from '../../../crm';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';
import { ContactEntity, ContactPersonEntity } from '../../../helpers/entities';

interface Props {
    closeDialog: { (): void };
    contactId?: string;

    responseHandler(): void;
}

const ContactPersonLinkDialog = ({
    closeDialog,
    contactId,
    responseHandler,
}: Props) => {
    const [selectedContactPersonId, setSelectedContactPersonId] = useState<
        string | null
    >(null);

    const autocompleteChangeHandler = (newValue: AutocompleteOption | null) => {
        setSelectedContactPersonId(newValue ? newValue.value : null);
    };

    return (
        <FormBuilder
            action={
                ContactEntity.apiBaseUrl +
                '/' +
                contactId +
                '/contact_persons/' +
                selectedContactPersonId
            }
            responseHandler={responseHandler}
            initialValues={{ contact_person_id: '' }}
            errorMessage="Fehler beim Verknüpfen des Ansprechpartners!"
        >
            <DialogTitle>
                <Typography component="h2">
                    Ansprechpartner verknüpfen
                </Typography>
                <IconButton onClick={closeDialog} size="small" edge="end">
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <EntityAutocomplete
                    entity={ContactPersonEntity}
                    name="contact_person_id"
                    onChangeHandler={autocompleteChangeHandler}
                    autoFocus
                />
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    color="primary"
                    disabled={!selectedContactPersonId}
                >
                    Ansprechpartner verknüpfen
                </Button>
            </DialogActions>
        </FormBuilder>
    );
};

export default ContactPersonLinkDialog;
