import React, { FC } from 'react';
import { AssignmentTurnedIn, KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const ReportDoneNotification: FC = () => (
    <Box mx="auto" my="100px" maxWidth="500px">
        <Box
            component="div"
            display="grid"
            alignItems="center"
            gridTemplateColumns="auto 1fr"
            gap={1}
            mb={1}
        >
            <AssignmentTurnedIn color="primary" />
            <Typography variant="h1">Erledigt</Typography>
        </Box>
        <Typography gutterBottom>
            Ihre Wiedervorlage wurde als erledigt markiert.
        </Typography>
        <Box sx={{ mt: 4, display: 'inline-block' }}>
            <Button
                component={Link}
                to="/"
                color="secondary"
                variant="contained"
                startIcon={<KeyboardArrowLeft />}
            >
                Zurück zum Dashboard
            </Button>
        </Box>
    </Box>
);

export default ReportDoneNotification;
