import React, { FC, ReactElement } from 'react';
import { Alert, Box, Paper, Typography } from '@mui/material';

interface Props {
    children: ReactElement | ReactElement[] | string | string[];
}

const AlertBox: FC<Props> = ({ children }) => (
    <Box mt={3}>
        <Paper>
            <Alert severity="warning">
                <Typography>{children}</Typography>
            </Alert>
        </Paper>
    </Box>
);

export default AlertBox;
