import React, { ReactElement, useContext, useEffect } from 'react';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import Name from './modules/Contact/Name';
import ContactPersons from './modules/Contact/ContactPersons';
import ContactDetails from './modules/ContactDetails/ContactDetails';
import Reports from './modules/Contact/Reports';
import Statistic from './modules/Contact/Statistic';
import useEntity from '../hooks/useEntity';
import { Contact } from '../mappers/contactMapper';
import { ContactEntity } from '../helpers/entities';
import Empfehler from './modules/Contact/Empfehler';
import Profile from './modules/Contact/Profile';
import Offices from './modules/Contact/Offices';
import AlertPermissionDenied from './helpers/AlertPermissionDenied';
import { GlobalContext } from '../stores/GlobalStore';
import Contracts from './modules/Contact/Contracts';
import Note from './modules/ContactPerson/Note';
import { ContactContactDetails } from '../mappers/contactDetailMapper';
import AlertBox from './helpers/AlertBox';
import Files from './modules/Files';
import AddressView from './modules/AddressView';

const ContactView = (): ReactElement => {
    const { contactId } = useParams<{ contactId?: string }>();
    const { setBackdropOpen } = useContext(GlobalContext);
    const {
        entity: contact,
        reloadEntity: reloadContact,
        requestStatus,
    } = useEntity<Contact>(ContactEntity, contactId);

    useEffect(() => {
        setBackdropOpen(contact !== null && requestStatus === 'loading');
    }, [requestStatus, contact]);

    if (requestStatus === 'error-forbidden') {
        return <AlertPermissionDenied />;
    }

    if (!contact) {
        return <LinearProgress />;
    }

    return (
        <>
            <Paper>
                <Name contact={contact} reloadContact={reloadContact} />
            </Paper>

            {contact.contacting_forbidden && (
                <AlertBox>
                    {contact.name} wünscht keine Kontaktaufnahme.
                </AlertBox>
            )}

            <Box my={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <AddressView
                            apiUrl={
                                ContactEntity.apiBaseUrl +
                                '/' +
                                contact.id +
                                '/address'
                            }
                            address={contact?.address}
                            contactId={contact.id}
                            reloadHandler={reloadContact}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <ContactDetails
                            contactDetailFields={ContactContactDetails}
                            contactDetails={contact.contact_details}
                            reloadHandler={reloadContact}
                            contactDetailUpdateUrl={
                                ContactEntity.apiBaseUrl +
                                '/' +
                                contact.id +
                                '/contactdetails'
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ContactPersons
                            contact={contact}
                            reloadContact={reloadContact}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Profile
                            contact={contact}
                            reloadHandler={reloadContact}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Reports
                            reports={contact.reports}
                            reloadHandler={reloadContact}
                            contact={contact}
                        />
                    </Grid>
                    {contact.empfehler && (
                        <Grid item xs={12} md={6}>
                            <Statistic
                                contact={contact}
                                reports={contact.reports}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <Contracts
                            contact={contact}
                            reloadHandler={reloadContact}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Note
                            note={contact.note}
                            apiUrl={ContactEntity.apiBaseUrl + '/' + contact.id}
                            reloadHandler={reloadContact}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Files
                            fileable={contact}
                            apiUrl={
                                ContactEntity.apiBaseUrl +
                                '/' +
                                contact.id +
                                '/files'
                            }
                            reloadHandler={reloadContact}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Empfehler
                            empfehler={contact.empfehler}
                            apiUrl={ContactEntity.apiBaseUrl + '/' + contact.id}
                            reloadHandler={reloadContact}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Offices
                            contact={contact}
                            reloadHandler={reloadContact}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ContactView;
