import React, { ReactElement, useContext } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { EntityContext } from '../../../helpers/entities';
import { Contact } from '../../../mappers/contactMapper';

const ContactRows = (): ReactElement => {
    const { entities: contacts, reloadEntities } = useContext(EntityContext);

    return (
        <>
            {contacts &&
                contacts.map((contact: Contact) => (
                    <TableRow key={contact.id}>
                        <TableCell>
                            <Link to={`/contact/${contact.id}`}>
                                {contact.name}
                            </Link>
                        </TableCell>
                        <TableCell>{contact.group?.group}</TableCell>
                        <TableCell>{contact.address?.address}</TableCell>
                        <TableCell>{contact.address?.postcode}</TableCell>
                        <TableCell>{contact.address?.city}</TableCell>
                    </TableRow>
                ))}
        </>
    );
};

export default ContactRows;
