import React, { ReactElement } from 'react';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import useEntity from '../hooks/useEntity';
import { User } from '../mappers/userMapper';
import { UserEntity } from '../helpers/entities';
import Name from './modules/User/Name';
import ContactDetails from './modules/User/ContactDetails';
import Offices from './modules/User/Offices';
import PasswordOverwrite from './modules/User/PasswordOverwrite';
import Disable from './modules/User/Disable';

const UserView = (): ReactElement => {
    const { userId } = useParams<{ userId?: string }>();
    const { entity: user, reloadEntity: reloadUser } = useEntity<User>(
        UserEntity,
        userId
    );

    if (!user) {
        return <LinearProgress />;
    }

    return (
        <>
            <Paper>
                <Name user={user} reloadUser={reloadUser} />
            </Paper>

            <Box my={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <ContactDetails
                            user={user}
                            reloadHandler={reloadUser}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Offices user={user} reloadHandler={reloadUser} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PasswordOverwrite user={user} />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Disable user={user} reloadHandler={reloadUser} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default UserView;
