import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { Entity, EntityContext } from '../../helpers/entities';
import useEntities from '../../hooks/useEntities';
import { GlobalContext } from '../../stores/GlobalStore';
import { FilterList, GetApp } from '@mui/icons-material';
import { useToggle } from 'react-use';
import { Form, Formik } from 'formik';
import Ajax from '../../helpers/Ajax';
import logError from '../../errors/logError';
import { useSnackbar } from 'notistack';
import EditCloseAction from './actions/EditCloseAction';
import CrmDialog from './CrmDialog';
import useDialog from '../../hooks/useDialog';
import HelpDialog from './HelpDialog';

interface Props {
    entity: Entity<any>;
    buttons?: React.ReactNode;
    children: any;
    withRelations?: string[];
    filters?: React.ReactNode;
    downloadable?: boolean;
    downloadDialogContent?: ReactElement;
    downloadOptions?: Obj;
    helpers?: { heading: string; text: string }[];
    preselectedFilters?: Obj | null;
    additionalQueryParameters?: Obj | null;
}

const PaginationPage = <T extends Entity<any>>({
    entity,
    buttons,
    children,
    withRelations,
    filters,
    downloadable,
    downloadDialogContent,
    downloadOptions,
    helpers,
    preselectedFilters = null,
    additionalQueryParameters,
}: Props): ReactElement => {
    const theme: Theme = useTheme();
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(0);
    const [activeFilters, setActiveFilters] = useState<Obj | null>(
        preselectedFilters
    );
    const { entities, totalCount, reloadEntities } = useEntities<T>(entity, {
        page: page,
        perPage: rowsPerPage,
        ...(withRelations && {
            withRelations,
        }),
        ...activeFilters,
        ...additionalQueryParameters,
    });
    const isLoading: boolean = entities === null;
    const { reloadPage, setReloadPage } = useContext(GlobalContext);
    const [showFilters, toggleShowFilters] = useToggle(false);
    const { enqueueSnackbar } = useSnackbar();
    const {
        dialogOpen: downloadDialogOpen,
        closeDialog: closeDownloadDialog,
        openDialog: openDownloadDialog,
    } = useDialog();
    const {
        dialogOpen: loadingDialogOpen,
        closeDialog: closeLoadingDialog,
        openDialog: openLoadingDialog,
    } = useDialog();

    useEffect(() => {
        if (reloadPage) {
            reloadEntities();
            setReloadPage(false);
        }
    }, [reloadPage]);

    const paginationClasses: string = [
        { transition: 'all .2s' },
        ...(isLoading ? [{ pointerEvents: 'none' }, { opacity: 0.3 }] : []),
    ].join(' ');

    const handleChangePage: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number
    ) => void = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => void = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onFiltersFormSubmit: (values: Obj) => void = values => {
        const joinedValues: Obj = {};
        Object.keys(values).map(key => {
            if (Array.isArray(values[key]) && values[key].length) {
                joinedValues[key] = values[key].join(',');
            }
        });
        setActiveFilters(joinedValues);
    };

    const downloadList: () => Promise<void> = async () => {
        closeDownloadDialog();
        openLoadingDialog();

        try {
            await Ajax.download(entity.apiBaseUrl + '/export', {
                ...(withRelations && {
                    withRelations,
                }),
                ...activeFilters,
                ...downloadOptions,
            });

            closeDownloadDialog();
        } catch (e) {
            enqueueSnackbar(`Fehler beim Herunterladen des Exportes!`, {
                variant: 'error',
            });
            logError(e);
        }

        closeLoadingDialog();
    };

    return (
        <Paper>
            <Toolbar sx={{ padding: theme.spacing(2) }}>
                <Typography sx={{ flexGrow: 1 }} variant="h1" component="h1">
                    {entity.namePlural}
                </Typography>
                {helpers && <HelpDialog helpers={helpers} />}
                {isLoading && <CircularProgress size={20} />}
                {downloadable &&
                    (downloadDialogContent ? (
                        <IconButton onClick={openDownloadDialog}>
                            <GetApp />
                        </IconButton>
                    ) : (
                        <Tooltip
                            title="Liste als .xls herunterladen"
                            placement="top"
                            arrow
                        >
                            <IconButton onClick={downloadList}>
                                <GetApp />
                            </IconButton>
                        </Tooltip>
                    ))}
                {filters && (
                    <IconButton onClick={toggleShowFilters}>
                        <FilterList />
                    </IconButton>
                )}
                {buttons}
            </Toolbar>
            {showFilters && (
                <Formik initialValues={{}} onSubmit={onFiltersFormSubmit}>
                    <Form>
                        <Box m={2}>{filters}</Box>
                    </Form>
                </Formik>
            )}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {entity.columns.map((columnTitle, index) => (
                                <TableCell key={index}>{columnTitle}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isLoading &&
                            (totalCount ? (
                                <EntityContext.Provider
                                    value={{ entities, reloadEntities }}
                                >
                                    {children}
                                </EntityContext.Provider>
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={entity.columns.length}>
                                        {`Keine ${entity.namePlural} vorhanden...`}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {isLoading && <LinearProgress />}
            </TableContainer>
            {!isLoading && !!totalCount && (
                <TablePagination
                    className={paginationClasses}
                    component="div"
                    labelRowsPerPage="Einträge pro Seite:"
                    rowsPerPageOptions={[10, 15, 25, 100]}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
            <CrmDialog
                dialogOpen={loadingDialogOpen}
                closeDialog={closeLoadingDialog}
                maxWidth="sm"
                confirmClose={false}
            >
                <DialogContent>
                    <Box
                        my={3}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box mb={1}>
                            <Typography
                                mb={1}
                                gutterBottom
                                component="h1"
                                variant="h1"
                            >
                                Export wird generiert
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography
                                mb={1}
                                gutterBottom
                                color="textSecondary"
                            >
                                Dieser Vorgang kann je nach Umfang mehrere
                                Minuten dauern.
                            </Typography>
                        </Box>
                        <CircularProgress />
                    </Box>
                </DialogContent>
            </CrmDialog>
            {downloadDialogContent && (
                <CrmDialog
                    dialogOpen={downloadDialogOpen}
                    closeDialog={closeDownloadDialog}
                    maxWidth="sm"
                    confirmClose={false}
                >
                    <DialogTitle>
                        <Typography component="h2">Export Optionen</Typography>
                        <EditCloseAction
                            editClose={closeDownloadDialog}
                            edge="end"
                            noCloseConfirmation
                        />
                    </DialogTitle>
                    <DialogContent dividers>
                        {downloadDialogContent}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            color="primary"
                            onClick={downloadList}
                        >
                            Exportieren
                        </Button>
                    </DialogActions>
                </CrmDialog>
            )}
        </Paper>
    );
};

export default PaginationPage;
