import React, { ReactElement } from 'react';
import {
    TableCell,
    TableCellProps,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { Order } from '../../helpers/getStableSortMap';

export interface HeadCell<T> {
    id: keyof T;
    label: string;
    alignment?: TableCellProps['align'];
}

interface Props<T> {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof T
    ) => void;
    headCells: HeadCell<T>[];
    order: Order;
    orderBy: keyof T;
}

const UniqueTableHeadCells = <T,>({
    onRequestSort,
    headCells,
    order,
    orderBy,
}: Props<T>): ReactElement => {
    const createSortHandler: (
        property: keyof T
    ) => (event: React.MouseEvent<unknown>) => void =
        (property: keyof T) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id as string}
                        align={headCell.alignment}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            sx={{
                                '& .MuiTableSortLabel-icon': {
                                    opacity: orderBy === headCell.id ? 1 : 0.25,
                                },
                            }}
                            active={orderBy === headCell.id}
                            direction={
                                orderBy === headCell.id ? order : Order.ASC
                            }
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default UniqueTableHeadCells;
