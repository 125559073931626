import React, { Fragment, ReactElement, useContext, useMemo } from 'react';
import {
    Grid,
    IconButton,
    MenuItem,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
import { TextField } from 'formik-mui';
import { Field } from 'formik';
import { Contact } from '../../../mappers/contactMapper';
import useEditable from '../../../hooks/useEditable';
import CrmCard from '../../helpers/CrmCard';
import CrmLabel from '../../helpers/CrmLabel';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { ConfigContext } from '../../../stores/ConfigStore';
import EntityAutocompleteMultiple from '../../helpers/forms/EntityAutocompleteMultiple';
import {
    AccidentConsultantEntity,
    BrandEntity,
    CampaignEntity,
    CompetitorEntity,
    ContactEntity,
    ContactTypeEntity,
    CooperationEntity,
    WorkshopSystemEntity,
} from '../../../helpers/entities';
import { Brand } from '../../../mappers/brandMapper';
import { ContactType } from '../../../mappers/contactTypeMapper';
import { AccidentConsultant } from '../../../mappers/accidentConsultantMapper';
import { WorkshopSystem } from '../../../mappers/workshopSystemMapper';
import { Cooperation } from '../../../mappers/cooperationMapper';
import EditCloseAction from '../../helpers/actions/EditCloseAction';
import { Competitor } from '../../../mappers/competitorMapper';
import { Link } from 'react-router-dom';
import EntityAutocomplete from '../../helpers/forms/EntityAutocomplete';

interface Props {
    contact: Contact;

    reloadHandler(): void;
}

const Profile = ({ contact, reloadHandler }: Props): ReactElement => {
    const { isEdited, editOpen, editClose } = useEditable();
    const { segmentationOptions } = useContext<ConfigContext>(ConfigContext);
    const theme: Theme = useTheme();
    const fiveColumnBreackpoint: boolean = useMediaQuery(
        theme.breakpoints.down('md')
    );

    const editResponseHandler = () => {
        editClose();
        reloadHandler();
    };

    const sortedCompetitors: Competitor[] = useMemo(
        () =>
            contact.competitors
                ? contact.competitors.sort((a, b) =>
                      a.competitor.localeCompare(b.competitor)
                  )
                : [],
        [contact.competitors]
    );

    const sortedBrands: Brand[] = useMemo(
        () =>
            contact.brands
                ? contact.brands.sort((a, b) => a.brand.localeCompare(b.brand))
                : [],
        [contact.brands]
    );
    const sortedContactTypes: ContactType[] = useMemo(
        () =>
            contact.contact_types
                ? contact.contact_types.sort((a, b) =>
                      a.contact_type.localeCompare(b.contact_type)
                  )
                : [],
        [contact.contact_types]
    );

    const sortedAccidentConsultants: AccidentConsultant[] = useMemo(
        () =>
            contact.accident_consultants
                ? contact.accident_consultants.sort((a, b) =>
                      a.accident_consultant.localeCompare(b.accident_consultant)
                  )
                : [],
        [contact.accident_consultants]
    );

    const sortedWorkshopSystems: WorkshopSystem[] = useMemo(
        () =>
            contact.workshop_systems
                ? contact.workshop_systems.sort((a, b) =>
                      a.workshop_system.localeCompare(b.workshop_system)
                  )
                : [],
        [contact.workshop_systems]
    );

    const sortedCooperations: Cooperation[] = useMemo(
        () =>
            contact.cooperations
                ? contact.cooperations.sort((a, b) =>
                      a.cooperation.localeCompare(b.cooperation)
                  )
                : [],
        [contact.cooperations]
    );

    if (isEdited) {
        return (
            <FormBuilder
                action={ContactEntity.apiBaseUrl + '/' + contact.id}
                initialValues={{
                    segmentation: contact.segmentation,
                    conditions: contact.conditions ?? '',
                }}
                responseHandler={editResponseHandler}
                errorMessage="Fehler beim Speichern der Profildaten!"
            >
                <CrmCard
                    title="Profil"
                    titleAction={
                        <>
                            <IconButton size="small" type="submit">
                                <Save />
                            </IconButton>
                            <EditCloseAction editClose={editClose} />
                        </>
                    }
                >
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={
                                fiveColumnBreackpoint
                                    ? {
                                          maxWidth: '20%',
                                          flexBasis: '20%',
                                      }
                                    : {}
                            }
                        >
                            <Field
                                component={TextField}
                                name="segmentation"
                                label="Priorität"
                                autoFocus
                                select
                            >
                                {segmentationOptions.map(value => (
                                    <MenuItem value={value} key={value}>
                                        {value ?? '-'}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={
                                fiveColumnBreackpoint
                                    ? {
                                          maxWidth: '20%',
                                          flexBasis: '20%',
                                      }
                                    : {}
                            }
                        >
                            <Field
                                component={TextField}
                                name="conditions"
                                label="Kaskokonditionen"
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={
                                fiveColumnBreackpoint
                                    ? {
                                          maxWidth: '20%',
                                          flexBasis: '20%',
                                      }
                                    : {}
                            }
                        >
                            <EntityAutocompleteMultiple<Brand>
                                entity={BrandEntity}
                                selectedElements={contact.brands}
                                minInputLength={0}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={
                                fiveColumnBreackpoint
                                    ? {
                                          maxWidth: '20%',
                                          flexBasis: '20%',
                                      }
                                    : {}
                            }
                        >
                            <EntityAutocompleteMultiple<ContactType>
                                entity={ContactTypeEntity}
                                selectedElements={contact.contact_types}
                                minInputLength={0}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={
                                fiveColumnBreackpoint
                                    ? {
                                          maxWidth: '20%',
                                          flexBasis: '20%',
                                      }
                                    : {}
                            }
                        >
                            <EntityAutocompleteMultiple<AccidentConsultant>
                                entity={AccidentConsultantEntity}
                                selectedElements={contact.accident_consultants}
                                minInputLength={0}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={
                                fiveColumnBreackpoint
                                    ? {
                                          maxWidth: '20%',
                                          flexBasis: '20%',
                                      }
                                    : {}
                            }
                        >
                            <EntityAutocompleteMultiple<WorkshopSystem>
                                entity={WorkshopSystemEntity}
                                selectedElements={contact.workshop_systems}
                                minInputLength={0}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={
                                fiveColumnBreackpoint
                                    ? {
                                          maxWidth: '20%',
                                          flexBasis: '20%',
                                      }
                                    : {}
                            }
                        >
                            <EntityAutocompleteMultiple<Cooperation>
                                entity={CooperationEntity}
                                selectedElements={contact.cooperations}
                                minInputLength={0}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={
                                fiveColumnBreackpoint
                                    ? {
                                          maxWidth: '20%',
                                          flexBasis: '20%',
                                      }
                                    : {}
                            }
                        >
                            <EntityAutocompleteMultiple<Competitor>
                                entity={CompetitorEntity}
                                selectedElements={contact.competitors}
                                minInputLength={0}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            sx={
                                fiveColumnBreackpoint
                                    ? {
                                          maxWidth: '20%',
                                          flexBasis: '20%',
                                      }
                                    : {}
                            }
                        >
                            <EntityAutocomplete
                                entity={CampaignEntity}
                                name="campaign_id"
                                minInputLength={0}
                                selectedElement={contact.campaign}
                                preloadAllEntries
                            />
                        </Grid>
                    </Grid>
                </CrmCard>
            </FormBuilder>
        );
    }

    return (
        <CrmCard
            title="Profil"
            titleAction={
                <IconButton size="small" onClick={editOpen}>
                    <Edit />
                </IconButton>
            }
        >
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={
                        fiveColumnBreackpoint
                            ? {
                                  maxWidth: '20%',
                                  flexBasis: '20%',
                              }
                            : {}
                    }
                >
                    <CrmLabel>Priorität</CrmLabel>
                    <Typography variant="body2">
                        <Link
                            style={{
                                color: 'inherit',
                                textDecorationColor: '#DDD',
                            }}
                            to={`/contacts?segmentations=${contact.segmentation}`}
                        >
                            {contact.segmentation ?? '-'}
                        </Link>
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={
                        fiveColumnBreackpoint
                            ? {
                                  maxWidth: '20%',
                                  flexBasis: '20%',
                              }
                            : {}
                    }
                >
                    <CrmLabel>Kaskokonditionen</CrmLabel>
                    <Typography variant="body2">
                        {contact.conditions ?? '-'}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={
                        fiveColumnBreackpoint
                            ? {
                                  maxWidth: '20%',
                                  flexBasis: '20%',
                              }
                            : {}
                    }
                >
                    <CrmLabel>
                        Herstellermarke
                        {sortedBrands.length > 1 && 'n'}
                    </CrmLabel>
                    <Typography variant="body2">
                        {sortedBrands.length > 0
                            ? sortedBrands.map(({ brand, id }, index) => (
                                  <Fragment key={`brand-${id}`}>
                                      {index !== 0 && ', '}
                                      <Link
                                          style={{
                                              color: 'inherit',
                                              textDecorationColor: '#DDD',
                                          }}
                                          to={`/contacts?brands=${id}`}
                                      >
                                          {brand}
                                      </Link>
                                  </Fragment>
                              ))
                            : '-'}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={
                        fiveColumnBreackpoint
                            ? {
                                  maxWidth: '20%',
                                  flexBasis: '20%',
                              }
                            : {}
                    }
                >
                    <CrmLabel>Typ</CrmLabel>
                    <Typography variant="body2">
                        {sortedContactTypes.length > 0
                            ? sortedContactTypes.map(
                                  ({ contact_type, id }, index) => (
                                      <Fragment key={`contact_type-${id}`}>
                                          {index !== 0 && ', '}
                                          <Link
                                              style={{
                                                  color: 'inherit',
                                                  textDecorationColor: '#DDD',
                                              }}
                                              to={`/contacts?contactTypes=${id}`}
                                          >
                                              {contact_type}
                                          </Link>
                                      </Fragment>
                                  )
                              )
                            : '-'}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={
                        fiveColumnBreackpoint
                            ? {
                                  maxWidth: '20%',
                                  flexBasis: '20%',
                              }
                            : {}
                    }
                >
                    <CrmLabel>Gutachter</CrmLabel>
                    <Typography variant="body2">
                        {sortedAccidentConsultants.length > 0
                            ? sortedAccidentConsultants.map(
                                  ({ accident_consultant, id }, index) => (
                                      <Fragment
                                          key={`accident_consultant-${id}`}
                                      >
                                          {index !== 0 && ', '}
                                          <Link
                                              style={{
                                                  color: 'inherit',
                                                  textDecorationColor: '#DDD',
                                              }}
                                              to={`/contacts?accidentConsultants=${id}`}
                                          >
                                              {accident_consultant}
                                          </Link>
                                      </Fragment>
                                  )
                              )
                            : '-'}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={
                        fiveColumnBreackpoint
                            ? {
                                  maxWidth: '20%',
                                  flexBasis: '20%',
                              }
                            : {}
                    }
                >
                    <CrmLabel>
                        Werkstattsystem
                        {sortedWorkshopSystems.length > 1 && 'e'}
                    </CrmLabel>
                    <Typography variant="body2">
                        {sortedWorkshopSystems.length > 0
                            ? sortedWorkshopSystems.map(
                                  ({ workshop_system, id }, index) => (
                                      <Fragment key={`workshop_system-${id}`}>
                                          {index !== 0 && ', '}
                                          <Link
                                              style={{
                                                  color: 'inherit',
                                                  textDecorationColor: '#DDD',
                                              }}
                                              to={`/contacts?workshopSystems=${id}`}
                                          >
                                              {workshop_system}
                                          </Link>
                                      </Fragment>
                                  )
                              )
                            : '-'}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={
                        fiveColumnBreackpoint
                            ? {
                                  maxWidth: '20%',
                                  flexBasis: '20%',
                              }
                            : {}
                    }
                >
                    <CrmLabel>
                        Kooperation
                        {sortedCooperations.length > 1 && 'en'}
                    </CrmLabel>
                    <Typography variant="body2">
                        {sortedCooperations.length > 0
                            ? sortedCooperations.map(
                                  ({ cooperation, id }, index) => (
                                      <Fragment key={`cooperation-${id}`}>
                                          {index !== 0 && ', '}
                                          <Link
                                              style={{
                                                  color: 'inherit',
                                                  textDecorationColor: '#DDD',
                                              }}
                                              to={`/contacts?cooperations=${id}`}
                                          >
                                              {cooperation}
                                          </Link>
                                      </Fragment>
                                  )
                              )
                            : '-'}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={
                        fiveColumnBreackpoint
                            ? {
                                  maxWidth: '20%',
                                  flexBasis: '20%',
                              }
                            : {}
                    }
                >
                    <CrmLabel>Wettbewerber</CrmLabel>
                    <Typography variant="body2">
                        {sortedCompetitors.length > 0
                            ? sortedCompetitors.map(
                                  ({ competitor, id }, index) => (
                                      <Fragment key={`competitor-${id}`}>
                                          {index !== 0 && ', '}
                                          <Link
                                              style={{
                                                  color: 'inherit',
                                                  textDecorationColor: '#DDD',
                                              }}
                                              to={`/contacts?competitors=${id}`}
                                          >
                                              {competitor}
                                          </Link>
                                      </Fragment>
                                  )
                              )
                            : '-'}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={
                        fiveColumnBreackpoint
                            ? {
                                  maxWidth: '20%',
                                  flexBasis: '20%',
                              }
                            : {}
                    }
                >
                    <CrmLabel>Kampagne</CrmLabel>
                    <Typography variant="body2">
                        {contact.campaign ? contact.campaign.campaign : '-'}
                    </Typography>
                </Grid>
            </Grid>
        </CrmCard>
    );
};

export default Profile;
