import React, { ReactElement } from 'react';
import FormattingBase from './FormattingBase';
import { Launch } from '@mui/icons-material';

interface Props {
    url: string;
}

const Url = ({ url }: Props): ReactElement => (
    <FormattingBase
        icon={Launch}
        label={url}
        href={url.startsWith('http') ? url : '//' + url}
        title={`${url} aufrufen`}
        linkProps={{
            rel: 'noreferrer noopener',
            target: '_blank',
        }}
    />
);

export default Url;
