import React, { FC } from 'react';
import CrmCard from './helpers/CrmCard';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export interface DataSet {
    plural: string;
    singular: string;
    slug: string;
    attributes: string[];
}

export const dataSets: DataSet[] = [
    {
        plural: 'Gruppen',
        singular: 'Gruppe',
        slug: 'groups',
        attributes: ['group'],
    },
    {
        plural: 'Kooperationen',
        singular: 'Kooperation',
        slug: 'cooperations',
        attributes: ['cooperation'],
    },
    {
        plural: 'Kontakttypen',
        singular: 'Kontakttyp',
        slug: 'contact_types',
        attributes: ['contact_type'],
    },
    {
        plural: 'Werkstattsysteme',
        singular: 'Werkstattsystem',
        slug: 'workshop_systems',
        attributes: ['workshop_system'],
    },
    {
        plural: 'Herstellermarken',
        singular: 'Herstellermarke',
        slug: 'brands',
        attributes: ['brand'],
    },
    {
        plural: 'Gutachter',
        singular: 'Gutachter',
        slug: 'accident_consultants',
        attributes: ['accident_consultant'],
    },
    {
        plural: 'Wettbewerber',
        singular: 'Wettbewerber',
        slug: 'competitors',
        attributes: ['competitor'],
    },
    {
        plural: 'Positionen',
        singular: 'Position',
        slug: 'positions',
        attributes: ['position_d', 'position_m', 'position_f'],
    },
    {
        plural: 'Kampagnen',
        singular: 'Kampagne',
        slug: 'campaigns',
        attributes: ['campaign'],
    },
];

export const baseUrl: string = '/settings/data-sets/';

const DataSetOverView: FC = () => (
    <Box maxWidth="300px" mx="auto">
        <CrmCard title="Stammdatenverwaltung">
            <List component="nav" disablePadding>
                {dataSets.map((dataSet: DataSet) => (
                    <ListItem
                        sx={{ cursor: 'pointer' }}
                        disableGutters
                        key={dataSet.slug}
                        component={Link}
                        to={`${baseUrl}${dataSet.slug}`}
                    >
                        <ListItemIcon>
                            <ChevronRight />
                        </ListItemIcon>
                        <ListItemText primary={dataSet.plural} />
                    </ListItem>
                ))}
            </List>
        </CrmCard>
    </Box>
);

export default DataSetOverView;
