import React, { FC } from 'react';
import { Box, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
    title?: any;
    subtitle?: string | null;
    subtitleLink?: string | null;
    disableTypography?: boolean;
    buttons?: any;
}

const CrmToolbar: FC<Props> = ({
    title,
    subtitle,
    subtitleLink,
    disableTypography,
    buttons,
}) => (
    <Toolbar>
        {disableTypography ? (
            <Box
                marginBottom={1}
                sx={{
                    flexGrow: 1,
                }}
            >
                {title}
            </Box>
        ) : (
            <Typography
                sx={{
                    flexGrow: 1,
                }}
                variant="h1"
                component="h1"
            >
                {title}
                {subtitle && (
                    <Typography variant="body2" color="textSecondary">
                        {subtitleLink ? (
                            <Link
                                style={{
                                    color: 'inherit',
                                    textDecorationColor: '#DDD',
                                }}
                                to={subtitleLink}
                            >
                                {subtitle}
                            </Link>
                        ) : (
                            subtitle
                        )}
                    </Typography>
                )}
            </Typography>
        )}

        {buttons}
    </Toolbar>
);

export default CrmToolbar;
