import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDebounce } from 'react-use';
import { AutocompleteOption } from '../../../crm';
import { FormBuilderFieldProps } from './FormBuilder';
import logError from '../../../errors/logError';
import {
    Autocomplete,
    ListItem,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';

interface Props extends FormBuilderFieldProps {
    getOptions(searchString: string): Promise<AutocompleteOption[]>;
    size?: 'medium' | 'small';
    autoFocus?: boolean;
    selectedOption?: AutocompleteOption | null;
    onChangeHandler?(newValue: AutocompleteOption | null): void;
    inputProps?: Obj;
    minInputLength?: number;
    withoutFormik?: boolean;
}

const AutocompleteInput = ({
    getOptions,
    label,
    name,
    size,
    selectedOption,
    onChangeHandler,
    autoFocus,
    inputProps = {},
    minInputLength = 3,
    required = false,
    withoutFormik = false,
}: Props): ReactElement => {
    const [searchString, setSearchString] = useState('');
    const [options, setOptions] = useState<AutocompleteOption[]>([]);
    const { setFieldValue, errors } = withoutFormik
        ? { setFieldValue: undefined, errors: undefined }
        : useFormikContext<Obj>();

    useEffect(() => {
        selectedOption &&
            setFieldValue &&
            setFieldValue(name, selectedOption.value);
    }, []);

    const updateOptions = async () => {
        if (searchString.trim().length < minInputLength) {
            return setOptions([]);
        }

        try {
            const tmpOptions = await getOptions(searchString);
            setOptions(
                tmpOptions.sort((a, b) => a.label.localeCompare(b.label))
            );
        } catch (e) {
            setOptions([]);
            logError(e);
            throw e;
        }
    };

    const [isReady] = useDebounce(updateOptions, 500, [searchString]);

    const onAutoCompleteChange = (
        event: any,
        newValue: AutocompleteOption | null
    ) => {
        setFieldValue && setFieldValue(name, newValue?.value ?? null);
        onChangeHandler && onChangeHandler(newValue);
    };

    const onInputChange = async (
        event: ChangeEvent<unknown>,
        value: string
    ) => {
        setSearchString(value);
    };

    return (
        <Autocomplete
            defaultValue={selectedOption}
            autoHighlight
            options={options}
            groupBy={option => option.label.charAt(0)}
            noOptionsText={
                searchString.trim().length >= minInputLength
                    ? isReady()
                        ? 'Keine passenden Einträge gefunden...'
                        : 'Lädt...'
                    : `Mindestens ${minInputLength} Zeichen eingeben...`
            }
            isOptionEqualToValue={(option, value) =>
                option.value === value.value
            }
            onChange={onAutoCompleteChange}
            onInputChange={onInputChange}
            getOptionLabel={(option: AutocompleteOption) => option.label}
            renderOption={(props, option: AutocompleteOption) => (
                <ListItem {...props}>
                    <ListItemText
                        primary={option.label}
                        secondary={option.additionalInformation}
                    />
                </ListItem>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    autoFocus={autoFocus}
                    label={label}
                    size={size || 'medium'}
                    {...inputProps}
                    required={required}
                    error={!!(errors && errors[name])}
                    helperText={
                        errors && (
                            <Typography> {errors[name] as string}</Typography>
                        )
                    }
                />
            )}
        />
    );
};

export default AutocompleteInput;
