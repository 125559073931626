import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import useEntities from '../../../hooks/useEntities';
import { User } from '../../../mappers/userMapper';
import { UserEntity } from '../../../helpers/entities';
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    TextField,
} from '@mui/material';
import { AutocompleteOption } from '../../../crm';

interface Props {
    name: string;
    label: string;
    placeholder?: string;
    userKey?: 'email' | 'id';
}

const ReportAddDialogAutocompleteAjax = ({
    name,
    label,
    placeholder,
    userKey = 'email',
}: Props): ReactElement => {
    const textInputName = `${name}_textfield_input`;
    const { entities: internalUsers } = useEntities<User>(UserEntity);
    const { setFieldValue, errors, values } = useFormikContext<any>();
    const [options, setOptions] = useState<AutocompleteOption[] | null>(null);
    const [defaultValue, setDefaultValue] = useState<
        AutocompleteOption[] | null
    >(null);

    useEffect(() => {
        internalUsers &&
            setOptions(
                internalUsers
                    .filter(user => !user.isDisabled)
                    .map(
                        (user): AutocompleteOption => ({
                            label: user.nameSortable,
                            value: user[userKey],
                        })
                    )
            );
    }, [internalUsers]);

    useEffect(() => {
        options !== null &&
            setDefaultValue(
                values[name].map(
                    (value: string): AutocompleteOption =>
                        options.find(option => option.value === value) || {
                            label: value,
                            value: value,
                        }
                )
            );
    }, [options]);

    const onChangeAutoComplete = (
        event: ChangeEvent<any>,
        values: any[]
    ): void => {
        setFieldValue(
            name,
            values.map((value: AutocompleteOption) => value.value)
        );
        setFieldValue(textInputName, '');
    };

    const getOptionLabel = (option: any): string => option.label;

    const isOptionEqualToValue = (
        option: AutocompleteOption,
        value: AutocompleteOption | string
    ): boolean =>
        option.value === (typeof value === 'string' ? value : value.value);

    if (options === null || defaultValue === null) {
        return <TextField disabled value="Lädt..." />;
    }

    return (
        <Autocomplete
            multiple
            autoHighlight
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={onChangeAutoComplete}
            getOptionLabel={getOptionLabel}
            noOptionsText="Keine passenden Einträge gefunden..."
            ChipProps={{
                color: 'secondary',
            }}
            defaultValue={defaultValue}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <Field
                    {...params}
                    component={TextField}
                    name={textInputName}
                    label={label}
                    placeholder={placeholder}
                />
            )}
            options={options}
            filterSelectedOptions
        />
    );
};

export default ReportAddDialogAutocompleteAjax;
