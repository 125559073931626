import React, { FC, useContext } from 'react';
import FormBuilder from '../../helpers/forms/FormBuilder';
import CrmToolbar from '../../helpers/CrmToolbar';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import {
    Box,
    Grid,
    IconButton,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    Archive,
    Close,
    Delete,
    Edit,
    Save,
    Unarchive,
} from '@mui/icons-material';
import useEditable from '../../../hooks/useEditable';
import { ContactPerson } from '../../../mappers/contactPersonMapper';
import { getGenderChar } from '../../../helpers/genderTranslation';
import { AuthContext } from '../../../stores/AuthStore';
import DeleteAction from '../../helpers/actions/DeleteAction';
import ContactingForbiddenAction from '../../helpers/actions/ContactingForbiddenAction';
import { useSnackbar } from 'notistack';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import logError from '../../../errors/logError';
import Ajax from '../../../helpers/Ajax';
import { ContactPersonEntity } from '../../../helpers/entities';

interface Props {
    contactPerson: ContactPerson;
    reloadContactPerson(): void;
}

const Name: FC<Props> = ({ contactPerson, reloadContactPerson }) => {
    const { isEdited, editOpen, editClose } = useEditable();
    const { userCan } = useContext<AuthContext>(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const navigate: NavigateFunction = useNavigate();

    const editResponseHandler: () => void = () => {
        editClose();
        reloadContactPerson();
    };

    const onDeleteContactPerson: () => void = () => {
        enqueueSnackbar(`${contactPerson.name} gelöscht`, {
            variant: 'success',
        });
        navigate('/', { replace: true });
    };

    const onArchiveContactPerson: () => void = () => {
        enqueueSnackbar(`${contactPerson.name} archiviert`, {
            variant: 'success',
        });
        reloadContactPerson();
    };

    const restoreContactPerson: () => Promise<void> = async () => {
        try {
            await Ajax.post(
                `${ContactPersonEntity.apiBaseUrl}/${contactPerson.id}/restore`,
                {}
            );
            enqueueSnackbar(`${contactPerson.name} wiederhergestellt`, {
                variant: 'success',
            });
            reloadContactPerson();
        } catch (e) {
            enqueueSnackbar('Fehler beim Wiederherstellen', {
                variant: 'error',
            });
            logError(e);
        }
    };

    if (!contactPerson) {
        return null;
    }

    if (isEdited) {
        return (
            <FormBuilder
                action={ContactPersonEntity.apiBaseUrl + '/' + contactPerson.id}
                initialValues={{
                    gender: contactPerson.gender,
                    title: contactPerson.title,
                    first_name: contactPerson.first_name,
                    last_name: contactPerson.last_name,
                    contacting_forbidden:
                        contactPerson.contacting_forbidden ?? false,
                }}
                responseHandler={editResponseHandler}
                errorMessage="Fehler beim Speichern des Namens!"
            >
                <CrmToolbar
                    title={
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                                <Field
                                    component={TextField}
                                    name="gender"
                                    label="Anrede"
                                    size="small"
                                    required
                                    select
                                    autoFocus
                                    fullWidth
                                >
                                    <MenuItem value="m">Herr</MenuItem>
                                    <MenuItem value="f">Frau</MenuItem>
                                    <MenuItem value="d">Divers</MenuItem>
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Field
                                    component={TextField}
                                    name="title"
                                    label="Titel"
                                    size="small"
                                    select
                                    fullWidth
                                >
                                    <MenuItem value="">&nbsp;</MenuItem>
                                    <MenuItem value="Dr.">Dr.</MenuItem>
                                    <MenuItem value="Prof. Dr.">
                                        Prof. Dr.
                                    </MenuItem>
                                </Field>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Field
                                    component={TextField}
                                    size="small"
                                    name="first_name"
                                    label="Vorname"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Field
                                    component={TextField}
                                    size="small"
                                    name="last_name"
                                    label="Nachname"
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    }
                    disableTypography
                    buttons={
                        <>
                            <IconButton type="submit">
                                <Save />
                            </IconButton>
                            <IconButton edge="end" onClick={editClose}>
                                <Close />
                            </IconButton>
                        </>
                    }
                />
            </FormBuilder>
        );
    }

    return (
        <CrmToolbar
            title={
                <>
                    {contactPerson.name}
                    <small
                        style={{
                            color: '#999',
                            paddingLeft: '10px',
                        }}
                    >
                        {getGenderChar(contactPerson.gender)}
                    </small>
                </>
            }
            buttons={
                <>
                    {userCan('delete') && (
                        <DeleteAction
                            label="Ansprechpartner löschen"
                            action={
                                ContactPersonEntity.apiBaseUrl +
                                '/' +
                                contactPerson.id
                            }
                            onDeleteHandler={onDeleteContactPerson}
                            icon={Delete}
                        >
                            <Typography>
                                Sind Sie sicher, dass Sie{' '}
                                <strong>{contactPerson.name}</strong>{' '}
                                unwiderruflich löschen wollen?
                            </Typography>
                        </DeleteAction>
                    )}
                    {contactPerson.is_archived ? (
                        <Tooltip title="Ansprechpartner wiederherstellen">
                            <IconButton onClick={restoreContactPerson}>
                                <Unarchive />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <DeleteAction
                            label="Ansprechpartner archivieren"
                            action={`${ContactPersonEntity.apiBaseUrl}/${contactPerson.id}/archive`}
                            onDeleteHandler={onArchiveContactPerson}
                            icon={Archive}
                        >
                            <>
                                <Typography gutterBottom>
                                    Sind Sie sicher, dass Sie
                                    <Box my={1}>
                                        <strong>{contactPerson.name}</strong>
                                    </Box>
                                    archivieren wollen?
                                </Typography>
                                <Typography gutterBottom>
                                    Der Ansprechpartner bleibt weiterhin den
                                    Kontakten zugeordnet und wird als nicht mehr
                                    verfügbar dargestellt.
                                </Typography>
                                <Typography>
                                    Sie erhalten nach 4 Wochen eine Erinnerung.
                                </Typography>
                            </>
                        </DeleteAction>
                    )}
                    <ContactingForbiddenAction
                        action={
                            ContactPersonEntity.apiBaseUrl +
                            '/' +
                            contactPerson.id
                        }
                        isContactingForbidden={
                            !contactPerson.contacting_forbidden
                        }
                        onUpdateHandler={reloadContactPerson}
                    />
                    <IconButton edge="end" onClick={editOpen}>
                        <Edit />
                    </IconButton>
                </>
            }
        />
    );
};

export default Name;
