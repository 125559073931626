import React, { ReactElement } from 'react';
import { IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Add, List as ListIcon } from '@mui/icons-material';
import CrmCard from '../../helpers/CrmCard';
import useDialog from '../../../hooks/useDialog';
import CrmDialog from '../../helpers/CrmDialog';
import RivalAddDialog from '../RivalAddDialog';

const Rivals = (): ReactElement => {
    const { dialogOpen, closeDialog, openDialog } = useDialog();

    return (
        <CrmCard
            title="Wettbewerber"
            titleAction={
                <>
                    <IconButton size="small" onClick={openDialog}>
                        <Add />
                    </IconButton>
                    <IconButton
                        size="small"
                        component={RouterLink}
                        to="/rivals/"
                    >
                        <ListIcon />
                    </IconButton>
                </>
            }
            noContent
        >
            <CrmDialog
                dialogOpen={dialogOpen}
                closeDialog={closeDialog}
                confirmClose
            >
                <RivalAddDialog closeDialog={closeDialog} />
            </CrmDialog>
        </CrmCard>
    );
};

export default Rivals;
