import React, { FC, useContext } from 'react';
import { GlobalContext } from '../../stores/GlobalStore';
import { Backdrop, CircularProgress } from '@mui/material';

const CrmBackdrop: FC = () => {
    const { backdropOpen } = useContext(GlobalContext);

    return (
        <Backdrop
            open={backdropOpen}
            sx={{
                zIndex: 999,
            }}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default CrmBackdrop;
