import React, { FC, memo, PropsWithChildren } from 'react';
import { Theme, Typography, useTheme } from '@mui/material';

const CrmLabel: FC<PropsWithChildren> = ({ children }) => {
    const theme: Theme = useTheme();

    return (
        <Typography
            sx={{
                '&:not(:first-child)': {
                    marginTop: theme.spacing(2),
                },
            }}
            component="h4"
            variant="subtitle2"
            color="textSecondary"
        >
            {children}
        </Typography>
    );
};

export default memo(CrmLabel);
